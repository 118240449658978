import React from 'react';
import { Button } from 'requiro-ui';

type SaveBarProps = {
  onCancel: Function;
  onCancelLabel: string;
  onSave: Function;
  onSaveLabel: string;
  saveDisabled?: boolean;
};

const SaveBar = ({
  onCancel,
  onCancelLabel,
  onSave,
  onSaveLabel,
  saveDisabled,
}: SaveBarProps) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: '16px',
      }}
    >
      {onCancel && onCancelLabel && (
        <div style={{ marginRight: '16px' }}>
          <Button color="secondary" onClick={onCancel}>
            {onCancelLabel}
          </Button>
        </div>
      )}

      {onSave && onSaveLabel && (
        <div>
          <Button color="primary" disabled={saveDisabled} onClick={onSave}>
            {onSaveLabel}
          </Button>
        </div>
      )}
    </div>
  );
};

export default SaveBar;

import { styled } from '@mui/system';

export const GridViewWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
}));

export const ListViewWrapper = styled('div')(({ theme }) => ({}));

export const FormStyles = styled('form')(({ theme }) => ({
  // padding: '24px',
}));

export const FileInput = styled('input')({
  display: 'none',
});

import { Formik } from 'formik';
import { t } from 'i18next';
import { get, pick } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Input } from 'requiro-ui';
import { Grid, Typography } from '@mui/material';
import Img from '../../../components/Img';
import { ScreenWrapper } from '../../../components/layouts/AppLayout';
import ScreenToolbar from '../../../components/ScreenToolbar';
import TitleToolbar from '../../../components/TitleToolbar';
import { WorkspaceGroupModel } from '../../../generated/axios';
import { CONFIRM_LABEL, wsActions } from '../../../lib/config';
import defaultIcon from '../../../lib/defaultIcon';
import { createGroupInWS, editWorkspaceGroup } from '../../../lib/mutations';
import { useSingleGroupQuery } from '../../../lib/queries';
import { useCrumbs } from '../../../lib/useCrumbs';
import { useApplicationContext } from '../../../providers/ApplicationProvider';
import { FormStyles } from './GroupStyles';

const baseValues = {
  // id: 0,
  name: '',
  description: '',
  // state: 0,
  // createdBy: '',
  // createdOn: '2022-03-16T13:14:00.649Z',
  // modifiedBy: '',
  // modifiedOn: '2022-03-16T13:14:00.649Z',
};

// const categories = [
//   { id: '1', label: 'Produttività' },
//   { id: '2', label: 'Finanza' },
//   { id: '3', label: 'Integrazione' },
// ];

const responsiveColumns = {
  xs: 12,
  sm: 12,
  md: 6,
};

const GroupForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const params = useParams();
  const [initialValues, setInitialValues] = useState(baseValues);
  const { currentWorkspace } = useApplicationContext();
  let groupInfo = useSingleGroupQuery();

  const name = get(currentWorkspace, 'name', '');

  useCrumbs([
    { to: '/ws', label: 'Workspaces' },
    { to: `/ws/${currentWorkspace.id}`, label: `${name}` },
    { to: `/ws/${currentWorkspace.id}/groups`, label: wsActions.groups },
    { label: params.idGroup ? wsActions.edit : wsActions.new },
  ]);

  const onError = (error: any) => {
    enqueueSnackbar(t('errors.generic'), { variant: 'error' });
  };

  const onSuccess = (data: any) => {
    enqueueSnackbar(t('res.saveOK'), { variant: 'success' });
    navigate(`/ws/${currentWorkspace.id}/groups`);
  };

  const mutation = createGroupInWS({
    onError,
    onSuccess,
  });
  const editMutation = editWorkspaceGroup({
    onError,
    onSuccess,
  });

  useEffect(() => {
    if (groupInfo.status === 'success') {
      const temp = get(groupInfo, 'data.data.data', {});
      const data = pick(temp, ['name', 'description']);
      const values = { ...initialValues, ...data };
      setInitialValues(values);
    }
  }, [groupInfo.status]);

  const newGroup = (group: WorkspaceGroupModel) => {
    if (currentWorkspace.id) {
      mutation.mutate({
        idWorkspace: currentWorkspace.id,
        group,
      });
    }
  };

  const update = (group: WorkspaceGroupModel) => {
    if (currentWorkspace.id) {
      const groupInfoData = get(groupInfo, 'data.data.data', {});
      // PREVENT ERROR: completa i dati del form con quelli che non sono variati
      const updatedGroupData = { ...groupInfoData, ...group };
      editMutation.mutate({
        idWorkspace: currentWorkspace.id,
        group: updatedGroupData,
      });
    }
  };

  return (
    <ScreenWrapper>
      <TitleToolbar
        title={params.id ? 'Modifica il gruppo' : 'Aggiungi un gruppo'}
      />

      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values) => {
          console.log(values);
          params.idGroup ? update(values) : newGroup(values);
        }}
      >
        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => {
          return (
            <FormStyles onSubmit={handleSubmit}>
              <Grid container columnSpacing={4} rowSpacing={4}>
                <Grid item {...responsiveColumns}>
                  <Typography variant="body1">Nome</Typography>
                  <Input
                    value={values.name}
                    onChange={(e: any) => setFieldValue('name', e.target.value)}
                    size="small"
                    helperText="Max 96 caratteri"
                    fullWidth
                  />
                </Grid>
                <Grid item {...responsiveColumns}>
                  <Typography variant="body1">Descrizione</Typography>
                  <Input
                    multiline
                    maxRows={4}
                    value={values.description}
                    fullWidth
                    onChange={(e: any) =>
                      setFieldValue('description', e.target.value)
                    }
                    size="small"
                  />
                </Grid>
                {/* <Grid item {...responsiveColumns}>
                        <Typography variant="body1">Creato da</Typography>
                        <TextField
                          multiline
                          maxRows={4}
                          value={values.createdBy}
                          fullWidth
                          onChange={(e: any) =>
                            setFieldValue('createdBy', e.target.value)
                          }
                          size="small"
                        />
                      </Grid>
                      <Grid item {...responsiveColumns}>
                        <Typography variant="body1">Modificato da</Typography>
                        <TextField
                          multiline
                          maxRows={4}
                          value={values.modifiedBy}
                          fullWidth
                          onChange={(e: any) =>
                            setFieldValue('modifiedBy', e.target.value)
                          }
                          size="small"
                        />
                      </Grid> */}
                <Grid item xs={12} sx={{ textAlign: 'right' }}>
                  <Button type="submit" variant="contained" size="small">
                    {CONFIRM_LABEL}
                  </Button>
                </Grid>
              </Grid>
            </FormStyles>
          );
        }}
      </Formik>
    </ScreenWrapper>
  );
};

export default GroupForm;

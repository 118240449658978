import axios from 'axios';
import { get } from 'lodash';
import { useQuery } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { useAppInitiator } from '../../../providers/AppInitiator';
import { catalogApps } from './recoil';

const catalogKeys = {
  all: ['catalog'] as const,
  detail: (id: string) => [...catalogKeys.all, id] as const,
};

/**
 * fetch all catalog apps
 * @returns
 */
export function useCatalogApps() {
  const { boot } = useAppInitiator();
  const setCatalog = useSetRecoilState(catalogApps);

  const key = catalogKeys.all;
  const fn = () => axios.get(`${boot.appServicesBaseUrl}/app`);

  return useQuery(key, fn, {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    select: (data) => {
      const apps = get(data, 'data.data', []);
      setCatalog(apps);
    },
  });
}

/**
 * fetch a single catalog app, id comes from url params
 */
export function useCatalogApp(id: string) {
  const { boot } = useAppInitiator();

  const key = catalogKeys.detail(id);
  const fn = () => axios.get(`${boot.appServicesBaseUrl}/app/${id}`);

  return useQuery(key, fn, {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: Boolean(id),
  });
}

import { Formik } from 'formik';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Switch } from 'requiro-ui';
import { FormControlLabel, Grid, TextField } from '@mui/material';
import { ScreenWrapper } from '../../components/layouts/AppLayout';
import MessageBox from '../../components/MessageBox';
import ScreenToolbar from '../../components/ScreenToolbar';
import TitleToolbar from '../../components/TitleToolbar';
import { createUserArchiva } from '../../lib/mutations';
import { useCrumbs } from '../../lib/useCrumbs';
import { extractMutationErrorMessage } from '../../lib/utils';

const _initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  state: 0,
  username: '',
};

const Form = () => {
  const [initialValues, seTinitialValues] = useState(_initialValues);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const createMutation = createUserArchiva({
    onError: (error) => {
      enqueueSnackbar(extractMutationErrorMessage(error), {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar(t('res.saveOK'), { variant: 'success' });
      navigate('/users');
    },
  });

  useCrumbs([
    { to: '/users', label: 'Gestione Utenti Archiva' },
    { label: 'crea utente' },
  ]);

  return (
    <ScreenWrapper>
      <TitleToolbar title="Crea utente START" />
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const newUser = { ...values, state: values.state ? 1 : 0 };
          createMutation.mutate(newUser);
        }}
      >
        {({ handleSubmit, values, handleChange, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Nome"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Cognome"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Username"
                  name="username"
                  value={values.username}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
              </Grid>
              {/* <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Ruolo"
                      name="role"
                      value={values.role}
                      onChange={handleChange}
                    />
                  </Grid> */}
              <Grid item xs={12}>
                {/* <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      border: '1px solid rgba(0,0,0,0.22)',
                      borderRadius: '8px',
                      height: '100%',
                    }}
                  > */}
                <FormControlLabel
                  sx={{ ml: '8px', mr: '8px' }}
                  control={
                    <Switch
                      size="small"
                      checked={values.state}
                      color="secondary"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('state', e.target.checked)
                      }
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={
                    <span style={{ marginLeft: '16px' }}>
                      {`Utente attivo: ${values.state ? 'SI' : 'NO'}`}
                    </span>
                  }
                />
                {/* </Box> */}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button variant="contained" type="submit" size="small">
                  Conferma
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </ScreenWrapper>
  );
};

export default Form;

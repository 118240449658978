import { useState } from 'react';
import { DEFAULT_PAGINATION, RQX_START_ROWS_PER_PAGE } from './config';

export function usePagination() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    const rows = window.localStorage.getItem(RQX_START_ROWS_PER_PAGE);
    return rows ? Number(rows) : DEFAULT_PAGINATION;
  });
  const [pages, setPages] = useState<any[][] | null>(null);

  const onPageChange = (e: unknown, newPage: number) => {
    setPage(newPage);
  };

  const onRowsPerPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const n = Number(e.target.value);
    setPage(0);
    window.localStorage.setItem(RQX_START_ROWS_PER_PAGE, String(n));
    setRowsPerPage(n);
  };

  return {
    page,
    rowsPerPage,
    pages,
    onPageChange,
    onRowsPerPageChange,
    setPages,
  };
}

import React from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, Paper, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Img from '../../../components/Img';
import { AppModel } from '../../../generated/axios';
import defaultIcon from '../../../lib/defaultIcon';

const ListItemWrapper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export default ({ data }: { data: AppModel }) => {
  return (
    <ListItemWrapper elevation={3}>
      <Stack direction="row" sx={{ alignItems: 'center' }}>
        <Img alt="alt" src="" altSrc={defaultIcon} className="ico" />
        <Typography variant="h6" sx={{ mx: 3 }}>
          {data.name}
        </Typography>
        <Typography variant="subtitle1">{data.description}</Typography>
      </Stack>
      {data.id ? (
        <Stack direction="row" spacing={3} sx={{ alignItems: 'center' }}>
          <Typography variant="subtitle2">Versione: {data.version}</Typography>
          <Link to={`${data.id}`} state={{ name: data.name }}>
            <IconButton>
              <InfoIcon color="secondary" fontSize="medium" />
            </IconButton>
          </Link>
          <Link to={`edit/${data.id}`} state={{ name: data.name }}>
            <IconButton>
              <EditIcon color="secondary" fontSize="medium" />
            </IconButton>
          </Link>
        </Stack>
      ) : null}
    </ListItemWrapper>
  );
};

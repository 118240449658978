import React from 'react';
import { Paper, Stack, Toolbar } from '@mui/material';
import { IHasChildren } from '../../types';
import AppCrumbs from './AppCrumbs';

const ScreenToolbar = ({ children }: IHasChildren) => {
  return (
    <Paper>
      <Stack direction="row" spacing={1}>
        <Toolbar sx={{ flexGrow: 1 }}>
          <AppCrumbs />
        </Toolbar>
        {children ? (
          <Toolbar sx={{ justifyContent: 'flex-end' }}>{children}</Toolbar>
        ) : null}
      </Stack>
    </Paper>
  );
};

export default ScreenToolbar;

export async function blob(token, uri, filename = null) {
  try {
    const res = await fetch(uri, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
    const blob = await res.blob();
    const urlObject = URL.createObjectURL(blob);
    if (filename) {
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.href = urlObject;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(urlObject);
      a.remove();
    } else {
      window.open(urlObject, '_blank');
    }
  } catch (error) {
    console.log({ error });
  }
}

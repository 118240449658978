import axios from 'axios';
import { get } from 'lodash';
import { useQuery } from 'react-query';
import { useAppInitiator } from '../../../providers/AppInitiator';

const wsUsersKeys = {
  all: (workspaceId: string) => ['workspace-users', workspaceId] as const,
  allWithQueryString: (workspaceId: string, qs: string) =>
    [...wsUsersKeys.all(workspaceId), qs] as const,
  allWithQueryStringEXT: (workspaceId: string, qs: string) =>
    [...wsUsersKeys.all(workspaceId), 'ext', qs] as const,
  detail: (workspaceId: string, userId: string) =>
    [...wsUsersKeys.all(workspaceId), userId] as const,
};

/**
 *
 * @param workspaceId
 * @returns
 */
export function useWorkspaceUsers(workspaceId: string) {
  const { boot } = useAppInitiator();

  const key = wsUsersKeys.all(workspaceId);
  const fn = () =>
    axios.get(`${boot.appServicesBaseUrl}/workspace/${workspaceId}/user`);

  return useQuery(key, fn, {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
}

/**
 *
 * @param workspaceId
 * @param queryString must start with '?'
 * @returns
 */
export function useFilteredWorkpaceUsers(
  workspaceId: string,
  queryString: string = '',
) {
  const { boot } = useAppInitiator();

  const key = wsUsersKeys.allWithQueryString(workspaceId, queryString);
  const fn = () =>
    axios.get(
      `${boot.appServicesBaseUrl}/workspace/${workspaceId}/user${queryString}`,
    );

  return useQuery(key, fn, {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    select: (data) => get(data, 'data.data'),
  });
}

/**
 *
 * @param workspaceId
 * @param queryString
 * @returns
 */
export function useFilteredWorkpaceUsersEXT(
  workspaceId: string,
  queryString: string = '',
) {
  const { boot } = useAppInitiator();

  const key = wsUsersKeys.allWithQueryStringEXT(workspaceId, queryString);
  const fn = () =>
    axios.get(`${boot.appServicesBaseUrl}/userExt/${queryString}`);

  return useQuery(key, fn, {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
}

/**
 *
 * @param workspaceId
 * @param userId
 * @returns
 */
export function useWorkspaceUser(workspaceId: string, userId: string) {
  const { boot } = useAppInitiator();

  const key = wsUsersKeys.detail(workspaceId, userId);
  const fn = () =>
    axios.get(
      `${boot.appServicesBaseUrl}/ws/${workspaceId}/users/edit/${userId}`,
    );

  return useQuery(key, fn, {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
}

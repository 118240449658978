import React, { FunctionComponent } from 'react';

import { Container, Paper, Stack } from '@mui/material';

import { AppModel } from '../../generated/axios';
import { GRID_VIEW } from '../../lib/config';
import { GridViewWrapper, ListViewWrapper } from './CatalogStyles';

const CatalogIndexView = ({
  children,
  data,
  viewMode,
  GridItem,
  ListItem,
}: {
  children: React.ReactNode;
  data: AppModel[];
  viewMode: string;
  GridItem: FunctionComponent<{
    data: AppModel;
  }>; // IGenericItemView;
  ListItem: FunctionComponent<{
    data: AppModel;
  }>;
}) => {
  return (
    <>
      <Paper elevation={3}>
        {children}
        {/* toolbar: change view type list/grid */}
      </Paper>
      {viewMode === GRID_VIEW ? (
        <GridViewWrapper>
          {data.map((item: AppModel) => (
            <GridItem key={item.id} data={item} />
          ))}
        </GridViewWrapper>
      ) : (
        <ListViewWrapper>
          {data.map((item: AppModel) => (
            <ListItem key={item.id} data={item} />
          ))}
        </ListViewWrapper>
      )}
    </>
  );
};

export default CatalogIndexView;

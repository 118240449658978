import { get } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableRow } from 'requiro-ui';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Grid, IconButton, Paper, Toolbar, Tooltip, Typography } from '@mui/material';
import { Workspace } from '../../../types';
import { ScreenWrapper } from '../../components/layouts/AppLayout';
import MessageBox from '../../components/MessageBox';
import ScreenToolbar from '../../components/ScreenToolbar';
import { DisabledIcon, EnabledIcon } from '../../components/Styled';
import { SHOW_LAST_USERS, SHOW_LAST_WORKSPACES } from '../../lib/config';
import { useUsersInDashboard, useWorkspacesInDashboard } from '../../lib/queries';
import { useCrumbs } from '../../lib/useCrumbs';
import WorkspacesManagement from '../../pages/WorkspacesManagement';
import { useApplicationContext } from '../../providers/ApplicationProvider';

const Dashboard = () => {
  const navigate = useNavigate();
  const { changeWorkspace } = useApplicationContext();
  const wsQuery = useWorkspacesInDashboard();
  const usersQuery = useUsersInDashboard();
  useCrumbs([{ label: 'Dashboard' }]);

  const workspaces = get(wsQuery, 'data.data.data.workspaces', []);
  const totalWSCount = get(wsQuery, 'data.data.data.totalCount', 0);
  const users = get(usersQuery, 'data.data.data.users', []);
  const totalUsersCount = get(usersQuery, 'data.data.data.totalCount', 0);

  const gotoWorkspace = (ws: Workspace) => {
    changeWorkspace(ws);
    navigate(`/ws/${ws.id}`);
  };

  return (
    <ScreenWrapper>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Paper>
            <Toolbar>
              <Typography sx={{ flexGrow: 1 }}>
                Ultimi {SHOW_LAST_WORKSPACES} Workspace (
                {`totale ${totalWSCount}`})
              </Typography>
              <Tooltip title="Visualizza tutti">
                <IconButton onClick={() => navigate('/ws')}>
                  <SearchIcon color="secondary" />
                </IconButton>
              </Tooltip>
            </Toolbar>
            <Box sx={{ p: 2 }}>
              {wsQuery.isLoading ? (
                <MessageBox.Loading />
              ) : wsQuery.isError ? (
                <MessageBox.QueryError query={wsQuery} />
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>Stato</TableCell>
                      <TableCell>Ultima modifica</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {workspaces.map((row: Workspace) => (
                      <TableRow key={row.id}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>
                          {row.state === 1 ? <EnabledIcon /> : <DisabledIcon />}
                        </TableCell>
                        <TableCell>
                          {row.modifiedOn
                            ? new Date(
                                Number(row.modifiedOn) * 1000,
                              ).toLocaleString()
                            : ''}
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="Visualizza i dettagli">
                            <IconButton onClick={() => gotoWorkspace(row)}>
                              <VisibilityIcon color="secondary" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper>
            <Toolbar>
              <Typography sx={{ flexGrow: 1 }}>
                Ultimi {SHOW_LAST_USERS} Utenti ({`totale ${totalUsersCount}`})
              </Typography>
              <Tooltip title="Visualizza tutti">
                <IconButton onClick={() => navigate('/users')}>
                  <AccountCircleOutlinedIcon color="secondary" />
                </IconButton>
              </Tooltip>
            </Toolbar>
            <Box sx={{ p: 2 }}>
              {usersQuery.isLoading ? (
                <MessageBox.Loading />
              ) : usersQuery.isError ? (
                <MessageBox.QueryError query={usersQuery} />
              ) : (
                <MessageBox.Info>Non ci sono risultati.</MessageBox.Info>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </ScreenWrapper>
  );
};

export default Dashboard;

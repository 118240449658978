import { t } from 'i18next';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { WorkspaceUser } from '../../../../types';
import Img from '../../../components/Img';
import { ScreenWrapper } from '../../../components/layouts/AppLayout';
import MessageBox from '../../../components/MessageBox';
import WorkspaceUsersTable from '../../../components/tables/WorkspaceUsersTable';
import TitleToolbar from '../../../components/TitleToolbar';
import { WorkspaceGroupModel } from '../../../generated/axios';
import { DEFAULT_PAGINATION, wsActions } from '../../../lib/config';
import defaultIcon from '../../../lib/defaultIcon';
import { deleteUserFromGroup } from '../../../lib/mutations';
import { useSingleGroupQuery, useUsersInGroup } from '../../../lib/queries';
import { useCrumbs } from '../../../lib/useCrumbs';
import { useApplicationContext } from '../../../providers/ApplicationProvider';

const StyledSpan = styled('span')(({ theme }) => ({
  display: 'inline-block',
  minWidth: '120px',
  marginRight: theme.spacing(2),
  fontWeight: '400',
}));

const Row = ({ name, value = '' }: { name: string; value?: string }) => {
  return (
    <Typography
      color="secondary"
      sx={{ fontWeight: '700', backgroundColor: '#fff', p: 1, borderRadius: 1 }}
    >
      <StyledSpan>{name}:</StyledSpan>
      {value}
    </Typography>
  );
};

const GroupItem = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_PAGINATION);
  const query = useSingleGroupQuery();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const params = useParams();
  const { id, idGroup } = params;

  const { currentWorkspace } = useApplicationContext();
  useCrumbs([
    { to: '/ws', label: 'Workspaces' },
    { to: `/ws/${currentWorkspace.id}`, label: `${currentWorkspace.name}` },
    { to: `/ws/${currentWorkspace.id}/groups`, label: wsActions.groups },
    { label: wsActions.info },
  ]);

  const getUserOfGroupQuery = useUsersInGroup(
    String(rowsPerPage),
    String(page),
  );

  const onError = (error: any) => {
    enqueueSnackbar(t('errors.generic'), { variant: 'error' });
  };

  const deleteUserFromGroupMutation = deleteUserFromGroup({
    onError,
    onSuccess: () => {
      enqueueSnackbar(t('res.saveOK'), { variant: 'success' });
      queryClient.invalidateQueries();
    },
  });

  if (query.isLoading) {
    return <MessageBox.Loading />;
  }

  // if (getUserOfGroupQuery.isLoading) {
  //   return <MessageBox.Loading />;
  // }

  if (query.isError) {
    return <MessageBox.QueryError query={query} />;
  }

  let usersOfGroup = [];
  let totalCount: number = 0;

  if (getUserOfGroupQuery.isError) {
    //return <MessageBox.QueryError query={getUserOfGroupQuery} />;
    usersOfGroup = [];
  } else {
    usersOfGroup = get(
      getUserOfGroupQuery,
      'data.data.data.users',
      [],
    ) as WorkspaceUser[];
    totalCount = get(
      getUserOfGroupQuery,
      'data.data.data.totalCount',
      [],
    ) as number;
  }

  const data = get(query, 'data.data.data') as WorkspaceGroupModel;

  const deleteFromGroup = (idKeycloak: string | number) => {
    deleteUserFromGroupMutation.mutate({
      id,
      idGroup,
      idKeycloak,
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <ScreenWrapper>
      <TitleToolbar title="Dettaglio del gruppo"></TitleToolbar>
      <Box sx={{ p: 2 }}>
        <Stack direction="column" spacing={1} sx={{ flexGrow: 1 }}>
          <Stack
            direction="row"
            spacing={3}
            sx={{ alignItems: 'center', marginBottom: 2 }}
          >
            <Img alt="alt" src="" altSrc={defaultIcon} className="ico" />
            <Typography variant="h6" color="secondary">
              {data.name}
            </Typography>
          </Stack>
          <Stack direction="column" spacing={1} sx={{ paddingLeft: 9 }}>
            <Row name="Nome" value={data.description} />
            <Row name="Descrizione" value={data.description} />
          </Stack>
        </Stack>
      </Box>

      <Box sx={{ p: 2 }}>
        <Stack direction="column" spacing={1} sx={{ flexGrow: 1 }}>
          <Stack
            direction="row"
            spacing={3}
            sx={{ alignItems: 'center', marginBottom: 2 }}
          >
            <Typography variant="h6" color="secondary">
              Utenti del gruppo
            </Typography>
          </Stack>
        </Stack>
        {usersOfGroup && usersOfGroup.length ? (
          <WorkspaceUsersTable
            hideActionsMenu={true}
            resetPassword={function (idWorkspace: string, idUser: string) {
              throw new Error('Function not implemented.');
            }}
            onDelete={function (idWorkspace: string, idUser: string) {
              throw new Error('Function not implemented.');
            }}
            onActivateDeactivate={function (
              idWorkspace: string,
              idUser: string,
              user: WorkspaceUser,
            ) {
              throw new Error('Function not implemented.');
            }}
            toggleState={(user) => {
              console.log('Function not implemented.');
            }}
            rows={usersOfGroup}
            totalCount={totalCount}
            workspace={currentWorkspace}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            deleteFromGroup={deleteFromGroup}
          />
        ) : (
          <MessageBox.Info>Non ci sono risultati.</MessageBox.Info>
        )}
      </Box>
    </ScreenWrapper>
  );
};

export default GroupItem;
function enqueueSnackbar(arg0: any, arg1: { variant: 'success' }) {
  throw new Error('Function not implemented.');
}

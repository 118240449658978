import '../App.css';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Select } from 'requiro-ui';
// import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import { Customer } from '../../types';
import { getRealms } from '../api/realmApi';
import {
  createWorkspace,
  getWorkspace,
  updateWorkspace,
} from '../api/workspaceApi';
import AddCustomersDialog from '../components/dialogs/AddCustomersDialog';
import MessageDialog from '../components/dialogs/MessageDialog';
import { ScreenWrapper } from '../components/layouts/AppLayout';
import TitleToolbar from '../components/TitleToolbar';
import { useCrumbs } from '../lib/useCrumbs';
import { useAppInitiator } from '../providers/AppInitiator';
import {
  ApplicationContext,
  useApplicationContext,
} from '../providers/ApplicationProvider';
import { isCustomerPresentInArray } from '../utilities/UtilitiesFunction';

const inputSx = { backgroundColor: '#fff' };

function Workspace(props: any) {
  const { changeWorkspace } = useApplicationContext();
  const context = useContext(ApplicationContext);
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const { boot } = useAppInitiator();
  const [workspace, setWorkspace] = React.useState(
    props.workspace ? props.workspace : ({} as any),
  );

  const [realm, setRealm] = React.useState(
    props.workspace ? props.workspace.realm : undefined,
  );

  // create breadcrumbs from location.state
  const links = [
    { to: '/ws', label: 'Workspaces' },
    {
      label:
        workspace && workspace.appCode
          ? `Modifica ${String(workspace.appCode)}`
          : 'Crea nuovo',
    },
  ];
  useCrumbs(links);

  const [title, setTitle] = React.useState(
    workspace && workspace.id ? 'Modifica workspace' : 'Nuovo workspace',
  );
  const [modifyMode, setModifyMode] = React.useState(
    workspace && workspace.id ? true : false,
  );
  const [enableSaveButton, setEnableSaveButton] = React.useState(false);
  const [textForMessageDialog, setTextForMessageDialog] = React.useState('');
  const [openMessageDialog, setOpenMessageDialog] = React.useState(false);
  const [realms, setRealms] = React.useState([]); //Lista dei realms proveniente da backend
  //let realms = ["acme", "standard"];

  const navigate = useNavigate();

  useEffect(() => {
    workspace.realm = realm;
  }, [realm]);

  useEffect(() => {
    setEnableSaveButton(controlEnableSaveButton());
  }, [workspace, context.customersOfSelectedWorkspace]);

  useEffect(() => {
    context.setCustomersOfSelectedWorkspace([]);
    fetchRealms();
    workspace ? fetchWorkspaceExtraInfo() : null;
  }, []);

  async function fetchRealms() {
    getRealms(boot.appServicesBaseUrl)
      .then((response: any) => {
        if (response && response.data && response.data.data) {
          setRealms(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //TODO: Da rifattorizzare
  async function fetchWorkspaceExtraInfo() {
    getWorkspace(
      boot.appServicesBaseUrl,
      workspace && workspace.id ? '/' + workspace.id : '',
    )
      .then((response: any) => {
        console.log(response);
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.customers &&
          context
        ) {
          let listaCustomers: Customer[] = [];
          response.data.data.customers.forEach((element: any) => {
            let c = {
              CompanyGroupCode: response.data.data.principalCustomerCode,
              CustomerCode: element.customerCode,
              CompanyName: element.companyName,
              VatNumber: element.vatNumber,
              BillingCode: element.billingCode,
            };
            listaCustomers.push(c);
          });
          console.log('LISTA CUSTOMERS', listaCustomers);
          //context.setCustomersOfSelectedWorkspace(response.data.data.customers ? response.data.data.customers : []);
          context.setCustomersOfSelectedWorkspace(listaCustomers);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const deleteCustomersFromWorkspace = () => {
    let newCustomersOfWorkspace: Customer[] = [];
    if (
      context &&
      context.selectedCustomersForAddingInWorkspace &&
      context.customersOfSelectedWorkspace
    ) {
      context.customersOfSelectedWorkspace.forEach((customer: Customer) => {
        if (
          context.selectedCustomersForAddingInWorkspace.length > 0 &&
          isCustomerPresentInArray(
            customer,
            context.selectedCustomersForAddingInWorkspace,
          ) === -1
        ) {
          newCustomersOfWorkspace.push(customer);
        }
      });
      context.setCustomersOfSelectedWorkspace(newCustomersOfWorkspace);
    }
  };

  const handleRealmChange = (event: SelectChangeEvent) => {
    setRealm(event.target.value);
  };

  const handleTextfieldsChange = (event: any) => {
    let workspaceMap = {} as any;
    if (event.target) {
      workspaceMap[event.target.id] = event.target.value;
    }
    setWorkspace({ ...workspace, ...workspaceMap });
  };

  const onCancel = () => {
    navigate(-1);
  };

  const saveWorkspace = () => {
    let workspaceDatiAggiuntivi = {} as any;

    workspaceDatiAggiuntivi.customers = context.customersOfSelectedWorkspace;

    if (workspaceDatiAggiuntivi && workspaceDatiAggiuntivi.customers) {
      workspaceDatiAggiuntivi.customers.forEach((customer: any) => {
        // workspaceDatiAggiuntivi.principalCustomerCode =
        //   customer.CompanyGroupCode;

        // POSSIBILE ERRORE: il principalCustomerCode viene sovrascritto ogni giro invece di rimanere fisso al principal
        // workspaceDatiAggiuntivi.principalCustomerCode = customer.CustomerCode;

        customer.billingCode = customer.BillingCode;
        customer.customerCode = customer.CustomerCode;
        customer.companyName = customer.CompanyName;
        customer.vatNumber = customer.VatNumber;
      });
      workspaceDatiAggiuntivi.contextName = 'AUTO-GENERATED'; //TODO: Da eliminare
    }

    setWorkspace({ ...workspace, ...workspaceDatiAggiuntivi });
    if (modifyMode) {
      modifyWorkspace({ ...workspace, ...workspaceDatiAggiuntivi });
    } else {
      creaWorkspace({ ...workspace, ...workspaceDatiAggiuntivi });
    }
  };

  const creaWorkspace = (workspace: any) => {
    createWorkspace(boot.appServicesBaseUrl, workspace)
      .then((response: any) => {
        console.log(response);
        setTextForMessageDialog(
          t('res.saveOK', 'Salvataggio effettuato con successo.'),
        );
        setOpenMessageDialog(true);
      })
      .catch((err) => {
        console.log(err.data);
        if (err.response.data.status.code === 400) {
          enqueueSnackbar(err.response.data.status.error.message, {
            variant: 'error',
          });
        }
      });
  };

  const modifyWorkspace = (workspace: any) => {
    updateWorkspace(boot.appServicesBaseUrl, workspace)
      .then((response: any) => {
        setTextForMessageDialog(
          t('res.saveOK', 'Salvataggio effettuato con successo.'),
        );
        setOpenMessageDialog(true);
        // update to show to new name in breadcrumbs
        changeWorkspace(workspace);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const controlEnableSaveButton = () => {
    return (
      workspace.realm &&
      workspace.appCode != null &&
      //workspace.contextName &&
      workspace.name &&
      workspace.description &&
      workspace.principalCustomerCode
    );
  };

  const handleCloseMessageDialog = () => {
    setOpenMessageDialog(false);
    navigate(-1);
  };

  const addBillingCustomerCode = () => {
    if (
      context &&
      context.selectedCustomersForAddingInWorkspace &&
      context.selectedCustomersForAddingInWorkspace.length > 0
    ) {
      let workspaceDatiAggiuntivi = {} as any;
      workspaceDatiAggiuntivi.customerCode =
        context.selectedCustomersForAddingInWorkspace[0].CustomerCode;
      workspaceDatiAggiuntivi.principalCustomerCode =
        context.selectedCustomersForAddingInWorkspace[0].CustomerCode;
      workspaceDatiAggiuntivi.customerCodeBilling =
        context.selectedCustomersForAddingInWorkspace[0].BillingCode ||
        context.selectedCustomersForAddingInWorkspace[0].CustomerCode;
      setWorkspace({ ...workspace, ...workspaceDatiAggiuntivi });
    }
  };

  return (
    <ScreenWrapper>
      {params.id ? (
        <TitleToolbar title="Modifica il workspace" />
      ) : (
        <>
          {/* <AppCrumbs /> */}
          <TitleToolbar title="Crea il workspace" />
        </>
      )}
      {/* <React.Fragment> */}
      {/* <Typography variant="h6" gutterBottom>
          {title}
        </Typography> */}
      {/* <Paper elevation={3} sx={{ p: 2 }}> */}
      <div style={{ margin: '16px -16px', display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ width: '100%', padding: '0 16px', marginBottom: '16px' }}>
          {params.id ? (
            <Input value={realm} disabled={!!params.id} fullWidth>
              {realm}
            </Input>
          ) : (
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={realm}
              onChange={handleRealmChange}
              label="Realm"
              name="realm"
              fullWidth
              autoWidth
              sx={inputSx}
            >
              {realms.map((real) => (
                <MenuItem value={real}>{real}</MenuItem>
              ))}
            </Select>
          )}
        </div>
        <div style={{ width: '50%', padding: '0 16px', marginBottom: '16px' }}>
          <Input
            required
            id="appCode"
            name="appCode"
            label="App Code"
            fullWidth
            variant="outlined"
            sx={inputSx}
            disabled={modifyMode}
            defaultValue={
              workspace && workspace.appCode ? workspace.appCode : null
            }
            onChange={handleTextfieldsChange}
          />
        </div>
        <div style={{ width: '50%', padding: '0 16px', marginBottom: '16px' }}>
          <Input
            required
            id="name"
            name="name"
            label="Nome Workspace"
            fullWidth
            variant="outlined"
            defaultValue={workspace && workspace.name ? workspace.name : null}
            onChange={handleTextfieldsChange}
            sx={inputSx}
          />
        </div>
        <div style={{ width: '50%', padding: '0 16px', marginBottom: '16px' }}>
          <Input
            required
            id="description"
            name="description"
            label="Descrizione"
            fullWidth
            variant="outlined"
            defaultValue={
              workspace && workspace.description ? workspace.description : null
            }
            onChange={handleTextfieldsChange}
            sx={inputSx}
          />
        </div>
        <div style={{ width: '50%', padding: '0 16px', marginBottom: '16px' }}>
          <Input
            required
            disabled
            id="principalCustomerCode"
            name="principalCustomerCode"
            label="Customer Code principale"
            fullWidth
            variant="outlined"
            value={
              workspace && workspace.principalCustomerCode
                ? workspace.principalCustomerCode
                : ''
            }
            //onChange={handleTextfieldsChange}
            sx={inputSx}
          />
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0 16px',
          }}
        >
          {!modifyMode ? (
            <AddCustomersDialog
              actionForSelectedCustomers={addBillingCustomerCode}
              singleSelection={true}
              customTitle="Scegli il Principal Customer"
              customButtonLabel="Aggiungi Principal Customer"
            ></AddCustomersDialog>
          ) : null}

          <Button
            onClick={onCancel}
            disableElevation
            variant="contained"
            color="secondary"
            sx={{ marginLeft: '16px' }}
          >
            Cancella
          </Button>

          <Button
            disabled={!enableSaveButton}
            onClick={saveWorkspace}
            disableElevation
            variant="contained"
            color="primary"
            sx={{ marginLeft: '16px' }}
          >
            Salva
          </Button>
        </div>
        <MessageDialog
          handleClose={handleCloseMessageDialog}
          text={textForMessageDialog}
          open={openMessageDialog}
          title={'Esito operazione'}
        ></MessageDialog>
      </div>
      {/* </Paper> */}
      {/* </React.Fragment> */}
    </ScreenWrapper>
  );
}

export default Workspace;

import { Formik } from 'formik';
import { t } from 'i18next';
import { get, pick } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Autocomplete, Button, Input } from 'requiro-ui';
import { Grid, Stack, TextFieldProps } from '@mui/material';
import Img from '../../components/Img';
import { ScreenWrapper } from '../../components/layouts/AppLayout';
import ScreenToolbar from '../../components/ScreenToolbar';
import TitleToolbar from '../../components/TitleToolbar';
import { ADD_ICON, EDIT_ICON, wsActions } from '../../lib/config';
import defaultIcon from '../../lib/defaultIcon';
import { useCrumbs } from '../../lib/useCrumbs';
import { FileInput, FormStyles } from './CatalogStyles';
import { useCatalogApp } from './helpers/queries';

const baseValues = {
  name: '',
  description: '',
  categories: [],
  icon: '',
};

const categories = [
  { id: '1', label: 'Produttività' },
  { id: '2', label: 'Finanza' },
  { id: '3', label: 'Integrazione' },
];
type ICat = { id: string; label: string };

const responsiveColumns = {
  xs: 12,
  sm: 12,
  md: 6,
};

const CatalogForm = () => {
  const params = useParams();
  const appInfo = useCatalogApp(params.id); //useSingleAppQuery();
  const [initialValues, setInitialValues] = useState(baseValues);

  const location = useLocation();

  const moreCrumbs = params.id
    ? [{ label: get(location, 'state.name', '') }, { label: wsActions.edit }]
    : [{ label: wsActions.new }];
  useCrumbs([{ to: '/catalog', label: 'Catalogo' }, ...moreCrumbs]);

  useEffect(() => {
    if (appInfo.status === 'success') {
      const temp = get(appInfo, 'data.data.data', {});
      const data = pick(temp, ['name', 'description']);
      const values = { ...initialValues, ...data };
      setInitialValues(values);
    }
  }, [appInfo.status]);

  return (
    <ScreenWrapper>
      <TitleToolbar title="Crea app" />
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values) => {
          console.log('NOT IMPLEMENTED', { values });
        }}
      >
        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => {
          return (
            <FormStyles onSubmit={handleSubmit}>
              <Grid container columnSpacing={4} rowSpacing={4}>
                <Grid item {...responsiveColumns}>
                  {/* <Typography variant="body1">Nome</Typography> */}
                  <Input
                    value={values.name}
                    onChange={(e: any) => setFieldValue('name', e.target.value)}
                    size="small"
                    helperText="Max 96 caratteri"
                    fullWidth
                    label="Nome"
                  />
                </Grid>
                <Grid item {...responsiveColumns}>
                  {/* <Typography variant="body1">Icona</Typography> */}
                  <Stack direction="row" spacing={4}>
                    <Img
                      alt="alt"
                      src=""
                      altSrc={defaultIcon}
                      className="ico"
                    />
                    <label htmlFor="icon-button-file">
                      <FileInput
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                      />
                      <Button variant="outlined" component="span" size="small">
                        {params.id ? EDIT_ICON : ADD_ICON}
                      </Button>
                    </label>
                  </Stack>
                </Grid>
                <Grid item {...responsiveColumns}>
                  {/* <Typography variant="body1">
                    {t('description', 'Descrizione')}
                  </Typography> */}
                  <Input
                    multiline
                    label="Descrizione"
                    maxRows={4}
                    value={values.description}
                    fullWidth
                    onChange={(e: any) =>
                      setFieldValue('description', e.target.value)
                    }
                    size="small"
                  />
                </Grid>
                <Grid item {...responsiveColumns}>
                  {/* <Typography variant="body1">
                    {t('categories', 'Categorie')}
                  </Typography> */}
                  <Autocomplete
                    multiple
                    label="Categorie"
                    options={categories}
                    getOptionLabel={(option: ICat) => option.label}
                    value={values.categories}
                    onChange={(event: any, value: ICat) => {
                      setFieldValue('categories', value);
                    }}
                    fullWidth
                    size="small"
                    renderInput={(params: TextFieldProps) => (
                      <Input {...params} />
                    )}
                  />
                  {/* <Autocomplete
                      freeSolo
                      disableClearable
                      multiple
                      size="small"
                      disablePortal
                      value={values.categories}
                      onChange={handleChange}
                      inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
                      options={categories}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <Input
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                    /> */}
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'right' }}>
                  <Button variant="contained" size="small">
                    {t('btn.label.confirm', 'Conferma')}
                  </Button>
                </Grid>
              </Grid>
            </FormStyles>
          );
        }}
      </Formik>
    </ScreenWrapper>
  );
};

export default CatalogForm;

import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AppsRouter from "../features/ws/apps";
import GroupsRouter from "../features/ws/groups";
import UsersRouter from "../features/ws/users";
import WSUsers from "../features/ws/users/WSUsers";
import WSApi from "../features/ws/WSApi";
import WSCustomers from "../features/ws/WSCustomers";
import WSEdit from "../features/ws/WSEdit";
import WSIndex from "../features/ws/WSIndex";
import WSItem from "../features/ws/WSItem";
import { useApplicationContext } from "../providers/ApplicationProvider";

const WorkspaceRoute = ({ children }: { children: any }) => {
  const { currentWorkspace } = useApplicationContext();
  return currentWorkspace ? children : <Navigate to="/ws" />;
};

const Workspaces = () => {
  return (
    <Routes>
      <Route path="" element={<WSIndex />} />
      <Route
        path=":id"
        element={
          <WorkspaceRoute>
            <WSItem />
          </WorkspaceRoute>
        }
      />
      <Route
        path=":id/users/*"
        element={
          <WorkspaceRoute>
            <UsersRouter />
          </WorkspaceRoute>
        }
      />
      <Route
        path=":id/customers/*"
        element={
          <WorkspaceRoute>
            <WSCustomers />
          </WorkspaceRoute>
        }
      />
      <Route
        path=":id/groups/*"
        element={
          <WorkspaceRoute>
            <GroupsRouter />
          </WorkspaceRoute>
        }
      />
      <Route
        path=":id/api/*"
        element={
          <WorkspaceRoute>
            <WSApi />
          </WorkspaceRoute>
        }
      />
      <Route
        path=":id/edit"
        element={
          <WorkspaceRoute>
            <WSEdit />
          </WorkspaceRoute>
        }
      />
      <Route
        path=":id/apps/*"
        element={
          <WorkspaceRoute>
            <AppsRouter />
          </WorkspaceRoute>
        }
      />
    </Routes>
  );
};

export default Workspaces;

import axios from 'axios';
import { resolve } from './resolve.js';

export async function fetchCustomers(coreUrl, queryString: string = '') {
  return await resolve(
    axios.get(
      `${coreUrl}/customers` +
        (queryString && queryString !== '' ? '?' + queryString : ''),
    ),
  );
}

import { get } from 'lodash';
import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import { t } from 'i18next';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tabs,
  Tab,
} from 'requiro-ui';

import { Order, DocumentalClassConfig } from '../../../../../../types';
import { ScreenWrapper } from '../../../../../components/layouts/AppLayout';
import MessageBox from '../../../../../components/MessageBox';
import TitleToolbar from '../../../../../components/TitleToolbar';
import { ScreenToolbarToolsComposite } from '../../../../../components/ScreenToolbarTools';
import {
  useSingleAppQuery,
  useSingleCustomerQuery,
  fetchFullClassesByCustomerCodeQuery,
  fetchFullSignByCustomerCodeQuery,
} from '../../../../../lib/queries';
import { useCrumbs } from '../../../../../lib/useCrumbs';
import { usePagination } from '../../../../../lib/usePagination';
import { tableRowsPerPageOptions } from '../../../../../lib/config';
import { useApplicationContext } from '../../../../../providers/ApplicationProvider';
import ConfirmationDialog from '../../../../../components/dialogs/ConfirmationDialog';
import { useAppInitiator } from '../../../../../providers/AppInitiator';

import {
  editConfiguration,
  editConfigurationSign,
  deleteConfiguration,
} from '../../../../../lib/mutations';

import {
  getComparator,
  stableSort,
} from '../../../../../utilities/UtilitiesFunction';

import KissflowClass from './KissflowClass';
import KissflowSign from './KissflowSign';
import KissflowIntegrationId from './KissflowIntegrationId';

const tabs = [
  { name: 'Classi documentali', value: 'classi_documentali' },
  { name: 'Requiro Sign API', value: 'requiro_sign_api' },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof DocumentalClassConfig,
  ) => void;
  order: Order;
  orderBy: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof DocumentalClassConfig;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'label',
    numeric: false,
    disablePadding: true,
    label: 'Nome',
  },
  {
    id: 'visible',
    numeric: false,
    disablePadding: true,
    label: 'Stato',
  },
  {
    id: 'enabled',
    numeric: false,
    disablePadding: true,
    label: 'Abilitata',
  },
];

const headCellsSign: readonly HeadCell[] = [
  {
    id: 'kfProcessId',
    numeric: false,
    disablePadding: true,
    label: 'Process ID',
  },
  {
    id: 'kfAccountId',
    numeric: false,
    disablePadding: true,
    label: 'Account ID',
  },
  {
    id: 'visible',
    numeric: false,
    disablePadding: true,
    label: 'Stato',
  },
  {
    id: 'enabled',
    numeric: false,
    disablePadding: true,
    label: 'Abilitata',
  },
];

const KissflowClasses = () => {
  const chosenTab = sessionStorage.getItem('chosen_tab')
    ? parseInt(sessionStorage.getItem('chosen_tab'), 10)
    : 0;

  const { boot } = useAppInitiator();
  const { enqueueSnackbar } = useSnackbar();
  const queryInfoApp = useSingleAppQuery();
  const { currentWorkspace } = useApplicationContext();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { customerCode } = params;

  const queryInfoCustomer = useSingleCustomerQuery(customerCode);
  const { rowsPerPage, onRowsPerPageChange } = usePagination();

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] =
    React.useState<keyof DocumentalClassConfig>('label');
  const [orderSign, setOrderSign] = React.useState<Order>('asc');
  const [orderBySign, setOrderBySign] =
    React.useState<keyof DocumentalClassConfig>('kfProcessId');
  const [classes, handleClasses] = React.useState([]);
  const [classesLoading, handleClassesLoading] = React.useState<boolean>(true);
  const [classesError, handleClassesError] = React.useState<boolean>(false);
  const [sign, handleSign] = React.useState([]);
  const [signLoading, handleSignLoading] = React.useState<boolean>(true);
  const [signError, handleSignError] = React.useState<string>('');
  const [action, setAction] = React.useState<string>(null);
  const [actionRow, setActionRow] = React.useState<DocumentalClassConfig>(null);
  const [page, setPage] = React.useState(0);
  const [pageSign, setPageSign] = React.useState(0);
  const [tab, setTab] = React.useState(chosenTab);

  const app = get(queryInfoApp, 'data.data.data', null);
  const customer = get(queryInfoCustomer, 'data.data.data', null);

  const configuredClasses = classes.filter((el) => el.configuration);

  // const configuredSign = sign.filter((el) => el.configuration);

  const mutation = editConfiguration({
    onError: (data: any) => {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
      fetchData(true);
      fetchDataSign(true);
    },
    onSuccess: (error: any) => {
      enqueueSnackbar(t('res.saveOK'), { variant: 'success' });
      fetchData(true);
      fetchDataSign(true);
    },
  });

  const mutationSign = editConfigurationSign({
    onError: (data: any) => {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
      fetchData(true);
      fetchDataSign(true);
    },
    onSuccess: (error: any) => {
      enqueueSnackbar(t('res.saveOK'), { variant: 'success' });
      fetchData(true);
      fetchDataSign(true);
    },
  });

  const mutationDelete = deleteConfiguration({
    onError: (data: any) => {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
      fetchData(true);
      fetchDataSign(true);
    },
    onSuccess: (error: any) => {
      enqueueSnackbar(t('res.saveOK'), { variant: 'success' });
      fetchData(true);
      fetchDataSign(true);
    },
  });

  useCrumbs([
    { to: '/ws', label: 'Workspaces' },
    { to: `/ws/${currentWorkspace.id}`, label: `${currentWorkspace.name}` },
    { to: `/ws/${currentWorkspace.id}/apps`, label: `App` },
    {
      to: `/ws/${currentWorkspace.id}/apps/${app && app.id ? app.id : ''}`,
      label: app && app.name ? app.name : '',
    },
    {
      label: customer
        ? `${customer.CompanyName}`
        : customerCode
        ? customerCode
        : '',
    },
  ]);

  const onDisable = (row: any) => {
    mutation.mutate({
      id: row.id,
      userName: JSON.parse(localStorage.currentUser).preferred_username,
      customerCode: row.customerCode,
      className: row.className,
      applicationCode: currentWorkspace.appCode,
      enabled: false,
    });
  };

  const onDisableSign = (row: any) => {
    mutationSign.mutate({
      id: row.id,
      userName: JSON.parse(localStorage.currentUser).preferred_username,
      customerCode: row.customerCode,
      className: row.className,
      applicationCode: currentWorkspace.appCode,
      enabled: false,
    });
  };

  const onEnable = (row: any) => {
    mutation.mutate({
      id: row.id,
      userName: JSON.parse(localStorage.currentUser).preferred_username,
      customerCode: row.customerCode,
      className: row.className,
      applicationCode: currentWorkspace.appCode,
      enabled: true,
    });
  };

  const onEnableSign = (row: any) => {
    mutationSign.mutate({
      id: row.id,
      userName: JSON.parse(localStorage.currentUser).preferred_username,
      customerCode: row.customerCode,
      className: row.className,
      applicationCode: currentWorkspace.appCode,
      enabled: true,
    });
  };

  const onDelete = () => {
    mutationDelete.mutate({
      id: actionRow.id,
      userName: JSON.parse(localStorage.currentUser).preferred_username,
      applicationCode: currentWorkspace.appCode,
    });

    setAction(null);
    setActionRow(null);
  };

  const onDeleteSign = () => {
    mutationDelete.mutate({
      id: actionRow.id,
      userName: JSON.parse(localStorage.currentUser).preferred_username,
      applicationCode: currentWorkspace.appCode,
    });

    setAction(null);
    setActionRow(null);
  };

  const onDeleteDialog = (row: any) => {
    setAction('delete');
    setActionRow(row);
  };

  const onDeleteDialogSign = (row: any) => {
    setAction('delete');
    setActionRow(row);
  };

  const onDeleteDialogClose = () => {
    setAction(null);
    setActionRow(null);
  };

  const onDeleteDialogSignClose = () => {
    setAction(null);
    setActionRow(null);
  };

  const onMenuClick = (row: any, type: string) => {
    switch (type) {
      case 'configure':
        navigate(
          `${location.pathname.split('/customer/')[0]}/customer/${String(
            row.customerCode,
          )}/configuration/${String(row.className)}`,
        );
        break;
      case 'subscription':
        navigate(
          `${location.pathname.split('/customer/')[0]}/customer/${String(
            row.customerCode,
          )}/subscription/${String(row.className)}`,
        );
        break;
      case 'history':
        navigate(
          `${location.pathname.split('/customer/')[0]}/customer/${String(
            row.customerCode,
          )}/history/${String(row.className)}`,
        );
        break;
      case 'disable':
        onDisable(row);
        break;
      case 'enable':
        onEnable(row);
        break;
      case 'delete':
        onDeleteDialog(row);
        break;

      default:
        break;
    }
  };

  const onMenuClickSign = (row: any, type: string) => {
    switch (type) {
      case 'configure':
        navigate(
          `${location.pathname.split('/customer/')[0]}/customer/${String(
            row.customerCode,
          )}/signconfiguration/${String(row.id)}`,
        );
        break;
      case 'disable':
        onDisableSign(row);
        break;
      case 'enable':
        onEnableSign(row);
        break;
      case 'delete':
        onDeleteDialogSign(row);
        break;

      default:
        break;
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangePageSign = (event: unknown, newPage: number) => {
    setPageSign(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onRowsPerPageChange(event);
    setPage(0);
    setPageSign(0);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof DocumentalClassConfig,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleRequestSortSign = (
    event: React.MouseEvent<unknown>,
    property: keyof DocumentalClassConfig,
  ) => {
    const isAsc = orderBySign === property && orderSign === 'asc';
    setOrderSign(isAsc ? 'desc' : 'asc');
    setOrderBySign(property);
  };

  const fetchData = async (second?: boolean) => {
    handleClassesError(false);
    if (!second) handleClassesLoading(true);
    const fullClasses = await fetchFullClassesByCustomerCodeQuery(
      boot.appKissflowServicesBaseUrl,
      customerCode,
    );
    if (fullClasses) {
      handleClasses(fullClasses);
    } else {
      handleClassesError(true);
    }

    if (!second) handleClassesLoading(false);
  };

  const fetchDataSign = async (second?: boolean) => {
    handleSignError('');
    if (!second) handleSignLoading(true);
    const fullSign = await fetchFullSignByCustomerCodeQuery(
      boot.appKissflowServicesBaseUrl,
      customerCode,
    );

    if (!fullSign.error) {
      handleSign(fullSign);
    } else {
      const isSignError =
        fullSign.error.toString() !== '' &&
        fullSign.error.toString().indexOf('404') === -1;
      if (!isSignError) {
        handleSign([]);
      } else {
        handleSignError(fullSign.error.toString());
      }
    }

    if (!second) handleSignLoading(false);
  };

  useEffect(() => {
    fetchData();
    fetchDataSign();
  }, []);

  if (classesLoading || signLoading) {
    return <MessageBox.Loading />;
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof DocumentalClassConfig) =>
      (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <strong>{headCell.label}</strong>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell key={'link'} align={'right'} padding={'normal'}>
            {' '}
          </TableCell>
          <TableCell key={'link2'} align={'right'} padding={'normal'}>
            {' '}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  function EnhancedTableHeadSign(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof DocumentalClassConfig) =>
      (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {headCellsSign.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <strong>{headCell.label}</strong>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell key={'link'} align={'right'} padding={'normal'}>
            {' '}
          </TableCell>
          <TableCell key={'link2'} align={'right'} padding={'normal'}>
            {' '}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const rows = configuredClasses || [];
  const rowsSign = sign || [];

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const emptyRowsSign =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsSign.length) : 0;

  const isClassiDocumentali = tab === 0;
  const isSignApi = tab === 1;

  const isSignError = signError !== '' && signError.indexOf('404') === -1;

  return (
    <ScreenWrapper>
      <Tabs
        sx={{ margin: '20px auto 0' }}
        onChange={(event, newValue) => {
          setTab(newValue);
          sessionStorage.setItem('chosen_tab', newValue);
        }}
        value={tab}
      >
        {tabs.map((t) => {
          return <Tab label={t.name} />;
        })}
      </Tabs>

      {isClassiDocumentali && (
        <div>
          <TitleToolbar title={t('classesTitle', 'Classi documentali')}>
            <ScreenToolbarToolsComposite.AddButton
              path={`${
                location.pathname.split('/customer/')[0]
              }/customer/${String(customerCode)}/configuration/add`}
              disabled={classesError}
            />
          </TitleToolbar>

          {classesError ? (
            <MessageBox.QueryError query={null} />
          ) : (
            <div>
              <TableContainer>
                <Table aria-labelledby="tableTitle" size="medium">
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />

                  <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
            rows.slice().sort(getComparator(order, orderBy)) */}
                    {stableSort(rows, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((row, index) => {
                        return (
                          <KissflowClass
                            key={row.classname}
                            row={row}
                            onMenuClick={onMenuClick}
                          />
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 53 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={tableRowsPerPageOptions}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          )}

          <KissflowIntegrationId />

          <ConfirmationDialog
            handleConfirmation={onDelete}
            handleClose={onDeleteDialogClose}
            text={t(
              'sureToDelete',
              'Sei sicuro di voler cancellare questa classe documentale?',
            )}
            open={action === 'delete'}
            title={t('attention', 'Attenzione')}
          ></ConfirmationDialog>
        </div>
      )}

      {isSignApi && (
        <div>
          <TitleToolbar title={t('classesTitle', 'Requiro sign API')}>
            <ScreenToolbarToolsComposite.AddButton
              path={`${
                location.pathname.split('/customer/')[0]
              }/customer/${String(customerCode)}/signconfiguration/add`}
              disabled={isSignError}
            />
          </TitleToolbar>

          {isSignError ? (
            <MessageBox.QueryError query={null} />
          ) : (
            <div>
              <TableContainer>
                <Table aria-labelledby="tableTitle" size="medium">
                  <EnhancedTableHeadSign
                    order={orderSign}
                    orderBy={orderBySign}
                    onRequestSort={handleRequestSortSign}
                  />

                  <TableBody>
                    {stableSort(rowsSign, getComparator(orderSign, orderBySign))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((row, index) => {
                        return (
                          <KissflowSign
                            key={row.id}
                            row={row}
                            onMenuClick={onMenuClickSign}
                          />
                        );
                      })}
                    {emptyRowsSign > 0 && (
                      <TableRow
                        style={{
                          height: 53 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={tableRowsPerPageOptions}
                component="div"
                count={rowsSign.length}
                rowsPerPage={rowsPerPage}
                page={pageSign}
                onPageChange={handleChangePageSign}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          )}

          <ConfirmationDialog
            handleConfirmation={onDeleteSign}
            handleClose={onDeleteDialogSignClose}
            text={t(
              'sureToDelete',
              'Sei sicuro di voler cancellare questa configurazione?',
            )}
            open={action === 'delete'}
            title={t('attention', 'Attenzione')}
          ></ConfirmationDialog>
        </div>
      )}
    </ScreenWrapper>
  );
};

export default KissflowClasses;

import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';
import { Customer, ICrumb, Workspace } from '../../types';
import { workspaceAtom } from '../state/jobs';

const clientiDelWorkspace: Customer[] = [];

//export const ApplicationContext = createContext<IContextInterface | null>(null);
export const ApplicationContext = createContext<any | null>(null);

export function ApplicationProvider({ children }: any) {
  const resetJobWorkspace = useResetRecoilState(workspaceAtom);
  const location = useLocation();
  // the selected/current workspace data
  const [currentWorkspace, setCurrentWorkspace] = useState<Workspace | null>();
  // current breadcrumbs items
  const [crumbs, setCrumbs] = useState<ICrumb[] | null>();
  // current view mode
  // const [currentViewMode, setCurrentViewMode] = useState<string>(
  //   () => localStorage.getItem(RQX_START_VIEW_MODE) || GRID_VIEW,
  // );

  // intercept navigation
  useEffect(() => {
    if (location.pathname.indexOf('/ws') === -1) {
      // reset currentWorkspace when outside /ws route
      setCurrentWorkspace(null);
    }
    if (location.pathname.indexOf('/jobs') === -1) {
      // reset workspace used in jobs
      resetJobWorkspace();
    }
  }, [location.pathname]);

  //
  const [customersOfSelectedWorkspace, setCustomersOfSelectedWorkspace] =
    useState<Customer[]>(clientiDelWorkspace);
  const [
    selectedCustomersForAddingInWorkspace,
    setSelectedCustomersForAddingInWorkspace,
  ] = useState<Customer[]>([]);

  const changeWorkspace = (ws?: Workspace) => setCurrentWorkspace(ws);
  const changeCrumbs = (crumbs?: ICrumb[]) => setCrumbs(crumbs);
  // const changeViewMode = (mode: string) => {
  //   localStorage.setItem(RQX_START_VIEW_MODE, mode);
  //   setCurrentViewMode(mode);
  // };

  const appContextValue = {
    currentWorkspace,
    crumbs,
    // currentViewMode,
    customersOfSelectedWorkspace,
    selectedCustomersForAddingInWorkspace,
    changeWorkspace,
    changeCrumbs,
    // changeViewMode,
    setCustomersOfSelectedWorkspace,
    setSelectedCustomersForAddingInWorkspace,
  };

  // useEffect(() => {
  //   console.log(customersOfSelectedWorkspace);
  // }, [customersOfSelectedWorkspace]);

  // console.log({ appContextValue });

  return (
    <ApplicationContext.Provider value={appContextValue}>
      {children}
    </ApplicationContext.Provider>
  );
}

export function useApplicationContext() {
  const ctx = useContext(ApplicationContext);
  if (!ctx) throw new Error('ApplicationContext connot be undefined');
  return ctx;
}

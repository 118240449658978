import React from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from 'requiro-ui';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {
  Order,
  Workspace,
  WorkspaceUser,
  WorkspaceUsersTableProps,
} from '../../../types';
import { tableRowsPerPageOptions } from '../../lib/config';
import ConfirmButton from '../buttons/ConfirmButton';
import ConfirmSwitch from '../buttons/ConfirmSwitch';
import WorkspaceUsersMenu from '../menu/WorkspaceUsersMenu';
import { DisabledIcon, EnabledIcon } from '../Styled';

interface HeadCell {
  disablePadding: boolean;
  id: keyof WorkspaceUser;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'username',
    numeric: false,
    disablePadding: true,
    label: 'Username',
  },
  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'lastName',
    numeric: false,
    disablePadding: false,
    label: 'Cognome',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'E-mail',
  },
  {
    id: 'state',
    numeric: false,
    disablePadding: false,
    label: 'Stato',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof WorkspaceUser,
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  visualizeActions?: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    visualizeActions,
  } = props;
  const createSortHandler =
    (property: keyof WorkspaceUser) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            //align={headCell.numeric ? 'right' : 'left'}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontWeight: '700' }}
          >
            {headCell.label}
            {/*
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
              */}
          </TableCell>
        ))}
        {visualizeActions ? (
          <TableCell
            key={'actions'}
            align={'left'}
            padding={'normal'}
            sortDirection={orderBy === 'actions' ? order : false}
            sx={{ fontWeight: '700' }}
          ></TableCell>
        ) : null}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.secondary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} Utenti del Workspace{' '}
          {numSelected === 1 ? 'selezionato' : 'selezionati'}
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Lista Utenti del Workspace
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Aggiungi utente">
          <Link
            to="/addUser"
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            <IconButton>
              <AddCircleIcon color="secondary" fontSize="large" />
            </IconButton>
          </Link>
        </Tooltip>
      )}
    </Toolbar>
  );
};

export default function WorkspaceUsersTable(props: WorkspaceUsersTableProps) {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof WorkspaceUser>('id');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState<WorkspaceUser[]>(
    props.rows ? props.rows : [],
  );
  const [workspace, setWorkspace] = React.useState<Workspace>(props.workspace);

  React.useEffect(() => {
    // console.log('CHANGED', props.rows);
    setRows(props.rows);
  }, [props.rows]);

  React.useEffect(() => {
    // console.log('CHANGED WORKSPACE', props.workspace);
    setWorkspace(props.workspace);
  }, [props.workspace]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof WorkspaceUser,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
      <TableContainer>
        <Table
          sx={{ margin: '10px', width: '98%' }}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            visualizeActions={props.hideActionsMenu ? false : true}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
            {
              /* stableSort(props.rows, getComparator(order, orderBy))
                .slice(props.page * rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage)
                */
              props.rows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    // onClick={(event) => handleClick(event, row.id)}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell
                      //component="th"
                      id={labelId}
                      //scope="row"
                      padding="none"
                      align="left"
                      //onClick={(event) => handleClick(event, row.id)}
                    >
                      {row.username}
                    </TableCell>
                    <TableCell align="left">{row.firstName}</TableCell>
                    <TableCell align="left">{row.lastName}</TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">
                      {row.state === 1 ? <EnabledIcon /> : <DisabledIcon />}
                    </TableCell>
                    {props.hideActionsMenu ? (
                      <TableCell align="left">
                        <ConfirmButton
                          action={() => {
                            props.deleteFromGroup(row.id);
                          }}
                        >
                          <ConfirmButton.DeleteIcon />
                        </ConfirmButton>
                        {/* <Tooltip title="Rimuovi dal gruppo XXX">
                          <IconButton
                            size="large"
                            onClick={(_e) => props.deleteFromGroup(row.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip> */}
                      </TableCell>
                    ) : (
                      <TableCell align="left">
                        <Stack
                          direction="row"
                          spacing={2}
                          sx={{ justifyContent: 'flex-end' }}
                        >
                          <ConfirmSwitch
                            checked={row.state === 1}
                            onChange={(_e: any) => {
                              props.toggleState && props.toggleState(row);
                            }}
                          />
                          <WorkspaceUsersMenu
                            onDelete={props.onDelete}
                            onActivateDeactivate={props.onActivateDeactivate}
                            workspace={workspace}
                            workspaceUser={row}
                            resetPassword={props.resetPassword}
                          ></WorkspaceUsersMenu>
                        </Stack>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })
            }
            {/* {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={tableRowsPerPageOptions}
        component="div"
        count={props.totalCount ? props.totalCount : props.rows.length}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        onPageChange={props.handleChangePage}
        onRowsPerPageChange={props.handleChangeRowsPerPage}
      />
    </Box>
  );
}

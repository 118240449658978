import { get } from 'lodash';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Item, Name, Value } from '../../components/display/Typo';
import Img from '../../components/Img';
import { ScreenWrapper } from '../../components/layouts/AppLayout';
import MessageBox from '../../components/MessageBox';
import ScreenToolbar from '../../components/ScreenToolbar';
import TitleToolbar from '../../components/TitleToolbar';
import { AppModel } from '../../generated/axios';
import { wsActions } from '../../lib/config';
import defaultIcon from '../../lib/defaultIcon';
import { useCrumbs } from '../../lib/useCrumbs';
import { dateTimeFormatter } from '../../lib/utils';
import { useCatalogApp } from './helpers/queries';

const StyledSpan = styled('span')(({ theme }) => ({
  display: 'inline-block',
  minWidth: '100px',
  marginRight: theme.spacing(1),
  fontWeight: '400',
}));

const Row = ({ name, value = '' }: { name: string; value?: string }) => {
  return (
    <Typography sx={{ fontWeight: '700' }}>
      <StyledSpan>{name}:</StyledSpan>
      {value}
    </Typography>
  );
};

const CatalogItem = () => {
  const location = useLocation();
  const params = useParams();
  const appInfo = useCatalogApp(params.id); // useSingleAppQuery();
  useCrumbs([
    { to: '/catalog', label: 'Catalogo' },
    { label: get(location, 'state.name', '') },
    { label: wsActions.info },
  ]);

  if (appInfo.isLoading) {
    return <MessageBox.Loading />;
  }

  if (appInfo.isError) {
    return <MessageBox.QueryError query={appInfo} />;
  }

  const data = get(appInfo, 'data.data.data', {}) as AppModel;

  // {
  //   "id": 6,
  //   "state": 1,
  //   "code": "requiroexchange",
  //   "version": "1.0",
  //   "name": "Requiro Exchange",
  //   "description": "I tuoi canali di scambio documentale",
  //   "vendor": "Archiva",
  //   "releasedOn": 1645012193.35095,
  //   "createdOn": 1645012193.35095,
  //   "modifiedOn": 1645012193.35095
  // }

  return (
    <ScreenWrapper>
      <TitleToolbar title="Dettaglio app" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            spacing={3}
            sx={{ alignItems: 'center', marginBottom: 2 }}
          >
            <Img alt="alt" src="" altSrc={defaultIcon} className="ico" />
            <Typography variant="h6">{data.name}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Item>
            <Name>Descrizione</Name>
            <Value>{data.description}</Value>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
            <Name>Codice</Name>
            <Value>{data.code}</Value>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
            <Name>Vendor</Name>
            <Value>{data.vendor}</Value>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
            <Name>Versione</Name>
            <Value>{data.version}</Value>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
            <Name>Creata il</Name>
            <Value>{dateTimeFormatter(data.createdOn)}</Value>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
            <Name>Modificata il</Name>
            <Value>{dateTimeFormatter(data.modifiedOn)}</Value>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
            <Name>Rilasciata il</Name>
            <Value>{dateTimeFormatter(data.releasedOn)}</Value>
          </Item>
        </Grid>
      </Grid>
      {/* <Stack direction="column" spacing={1} sx={{ paddingLeft: 9 }}>
                <Row name="Descrizione" value={data.description} />
                <Row name="Codice" value={data.code} />
                <Row name="Vendor" value={data.vendor} />
                <Row name="Versione" value={data.version} />
                <Row name="Creata il" value={data.createdOn} />
                <Row name="Modificata il" value={data.modifiedOn} />
                <Row name="Rilasciata il" value={data.releasedOn} />
              </Stack> */}
    </ScreenWrapper>
  );
};

export default CatalogItem;

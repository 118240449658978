import React from 'react';
import { NavLink } from 'react-router-dom';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ElectricalServicesOutlinedIcon from '@mui/icons-material/ElectricalServicesOutlined';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useApplicationContext } from '../../providers/ApplicationProvider';

const StyledStack = styled(Stack)(({ theme }) => ({
  opacity: '0.1',
  height: '20px',
  boxShadow: '0px 10px 10px -9px rgb(0, 0, 0, 0.1)',
  justifyContent: 'center',
  width: '80%',
  marginLeft: 'auto !important',
  marginRight: 'auto !important',
  marginBottom: 0,
  transition: 'all ease 0.25s',

  '&.activeStyledStack': {
    opacity: '1',
    height: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
}));

const StyledLink = styled(NavLink)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration: 'none',
  color: '#1A416B',
  border: '1px solid rgba(0,0,0,0.025)',
  borderBottom: 0,
  padding: '12px 10px',
  minWidth: '140px',
  borderRadius: '4px',
  backgroundColor: '#d5eff1',
  transition: 'all ease 0.25s',

  '&.active': {
    color: 'white',
    backgroundColor: '#1A416B',
  },
}));

const WSNav = () => {
  const { currentWorkspace } = useApplicationContext();

  return (
    <StyledStack
      direction="row"
      spacing={1}
      className={currentWorkspace ? 'activeStyledStack' : ''}
    >
      {currentWorkspace ? (
        <>
          <StyledLink to={`/ws/${currentWorkspace.id}`} end>
            <InfoOutlinedIcon
              sx={{ width: '24px', height: '24px', mr: 1 }}
              color="inherit"
            />{' '}
            <Typography sx={{ ml: 1 }}>Info</Typography>
          </StyledLink>
          <StyledLink to={`/ws/${currentWorkspace.id}/apps`}>
            <GridViewOutlinedIcon
              sx={{ width: '24px', height: '24px', mr: 1 }}
              color="inherit"
            />{' '}
            <Typography sx={{ ml: 1 }}>App</Typography>
          </StyledLink>
          <StyledLink to={`/ws/${currentWorkspace.id}/customers`}>
            <FactoryOutlinedIcon
              sx={{ width: '24px', height: '24px', mr: 1 }}
              color="inherit"
            />{' '}
            <Typography sx={{ ml: 1 }}>Clienti</Typography>
          </StyledLink>
          <StyledLink to={`/ws/${currentWorkspace.id}/groups`}>
            <GroupWorkOutlinedIcon
              sx={{ width: '24px', height: '24px', mr: 1 }}
              color="inherit"
            />{' '}
            <Typography sx={{ ml: 1 }}>Gruppi utente</Typography>
          </StyledLink>
          <StyledLink to={`/ws/${currentWorkspace.id}/users`}>
            <AccountCircleOutlinedIcon
              sx={{ width: '24px', height: '24px', mr: 1 }}
              color="inherit"
            />{' '}
            <Typography sx={{ ml: 1 }}>Utenti</Typography>
          </StyledLink>
        </>
      ) : null}
    </StyledStack>
  );
};

export default WSNav;

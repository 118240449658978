import { isEmpty, isNil } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
// import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Typography } from '@mui/material';
import { ICrumb } from '../../types';
import { useApplicationContext } from '../providers/ApplicationProvider';

const AppCrumbs = () => {
  const { crumbs } = useApplicationContext();
  // const crumbs = useRecoilValue(breadcrumbState);

  if (isNil(crumbs) || isEmpty(crumbs)) return null;

  return (
    <Breadcrumbs
      sx={{ marginTop: '20px' }}
      separator={
        <NavigateNextIcon
          fontSize="small"
          color="secondary"
          sx={{ position: 'relative', bottom: '-2px', fontSize: '20px' }}
        />
      }
    >
      <Link to="/" style={{ textDecoration: 'none' }}>
        {/* <HomeIcon color="secondary" /> */}

        <Typography color="secondary" sx={{ fontFamily: 'WotfardLight' }}>
          Home
        </Typography>
      </Link>
      {crumbs
        ? crumbs.map((item: ICrumb) =>
            item.to ? (
              <Link
                key={item.label}
                to={item.to}
                style={{ textDecoration: 'none' }}
              >
                <Typography
                  color="secondary"
                  sx={{ fontFamily: 'WotfardLight' }}
                >
                  {item.label.toLowerCase()}
                </Typography>
              </Link>
            ) : (
              <Typography key={item.label} color="secondary">
                {item.label.toLowerCase()}
              </Typography>
            ),
          )
        : null}
    </Breadcrumbs>
  );
};

export default AppCrumbs;

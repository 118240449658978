import React, { ReactNode } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { IHasChildren } from '../../types';
import { extractErrorMessage } from '../lib/utils';

const Styled = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(4),
}));

export const Loading = () => (
  <MessageBox>
    <HourglassBottomIcon color="info" />
    <Typography sx={{ ml: 2 }}>Loading data ...</Typography>
  </MessageBox>
);

export const QueryError = ({ query }: { query: any }) => (
  <MessageBox>
    <ErrorOutlineIcon color="error" />
    <Typography sx={{ ml: 2 }}>{extractErrorMessage(query)}</Typography>
  </MessageBox>
);

export const Info = ({ children }: IHasChildren) => (
  <MessageBox>
    <InfoOutlinedIcon color="info" />
    <Typography sx={{ ml: 2 }}>{children}</Typography>
  </MessageBox>
);

const MessageBox = ({ children }: { children: ReactNode }) => {
  return <Styled sx={{ width: '100%' }}>{children}</Styled>;
};

MessageBox.Loading = Loading;
MessageBox.QueryError = QueryError;
MessageBox.Info = Info;

export default MessageBox;

import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { Navigate } from 'react-router-dom';

function RequireAuth({ children }: any) {
  //const [keycloak] = useKeycloak();
  const { keycloak, initialized } = useKeycloak();

  keycloak.loadUserInfo().then((res) => {
    localStorage.currentUser = JSON.stringify(res);
  });

  if (!initialized || !keycloak || !keycloak.authenticated) {
    // Redirect them to the login page
    return keycloak.login();
    //return <Navigate to="/" />;
  }
  return children;
}

export default RequireAuth;

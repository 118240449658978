import React from 'react';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';
import { useIntegrationIdQuery } from '../../../../../lib/queries';
import TitleToolbar from '../../../../../components/TitleToolbar';
import { useSnackbar } from 'notistack';

import { Button } from 'requiro-ui';

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

const KissflowIntegrationIdSign = ({
  integrationId,
}: {
  integrationId: string;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const onCopy = () => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(integrationId);
    } else {
      fallbackCopyTextToClipboard(integrationId);
    }
    enqueueSnackbar(t('copyToclipBoardSuccess', 'GUID copiato con successo!'), {
      variant: 'success',
    });
  };

  return (
    <div>
      <TitleToolbar title={t('integration', 'Integration GUUID')} />

      <div
        style={{
          marginTop: '0',
          marginBottom: '30px',
          fontFamily: 'WotfardLight',
          fontSize: '16px',
          display: 'flex',
          alignItems: 'center',
          minWidth: '500px',
        }}
      >
        <span
          style={{
            padding: '11px 16px 10px 16px',
            marginRight: '8px',
            display: 'inline-block',
            borderRadius: '8px',
            background: '#fff',
            border: `1px solid ${integrationId ? '#fff' : '#f44336'}`,
          }}
        >
          {integrationId ? (
            integrationId
          ) : (
            <span style={{ color: '#f44336' }}>no integration id found</span>
          )}
        </span>
        <Button onClick={onCopy} color="secondary" disabled={!integrationId}>
          {t('copytoclipboard', 'Copy')}
        </Button>
      </div>
    </div>
  );
};

export default KissflowIntegrationIdSign;

import { get } from 'lodash';
import React, { createContext, useContext, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Table, TableBody, TableHead, TableRow } from 'requiro-ui';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { TaskModel } from '../../../types';
import { ScreenWrapper } from '../../components/layouts/AppLayout';
import MessageBox from '../../components/MessageBox';
import { ScreenToolbarToolsComposite } from '../../components/ScreenToolbarTools';
import Row, { Cell } from '../../components/tables/Cells';
import TitleToolbar from '../../components/TitleToolbar';
import { blob } from '../../lib/blob';
import { useWorkspaces } from '../../lib/queries';
import { useCrumbs } from '../../lib/useCrumbs';
import { useAppInitiator } from '../../providers/AppInitiator';
import { workspaceAtom, workspaceIdAtom } from '../../state/jobs';
import LogsDisplay from './components/LogsDisplay';
import { IJob } from './helpers/mocks';
import {
    useJobs, useWorkspaceTask, useWorkspaceTaskLogs, useWorkspaceTasks
} from './helpers/queries';
import { IHeaderCell, tableCells } from './helpers/tableCells';
import { useSearchWorkspace } from './helpers/useSearchWorkspace';

// GET     /workspace/{id}/task/{idTask}/input ✔️
// GET     /workspace/{id}/task/{idTask}/output ✔️ // download csv

const JobsContext = createContext({
  action: ({ action, data }: { action: string; data: TaskModel }) => {},
});

const Jobs = () => {
  const [open, toggle] = useState(false);

  const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);
  const { boot, token } = useAppInitiator();
  const { keycloak } = useKeycloak();
  const kcToken: string = keycloak && keycloak.token ? keycloak.token : '';

  useCrumbs([{ label: 'Job Manager' }]);

  const {
    workspacesQuery,
    searchKey,
    workspaceId,
    selectedWorkspace,
    handleSearch,
    setSearchKey,
  } = useSearchWorkspace();

  const tasksQuery = useWorkspaceTasks(workspaceId);

  const handleClose = () => toggle(false);

  const handleOpen = () => {
    toggle(true);
  };
  const actionHandler = ({
    action,
    data,
  }: {
    action: string;
    data: TaskModel;
  }) => {
    if (action === 'log') {
      setSelectedTaskId(String(data.id));
      handleOpen();
    }
    if (action === 'download_input') {
      // window.open(
      //   `${boot.appServicesBaseUrl}/workspace/${workspaceId}/task/${String(
      //     data.id,
      //   )}/input`,
      // );
      console.log({ kcToken });

      blob(
        kcToken,
        `${boot.appServicesBaseUrl}/workspace/${workspaceId}/task/${String(
          data.id,
        )}/input`,
      );
    }
    if (action === 'download_output') {
      blob(
        kcToken,
        `${boot.appServicesBaseUrl}/workspace/${workspaceId}/task/${String(
          data.id,
        )}/output`,
      );
    }
  };

  const keyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // console.log(event.key);
    if (event.key.toLocaleLowerCase() === 'enter') {
      handleSearch();
    }
  };

  const onSearchKeyChange = (value) => {
    setSearchKey(value);
    setSelectedTaskId(null);
  };

  const contextValue = {
    action: actionHandler,
  };

  return (
    <ScreenWrapper>
      <TitleToolbar title="Job manager">
        {workspaceId ? (
          <ScreenToolbarToolsComposite.AddButton label="Aggiungi job" />
        ) : null}
        <ScreenToolbarToolsComposite.SearchBox
          options={
            workspacesQuery.isSuccess &&
            workspacesQuery.data &&
            workspacesQuery.data.workspaces
              ? workspacesQuery.data.workspaces
              : []
          }
          setSearchKey={onSearchKeyChange}
          searchKey={searchKey}
          handleSearch={handleSearch}
          handleKeyUp={keyUpHandler}
          label="Seleziona un workspace"
        />
        {workspaceId ? (
          <ScreenToolbarToolsComposite.Refetch action={tasksQuery.refetch} />
        ) : null}
      </TitleToolbar>
      {selectedWorkspace ? (
        <Typography
          color="secondary"
          sx={{ p: 2, backgroundColor: '#fff', borderRadius: '16px' }}
        >{`Workspace selezionato: ${selectedWorkspace.name}`}</Typography>
      ) : null}

      {tasksQuery.status === 'idle' ? (
        <MessageBox.Info>Seleziona un workspace</MessageBox.Info>
      ) : null}

      {/* data table */}

      {tasksQuery.isLoading ? <MessageBox.Loading /> : null}

      {tasksQuery.isError ? <MessageBox.QueryError query={tasksQuery} /> : null}

      {tasksQuery.isSuccess ? (
        tasksQuery.data.tasks ? (
          <JobsContext.Provider value={contextValue}>
            <Table>
              <TableHead>
                <TableRow>
                  {tableCells.map((cell: IHeaderCell) => (
                    <Cell.Header key={cell.id} info={cell} />
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {get(tasksQuery, 'data.tasks', []).map((row: TaskModel) => (
                  <Row key={row.id} info={row} map={tableCells} />
                ))}
              </TableBody>
            </Table>
          </JobsContext.Provider>
        ) : (
          <MessageBox.Info>Non ci sono dati da visualizzare.</MessageBox.Info>
        )
      ) : null}
      {workspaceId && selectedTaskId ? (
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth="md"
          scroll="paper"
        >
          <DialogTitle>
            {`Logs (job id: ${selectedTaskId})`}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <LogsDisplay
              workspaceId={workspaceId}
              selectedTaskId={selectedTaskId}
            />
          </DialogContent>
        </Dialog>
      ) : null}
    </ScreenWrapper>
  );
};

export function useJobsContext() {
  const ctx = useContext(JobsContext);
  if (!ctx) throw new Error('JobsContext must not be undefined!');
  return ctx;
}

export default Jobs;

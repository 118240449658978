import React from 'react';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { wsActions } from '../../lib/config';
import { UserManagementContext } from '../../pages/UserManagement';
import ConfirmSwitch from '../buttons/ConfirmSwitch';
import LockResetIcon from '@mui/icons-material/LockReset';

export default function MenuUsersRow({ data }: { data: any }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { actionHandler } = React.useContext(UserManagementContext);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ justifyContent: 'flex-end', display: 'flex' }}>
      {/* <Switch
        sx={{ mr: 2 }}
        checked={data.enabled}
        onChange={(_e: any) =>
          actionHandler({
            action: wsActions.toggleState,
            data,
          })
        }
      /> */}
      <ConfirmSwitch
        checked={data.enabled}
        onChange={(_e: any) =>
          actionHandler({
            action: wsActions.toggleState,
            data,
          })
        }
      />
      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            actionHandler({ data, action: 'view' });
          }}
        >
          <ListItemIcon>
            <EditIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          Modifica
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            actionHandler({ data, action: 'delete' });
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          Cancella
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            actionHandler({ data, action: 'resetpsw' });
          }}
        >
          <LockResetIcon>
            <DeleteIcon fontSize="small" color="secondary" />
          </LockResetIcon>
          Reset password
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            handleClose();
            actionHandler({ data, action: 'toggleState' });
          }}
        >
          <ListItemIcon>
            <ArchiveIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          {data.enabled ? 'Disabilita' : 'Abilita'}
        </MenuItem> */}
      </Menu>
    </Box>
  );
}

import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from 'requiro-ui';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import GridViewIcon from '@mui/icons-material/GridView';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import ViewListIcon from '@mui/icons-material/ViewList';
import { Autocomplete, ButtonProps, IconButton, Stack, TextField, Tooltip } from '@mui/material';
import { IHasChildren } from '../../types';
import { GRID_VIEW, LIST_VIEW } from '../lib/config';

/**
 * Ricerca con autocomplete
 * @param param0
 * @returns
 */
const SearchBox = ({
  options,
  searchKey,
  setSearchKey,
  handleSearch,
  handleKeyUp,
  label = 'Cerca',
}: {
  options: any[];
  searchKey?: string;
  label?: string;
  setSearchKey?: (value: string) => void;
  handleKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleSearch?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={1}
    >
      <Autocomplete
        freeSolo
        disableClearable
        size="small"
        disablePortal
        options={options.map((opt) => opt.name)}
        sx={{ width: 300 }}
        inputValue={searchKey}
        onInputChange={(event, newInputValue) => {
          setSearchKey && setSearchKey(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Cerca"
            onKeyUp={handleKeyUp}
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
      />
      {handleSearch ? (
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={handleSearch}
          startIcon={<SearchIcon />}
        >
          {label}
        </Button>
      ) : null}
    </Stack>
  );
};

/**
 * Ricerca con autocomplete
 * @param param0
 * @returns
 */
const SearchBoxManual = ({
  options,
  searchKey,
  setSearchKey,
  handleSearch,
  label = 'Cerca',
}: {
  options: any[];
  searchKey?: string;
  label?: string;
  setSearchKey?: (value: string) => void;
  handleSearch?: (key: string) => void;
}) => {
  const [key, setKey] = useState('');

  const keyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // console.log('SearchBoxManual', event.key);
    if (event.key.toLocaleLowerCase() === 'enter') {
      handleSearch(key);
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={1}
    >
      <Autocomplete
        freeSolo
        disableClearable
        size="small"
        disablePortal
        options={options.map((opt) => opt.name)}
        sx={{ width: 300 }}
        inputValue={key}
        onInputChange={(event, newInputValue) => {
          setKey(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Cerca"
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
            onKeyUp={keyUpHandler}
          />
        )}
      />
      {handleSearch ? (
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={() => {
            handleSearch(key);
          }}
          startIcon={<SearchIcon />}
        >
          {label}
        </Button>
      ) : null}
    </Stack>
  );
};

/**
 * Ricerca in base all'input utente
 * @param handleSearch
 * @returns
 */
const SimpleSearchBox = ({ handleSearch }) => {
  const [searchKey, setSearchKey] = useState('');

  const keyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // console.log('SimpleSearchBox', event.key);
    if (event.key.toLocaleLowerCase() === 'enter') {
      handleSearch(searchKey);
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={1}
    >
      <Input
        sx={{ width: '260px', backgroundColor: '#fff' }}
        variant="outlined"
        size="small"
        placeholder="Search key"
        value={searchKey}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearchKey(e.target.value)
        }
        onKeyUp={keyUpHandler}
      />
      <Button
        color="primary"
        size="small"
        onClick={() => handleSearch(searchKey)}
        variant="contained"
        startIcon={<SearchIcon />}
      >
        Cerca
      </Button>
    </Stack>
  );
};

const GridViewIconButton = ({
  action,
}: {
  action: (value: string) => void;
}) => (
  <Tooltip title="Vista griglia">
    <Button onClick={() => action(GRID_VIEW)} color="primary">
      <GridViewIcon />
    </Button>
  </Tooltip>
);

const ListViewIconButton = ({
  action,
}: {
  action: (value: string) => void;
}) => (
  <Tooltip title="Vista lista">
    <IconButton onClick={() => action(LIST_VIEW)} color="primary">
      <ViewListIcon />
    </IconButton>
  </Tooltip>
);

/**
 *
 */
const GridViewButton = ({ action }: { action: (value: string) => void }) => (
  <Button
    onClick={() => action(GRID_VIEW)}
    color="primary"
    endIcon={<GridViewIcon />}
    size="small"
  >
    Grid view
  </Button>
);

/**
 *
 */
const ListViewButton = ({ action }: { action: (value: string) => void }) => (
  <Button
    onClick={() => action(LIST_VIEW)}
    color="primary"
    endIcon={<ViewListIcon />}
    size="small"
  >
    Grid view
  </Button>
);

/**
 *
 */
const GenericButton = (props: ButtonProps) => {
  const { children, ...otherProps } = props;

  return <Button {...otherProps}>{children}</Button>;
};

/**
 *
 */
const ToggleViewButton = ({
  currentViewMode,
  action,
}: {
  currentViewMode: string;
  action: (value: string) => void;
}) => {
  const changeViewMode = () => {
    const nextMode = currentViewMode === LIST_VIEW ? GRID_VIEW : LIST_VIEW;

    action(nextMode);
  };

  return (
    <Button
      onClick={changeViewMode}
      color="primary"
      endIcon={
        currentViewMode === LIST_VIEW ? <GridViewIcon /> : <ViewListIcon />
      }
      size="small"
    >
      {currentViewMode === LIST_VIEW ? 'Grid view' : 'List view'}
    </Button>
  );
};

const AddIconButton = ({ path = 'new' }: { path?: string }) => {
  const navigate = useNavigate();
  return (
    <Tooltip title="Crea nuovo">
      <IconButton onClick={() => navigate(path)}>
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
};

const AddButton = ({
  path = 'new',
  icon = <AddIcon />,
  label = 'Crea nuovo',
  disabled = false,
}: {
  path?: string;
  icon?: ReactNode;
  label?: string;
  disabled?: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => navigate(path)}
      color="primary"
      startIcon={icon}
      variant="contained"
      disableElevation
      size="small"
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

const EditIconButton = ({ path = 'edit' }: { path?: string }) => {
  const navigate = useNavigate();
  return (
    <Tooltip title="Modifica">
      <IconButton onClick={() => navigate(path)}>
        <EditIcon color="action" fontSize="medium" />
      </IconButton>
    </Tooltip>
  );
};

const EditButton = ({ path = 'edit' }: { path?: string }) => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => navigate(path)}
      color="primary"
      startIcon={<EditIcon />}
      variant="contained"
      disableElevation
      size="small"
    >
      Modifica
    </Button>
  );
};

const RefetchButton = ({ action, label = 'Ricarica i dati' }) => {
  return (
    <Button
      onClick={() => action()}
      color="primary"
      startIcon={<RefreshIcon />}
      variant="contained"
      disableElevation
      size="small"
    >
      {label}
    </Button>
  );
};

const Toggler = ({
  value,
  toggle,
}: {
  value: boolean;
  toggle: (value: boolean) => void;
}) => {
  const changeViewMode = () => {
    toggle(!value);
  };

  return (
    <Button onClick={changeViewMode} color="primary" size="small">
      {value ? 'Mostra selezionati' : 'Mostra tutti'}
    </Button>
  );
};

export const ScreenToolbarToolsComposite = ({ children }: IHasChildren) => (
  <Stack direction="row" spacing={2}>
    {children}
  </Stack>
);

ScreenToolbarToolsComposite.Button = GenericButton;
ScreenToolbarToolsComposite.Add = AddIconButton;
ScreenToolbarToolsComposite.AddButton = AddButton;
ScreenToolbarToolsComposite.Edit = EditIconButton;
ScreenToolbarToolsComposite.EditButton = EditButton;
ScreenToolbarToolsComposite.Grid = GridViewButton;
ScreenToolbarToolsComposite.GridIcon = GridViewIconButton;
ScreenToolbarToolsComposite.List = ListViewButton;
ScreenToolbarToolsComposite.Listicon = ListViewIconButton;
ScreenToolbarToolsComposite.ToggleViewButton = ToggleViewButton;
ScreenToolbarToolsComposite.Toggler = Toggler;
ScreenToolbarToolsComposite.SearchBox = SearchBox;
ScreenToolbarToolsComposite.SearchBoxManual = SearchBoxManual;
ScreenToolbarToolsComposite.SimpleSearchBox = SimpleSearchBox;
ScreenToolbarToolsComposite.Refetch = RefetchButton;

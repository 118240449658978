export interface IJob {
  id: number;
  type: string;
  createdBy: string;
  state: boolean;
  start: number;
  finish: number;
}

export default [
  {
    id: 0,
    type: 'A',
    createdBy: 'Mr. Red',
    state: 'Scheduled',
    start: 1649428305.47,
    finish: 1649428305.47,
  },
  {
    id: 1,
    type: 'B',
    createdBy: 'Mr. Orange',
    state: 'In progress',
    start: 1649428305.47,
    finish: 1649428305.47,
  },
  {
    id: 2,
    type: 'C',
    createdBy: 'Mr. Brown',
    state: 'Finished',
    start: 1649428305.47,
    finish: 1649428305.47,
  },
  {
    id: 3,
    type: 'D',
    createdBy: 'Mr. Green',
    state: 'Error',
    start: 1649428305.47,
    finish: 1649428305.47,
  },
  {
    id: 4,
    type: 'A',
    createdBy: 'Mr. Red',
    state: 'Aborted',
    start: 1649428305.47,
    finish: 1649428305.47,
  },
];

import { get } from 'lodash';
import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Input, Select, MenuItem } from 'requiro-ui';

import { ScreenWrapper } from '../../../../../components/layouts/AppLayout';
import MessageBox from '../../../../../components/MessageBox';
import TitleToolbar from '../../../../../components/TitleToolbar';
import SaveBar from '../../components/Savebar';
import {
  useSingleAppQuery,
  useSingleCustomerQuery,
  useClassesByCustomerCodeQuery,
  fetchFullClassesByCustomerCodeQuery,
} from '../../../../../lib/queries';
import { addConfiguration } from '../../../../../lib/mutations';
import { useCrumbs } from '../../../../../lib/useCrumbs';
import { useApplicationContext } from '../../../../../providers/ApplicationProvider';
import { DocumentalClassConfig } from '../../../../../../types';
import { useAppInitiator } from '../../../../../providers/AppInitiator';
import ConfirmationDialog from '../../../../../components/dialogs/ConfirmationDialog';

const KissflowClassConfigurationAdd = () => {
  const { boot } = useAppInitiator();
  const { enqueueSnackbar } = useSnackbar();
  const queryInfoApp = useSingleAppQuery();
  const { currentWorkspace } = useApplicationContext();
  const navigate = useNavigate();
  const params = useParams();
  const { customerCode } = params;

  const defaultConfiguration = {
    label: '',
    userName: JSON.parse(localStorage.currentUser).preferred_username,
    customerCode: customerCode,
    className: null,
    kfUrl: '',
    kfDateFormat: '',
    kfAccountId: '',
    kfProcessId: '',
    kfAccessSecret: '',
    kfKeyId: '',
    domainCode: '',
    enabled: false,
    applicationCode: currentWorkspace.appCode,
  };

  const queryInfo = useClassesByCustomerCodeQuery(customerCode);
  const queryInfoCustomer = useSingleCustomerQuery(customerCode);

  const [saveLoading, handleSaveLoading] = React.useState<boolean>(false);
  const [openDialog, handleOpenDialog] = React.useState<boolean>(false);
  const [classes, handleClasses] = React.useState([]);
  const [classesLoading, handleClassesLoading] = React.useState<boolean>(true);
  const [classesError, handleClassesError] = React.useState<boolean>(false);
  const [configuration, handleConfiguration] =
    React.useState<DocumentalClassConfig>(defaultConfiguration);

  const app = get(queryInfoApp, 'data.data.data', []);
  const customer = get(queryInfoCustomer, 'data.data.data', null);

  const mutationAdd = addConfiguration({
    onError: (data: any) => {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
      handleSaveLoading(false);
    },
    onSuccess: (error: any) => {
      handleSaveLoading(false);
      enqueueSnackbar(t('res.saveOK'), { variant: 'success' });
      navigate(-1);
    },
  });

  const onCancel = () => {
    navigate(-1);
  };

  const onOpenConfirmationDialog = () => {
    handleOpenDialog(true);
  };

  const onCloseConfirmationDialog = () => {
    handleOpenDialog(false);
  };

  const onSave = () => {
    if (configuration) {
      handleSaveLoading(true);
      const configurationToSave = {
        userName: JSON.parse(localStorage.currentUser).preferred_username,
        customerCode: configuration.customerCode,
        className: configuration.className,
        kfUrl: configuration.kfUrl,
        kfDateFormat: 'yyyy-MM-dd',
        kfAccountId: configuration.kfAccountId,
        kfProcessId: configuration.kfProcessId,
        kfAccessSecret: configuration.kfAccessSecret,
        kfKeyId: configuration.kfKeyId,
        // domainCode: configuration.domainCode,
        enabled: false,
        applicationCode: currentWorkspace.appCode,
        domainCode: currentWorkspace.realm,
      };

      mutationAdd.mutate(configurationToSave);
    }
  };

  const onChange = (key, value) => {
    const clonedConfiguration = JSON.parse(JSON.stringify(configuration));

    clonedConfiguration[key] = value;
    handleConfiguration(clonedConfiguration);
  };

  useCrumbs([
    { to: '/ws', label: 'Workspaces' },
    { to: `/ws/${currentWorkspace.id}`, label: `${currentWorkspace.name}` },
    { to: `/ws/${currentWorkspace.id}/apps`, label: `App` },
    {
      to: `/ws/${currentWorkspace.id}/apps/${app && app.id ? app.id : ''}`,
      label: app && app.name ? app.name : '',
    },
    {
      to: `/ws/${currentWorkspace.id}/apps/${
        app && app.id ? app.id : ''
      }/customer/${customerCode || ''}`,
      label: customer
        ? `${customer.CompanyName}`
        : customerCode
        ? customerCode
        : '',
    },
    {
      label: configuration
        ? t('addNew', 'Aggiungi nuova Classe Documentale')
        : '',
    },
  ]);

  const fetchData = async (second?: boolean) => {
    if (!second) handleClassesLoading(true);
    const fullClasses = await fetchFullClassesByCustomerCodeQuery(
      boot.appKissflowServicesBaseUrl,
      customerCode,
    );

    if (fullClasses) {
      const notConfiguredClasses = fullClasses.filter(
        (el) => !el.configuration,
      );
      handleClasses(notConfiguredClasses);
      if (notConfiguredClasses.length === 1) {
        onChange('className', notConfiguredClasses[0].classname);
      }
    } else {
      handleClassesError(true);
    }

    if (!second) handleClassesLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (queryInfo.isLoading || classesLoading) {
    return <MessageBox.Loading />;
  }
  if (queryInfo.isError || classesError) {
    return <MessageBox.QueryError query={queryInfo} />;
  }

  const titleForDialog = t('alert', 'Attenzione');
  const textForDialog = t('saveConfigText', 'Vuoi salvare la configurazione?');

  return (
    <ScreenWrapper>
      <TitleToolbar title={t('addNew', 'Aggiungi nuova Classe Documentale')} />
      <div
        style={{
          marginTop: '40px',
          pointerEvents: saveLoading ? 'none' : 'auto',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            margin: '0 -16px',
            opacity: saveLoading ? 0.5 : 1,
          }}
        >
          <div
            style={{ width: '50%', padding: '0 16px', marginBottom: '16px' }}
          >
            <Select
              label={t('classe', 'Classe documentale')}
              value={configuration.className}
              sx={{ width: '50%' }}
              onChange={(e) => {
                onChange('className', e.target.value);
              }}
            >
              {classes.map((c) => {
                return <MenuItem value={c.classname}>{c.label}</MenuItem>;
              })}
            </Select>
          </div>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0 -16px' }}>
          <div
            style={{
              width: '50%',
              padding: '0 16px',
              marginBottom: '16px',
              opacity: saveLoading ? 0.5 : 1,
            }}
          >
            <Input
              label={t('url', 'URL')}
              placeholder={t('insertText', 'Inserisci testo')}
              fullWidth
              value={configuration ? configuration.kfUrl : ''}
              onChange={(e) => {
                onChange('kfUrl', e.target.value);
              }}
              disabled={!configuration.className}
            />
          </div>
          <div
            style={{
              width: '50%',
              padding: '0 16px',
              marginBottom: '16px',
              opacity: saveLoading ? 0.5 : 1,
            }}
          >
            <Input
              label={t('accountId', 'Account ID')}
              placeholder={t('insertText', 'Inserisci testo')}
              fullWidth
              value={configuration ? configuration.kfAccountId : ''}
              onChange={(e) => {
                onChange('kfAccountId', e.target.value);
              }}
              disabled={!configuration.className}
            />
          </div>
          <div
            style={{
              width: '50%',
              padding: '0 16px',
              marginBottom: '16px',
              opacity: saveLoading ? 0.5 : 1,
            }}
          >
            <Input
              label={t('processId', 'Process ID')}
              placeholder={t('insertText', 'Inserisci testo')}
              fullWidth
              value={configuration ? configuration.kfProcessId : ''}
              onChange={(e) => {
                onChange('kfProcessId', e.target.value);
              }}
              disabled={!configuration.className}
            />
          </div>
          <div
            style={{
              width: '50%',
              padding: '0 16px',
              marginBottom: '16px',
              opacity: saveLoading ? 0.5 : 1,
            }}
          >
            <Input
              label={t('keyId', 'Key ID')}
              placeholder={t('insertText', 'Inserisci testo')}
              fullWidth
              value={configuration ? configuration.kfKeyId : ''}
              onChange={(e) => {
                onChange('kfKeyId', e.target.value);
              }}
              disabled={!configuration.className}
            />
          </div>
          <div
            style={{
              width: '50%',
              padding: '0 16px',
              marginBottom: '16px',
              opacity: saveLoading ? 0.5 : 1,
            }}
          >
            <Input
              label={t('accessSecret', 'Access Secret')}
              placeholder={t('insertText', 'Inserisci testo')}
              fullWidth
              value={configuration ? configuration.kfAccessSecret : ''}
              onChange={(e) => {
                onChange('kfAccessSecret', e.target.value);
              }}
              disabled={!configuration.className}
            />
          </div>

          <div style={{ width: '100%', padding: '0 16px' }}>
            <SaveBar
              onCancelLabel={t('cancel', 'Cancel')}
              onCancel={onCancel}
              onSaveLabel={t('save', 'Salva')}
              onSave={onOpenConfirmationDialog}
            />
          </div>

          <ConfirmationDialog
            handleConfirmation={onSave}
            handleClose={onCloseConfirmationDialog}
            text={textForDialog}
            open={openDialog}
            title={titleForDialog}
          ></ConfirmationDialog>
        </div>
      </div>
    </ScreenWrapper>
  );
};

export default KissflowClassConfigurationAdd;

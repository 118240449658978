import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { EDIT, NEW } from '../../../lib/config';
import GroupForm from './Form';
import GroupIndex from './GroupIndex';
import GroupItem from './Item';
import PermissionsGroup from './Permissions/PermissionsGroup';

const GroupsRouter = () => {
  return (
    <Routes>
      <Route path="" element={<GroupIndex />} />
      <Route path=":idGroup" element={<GroupItem />} />
      <Route path={`${NEW}`} element={<GroupForm />} />
      <Route path={`${EDIT}/:idGroup`} element={<GroupForm />} />
      <Route
        path={`:idGroup/permissions`}
        element={<PermissionsGroup edit={false} />}
      />
      <Route
        path={`${EDIT}/:idGroup/permissions`}
        element={<PermissionsGroup edit={true} />}
      />
    </Routes>
  );
};

export default GroupsRouter;

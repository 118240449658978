import React, { useState } from 'react';
import { Switch } from 'requiro-ui';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const ConfirmSwitch = ({ checked, onChange }) => {
  const [open, toggle] = useState(false);

  const handleCancel = () => toggle(false);

  const handleOk = () => {
    handleCancel();
    onChange();
  };

  return (
    <>
      <Switch checked={checked} onChange={() => toggle(true)} />
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={open}
      >
        <DialogTitle>Confermi l'azione?</DialogTitle>
        <DialogContent>
          {/* <QuestionAnswerOutlinedIcon /> */}
          Seleziona SI per confermare, NO per cancellare.
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleCancel}
            variant="contained"
            color="secondary"
          >
            NO
          </Button>
          <Button onClick={handleOk} variant="contained" color="secondary">
            SI
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmSwitch;

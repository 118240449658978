export const GRID_VIEW = 'gridView';
export const LIST_VIEW = 'listView';
export const CATALOG_PAGE_TITLE = 'Catalog';
export const GROUPS_PAGE_TITLE = 'Gruppi';

export const SHOW_LAST_WORKSPACES = 5;
export const SHOW_LAST_USERS = 5;
export const DEFAULT_LIMIT_REQUIRO_START_USERS = 5;
export const LOADING_STRING_REACT_QUERY = 'Loading';
export const ERROR_STRING_REACT_QUERY = 'Error';

export const RQX_START_VIEW_MODE = 'RQX_START_VIEW_MODE';
export const RQX_START_ROWS_PER_PAGE = 'RQX_START_ROWS_PER_PAGE';

export const NEW = 'new';
export const EDIT = 'edit';

export const wsActions = {
  info: 'INFO',
  edit: 'MODIFICA',
  app: 'APP',
  customers: 'CLIENTI',
  groups: 'GRUPPI UTENTE',
  users: 'UTENTI',
  api: 'API',
  new: 'CREA',
  add: 'AGGIUNGI',
  delete: 'DELETE',
  toggleState: 'toggleState',
  deleteUserFromGroup: 'deleteUserFromGroup',
  permissionEdit: 'permissionEdit',
  permissionShow: 'permissionShow',
};

export const CONFIRM_LABEL = 'Conferma';
export const EDIT_ICON = 'Modifica icona';
export const ADD_ICON = 'Aggiungi icona';
export const EDIT_LABEL_TOOLTIP = 'Modifica';

export const DELETED_STATE = 2;
export const ENABLED_STATE = 1;
export const DISABLED_STATE = 0;

export const DRAWER_WIDTH = 250;

export const tableRowsPerPageOptions = [1, 5, 10, 25, 50, 100];
export const DEFAULT_PAGINATION = tableRowsPerPageOptions[1];

export function i18nLoadPath() {
  return `/locales/{{lng}}/{{ns}}.json`;
}

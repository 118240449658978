import React from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow
} from 'requiro-ui';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { rowType } from './interface';
import Row from './Row';

export function CollapsibleTable(props: {
  rows: rowType[];
  rowsCopy: rowType[];
  edit: boolean;
  filterFn?: (item: any) => boolean;
}) {
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const { rows, rowsCopy, edit } = props;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
      <TableContainer
        component={Box}
        sx={{ backgroundColor: 'white', borderRadius: '12px', boxShadow: 1 }}
      >
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell component="th" scope="row" sx={{ width: '10%' }} />
              <TableCell>
                <Typography variant="h6" gutterBottom component="div">
                  App
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              '& .MuiTableRow-root:last-child td': {
                backgroundColor: 'rgba(0, 0, 0, 0) !important',
                border: 'none !important',
              },
            }}
          >
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
              <Row
                key={row.name}
                row={row}
                rows={rowsCopy}
                edit={edit}
                filterFn={props.filterFn}
              />
            ))}
            {/* {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

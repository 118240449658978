import axios from 'axios';
import { t } from 'i18next';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { SetStateAction, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, Avatar, Button, Input, Switch } from 'requiro-ui';
import { FormControlLabel, Grid, Typography } from '@mui/material';
import { Item, Name, Value } from '../../components/display/Typo';
import { ScreenWrapper } from '../../components/layouts/AppLayout';
import MessageBox from '../../components/MessageBox';
import ScreenToolbar from '../../components/ScreenToolbar';
import TitleToolbar from '../../components/TitleToolbar';
import { UserModel } from '../../generated/axios';
import { editUserArchiva } from '../../lib/mutations';
import { REQUIRO_START_USERS_EXT_QUERY_KEY, useRequiroStartUsers } from '../../lib/queries';
import { useCrumbs } from '../../lib/useCrumbs';
import { createAvatarChars, extractMutationErrorMessage } from '../../lib/utils';
import { useAppInitiator } from '../../providers/AppInitiator';

const AddUser = () => {
  const navigate = useNavigate();
  useCrumbs([
    { to: '/users', label: 'Gestione Utenti Archiva' },
    { label: 'aggiungi alla directory' },
  ]);

  const { enqueueSnackbar } = useSnackbar();
  const [currentUser, setCurrentUser] = useState<UserModel | null>(null);
  const [currentUsername, setCurrentUsername] = useState<string | null>('');
  const [active, setActive] = useState(false);

  const { boot } = useAppInitiator();
  const query = useRequiroStartUsers('1000', '0');

  const mutation = editUserArchiva({
    onError: (error) => {
      enqueueSnackbar(extractMutationErrorMessage(error), {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar(t('res.saveOK'), { variant: 'success' });
      navigate('/users');
    },
  });

  // user details
  const detailsQuery = useQuery(
    [REQUIRO_START_USERS_EXT_QUERY_KEY, currentUsername],
    () =>
      axios.get(
        `${boot.appServicesBaseUrl}/userExt/?realm=master&exact=true&briefRepresentation=false&username=${currentUsername}`,
      ),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      enabled: !!currentUsername,
      onSuccess: (data) => {
        setActive(get(data, 'data.data.users', [])[0]['enabled']);
      },
    },
  );

  // add user
  const submitData = () => {
    currentUser && mutation.mutate(currentUser);
  };

  const users = get(query, 'data.data.data.users', []);

  const details = get(detailsQuery, 'data.data.data.users', [])[0];

  return (
    <ScreenWrapper>
      <TitleToolbar title="Aggiungi un utente START alla directory" />
      {query.isLoading ? (
        <MessageBox.Loading />
      ) : query.isError ? (
        <MessageBox.QueryError query={query} />
      ) : (
        <>
          <Typography
            variant="body2"
            component="div"
            sx={{
              fontWeight: '500',
              fontFamily: 'WotfardMedium',
            }}
            color="secondary"
          >
            Selezione una persona, un gruppo o una risorsa dalla directory
            dell'organizzazione per visualizzare il calendario associato.
          </Typography>

          <Grid container spacing={2} sx={{ ml: -2 }}>
            <Grid item className="reset-padding" xs={12}>
              <Item>
                <Autocomplete
                  freeSolo
                  size="small"
                  disablePortal
                  options={users}
                  sx={{ width: '100%' }}
                  value={currentUser}
                  onChange={(
                    event: React.ChangeEvent,
                    newInputValue: SetStateAction<UserModel>,
                  ) => {
                    setCurrentUser(newInputValue);
                    newInputValue &&
                      setCurrentUsername(newInputValue['username']);
                  }}
                  getOptionLabel={(option: UserModel) =>
                    `${option.firstName || option.email || ''} ${
                      option.lastName || ''
                    }`
                  }
                  renderInput={(params) => (
                    <Input
                      {...params}
                      label="Cerca per nome"
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                    />
                  )}
                />
              </Item>
            </Grid>
            {currentUser && details ? (
              <>
                <Grid item className="reset-padding" xs={12}>
                  <Item>
                    <Avatar
                      alt={createAvatarChars(details)}
                      src=""
                      variant="round"
                    />
                  </Item>
                </Grid>
                <Grid item className="reset-padding" xs={6}>
                  <Item>
                    <Name>Nome</Name>
                    <Value>{details.firstName || '-'}</Value>
                  </Item>
                </Grid>
                <Grid item className="reset-padding" xs={6}>
                  <Item>
                    <Name>Cognome</Name>
                    <Value>{details.lastName || '-'}</Value>
                  </Item>
                </Grid>
                <Grid item className="reset-padding" xs={6}>
                  <Item>
                    <Name>Email</Name>
                    <Value>{details.email || '-'}</Value>
                  </Item>
                </Grid>
                <Grid item className="reset-padding" xs={3}>
                  <Item>
                    <Name>Ruolo</Name>
                    <Value>{'-'}</Value>
                  </Item>
                </Grid>
                <Grid item className="reset-padding" xs={3}>
                  <Item>
                    <Name>Attivo</Name>
                    <Value>
                      <FormControlLabel
                        sx={{ ml: '8px', mr: '8px' }}
                        control={
                          <Switch
                            size="small"
                            checked={active}
                            color="secondary"
                            onChange={(event) =>
                              setActive(event.target.checked)
                            }
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label={
                          <span style={{ marginLeft: '16px' }}>
                            {active ? 'SI' : 'NO'}
                          </span>
                        }
                      />
                    </Value>
                  </Item>
                </Grid>
                <Grid item className="reset-padding" xs={12}>
                  <Item style={{ textAlign: 'right' }}>
                    <Button onClick={submitData} size="small">
                      Conferma
                    </Button>
                  </Item>
                </Grid>
              </>
            ) : null}
          </Grid>
        </>
      )}
    </ScreenWrapper>
  );
};

export default AddUser;

import React, { useEffect, useRef, useState } from 'react';
import { Button, Input } from 'requiro-ui';
import EditIcon from '@mui/icons-material/Edit';
import { Typography, Modal, Box, Backdrop, IconButton } from '@mui/material';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { BoxModalWrapper } from './Styled';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { useAppInitiator } from '../../../../providers/AppInitiator';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { WS_APPS_QUERY_KEY } from '../../../../lib/queries';

export default function ModalChangeURL({ data }) {
  const client = useQueryClient();
  const { boot } = useAppInitiator();
  const params = useParams();
  const { id } = params;
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [inputDefault, setInputDefault] = useState(null);
  if (data.appParams) {
    if (!inputDefault && inputDefault !== JSON.parse(data.appParams).url) {
      setInputDefault(JSON.parse(data.appParams).url);
    }
  }
  console.log('Here reload');

  const pathRef = useRef<HTMLInputElement | null>(null);

  async function handleClick(dataApp) {
    if (dataApp && dataApp.id) {
      const idApp = dataApp.id;
      try {
        const result = await axios.put(
          `${boot.appServicesBaseUrl}/workspace/${id}/app/${idApp}`,
          dataApp,
        );
        if (result) {
          enqueueSnackbar('Path modificato con successo', {
            variant: 'success',
          });
          setInputDefault(JSON.parse(dataApp.appParams).url);
          console.log('Here put : ' + JSON.parse(dataApp.appParams).url);
          client.refetchQueries([WS_APPS_QUERY_KEY, 'useWorkspaceAppsQuery']);
        }
      } catch (err) {
        enqueueSnackbar('Errore durante la modifica del path', {
          variant: 'error',
        });
        console.error(err);
      }
    }
  }
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <InsertLinkIcon color="secondary" fontSize="medium" />
      </IconButton>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          marginLeft: '0px !important',
        }}
        open={open}
        onClick={handleClose}
        id="prova"
      >
        <Modal
          hideBackdrop
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <BoxModalWrapper onClick={(e) => e.stopPropagation()}>
            <Box
              sx={{
                p: 2,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: '#1A416B',
                color: 'white',
              }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {data.name}
              </Typography>
              <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
            </Box>
            <Box
              sx={{
                p: 4,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <Box style={{ width: '100%' }}>
                <Input
                  id="outlined-multiline-static"
                  label="URL"
                  multiline
                  rows={4}
                  style={{ width: '100%' }}
                  defaultValue={inputDefault}
                  inputRef={pathRef}
                />
              </Box>
              <Button
                onClick={() => {
                  handleClick({
                    ...data,
                    appParams: `{ \"url\": \"${pathRef.current.value}\" }`,
                  });
                  handleClose();
                }}
                color="primary"
                startIcon={<EditIcon />}
                variant="contained"
                size="small"
                disableElevation
                style={{ marginTop: '10px' }}
              >
                Cambia URL
              </Button>
            </Box>
          </BoxModalWrapper>
        </Modal>
      </Backdrop>
    </>
  );
}

import { get } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { Button } from 'requiro-ui';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Customer } from '../../../types';
import { fetchCustomers } from '../../api/customerApi';
import { useAppInitiator } from '../../providers/AppInitiator';
import { useApplicationContext } from '../../providers/ApplicationProvider';
import { searchEntityBySpecifiedKey } from '../../utilities/UtilitiesFunction';
import { SearchBar } from '../SearchBar';
import CustomersTable from '../tables/CustomersTable';
import MessageDialog from './MessageDialog';

export default function AddCustomersDialog(props: any) {
  const {
    currentWorkspace,
    customersOfSelectedWorkspace,
    selectedCustomersForAddingInWorkspace,
    setCustomersOfSelectedWorkspace,
    setSelectedCustomersForAddingInWorkspace,
  } = useApplicationContext();
  const { boot } = useAppInitiator();
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState<Customer[]>([]);
  const [openMessageDialog, setOpenMessageDialog] = React.useState(false);
  const [textMessageDialog, setTextMessageDialog] = React.useState('');
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('lg');

  //It is used to avoid memory leaks
  const _isMounted = useRef(true); // Initial value _isMounted = true

  useEffect(() => {
    props.customers ? null : getCustomers();
    return () => {
      // ComponentWillUnmount in Class Component
      _isMounted.current = false;
    };
  }, []);

  async function getCustomers(queryString: string = '') {
    if (props.preFiltering) {
      queryString =
        'companyGroupCode=' +
        currentWorkspace.companyGroupCode +
        (queryString ? '&' + queryString : '');
    }
    fetchCustomers(boot.appCoreServicesBaseUrl, queryString)
      .then((response: any) => {
        const data = get(response, 'data.data.data');
        if (data) {
          if (_isMounted.current) {
            if (props.onlyWorkspaceCustomers) {
              // console.log(data, props.customers);
              const codes: string[] = props.customers.map(
                (c) => c.customerCode,
              );
              const filterdData = data.filter((item) =>
                codes.includes(item.CustomerCode),
              );
              setRows(filterdData);
            } else {
              setRows(data);
            }
            //setSelectedCustomersForAddingInWorkspace([]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setRows([]);
      });
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseMessageDialog = () => {
    setOpenMessageDialog(false);
  };

  /**
   * Check if the chosen customers are part of the same group, i.e. if they have the same CompanyGroupCode,
   *  which is then equivalent to the CustomerCode of the main company.
   */
  const customersSelectedForAddingAreOfSameGroup = () => {
    const companyGroupCodesSelected = new Set();
    //const companyBillingCodesSelected = new Set();

    if (customersOfSelectedWorkspace) {
      customersOfSelectedWorkspace.forEach((customer: any) => {
        customer.CompanyGroupCode
          ? companyGroupCodesSelected.add(customer.CompanyGroupCode)
          : companyGroupCodesSelected.add(
              currentWorkspace.principalCustomerCode,
            );
        //companyBillingCodesSelected.add(customer.BillingCode);
      });
    }
    if (selectedCustomersForAddingInWorkspace) {
      for (let i = 0; i < selectedCustomersForAddingInWorkspace.length; i++) {
        companyGroupCodesSelected.add(
          selectedCustomersForAddingInWorkspace[i].CompanyGroupCode,
        );
        /*companyBillingCodesSelected.add(
          context.selectedCustomersForAddingInWorkspace[i].BillingCode
        );
        */
        if (
          searchEntityBySpecifiedKey(
            'CustomerCode',
            customersOfSelectedWorkspace,
            selectedCustomersForAddingInWorkspace[i],
          ) > -1
        ) {
          setTextMessageDialog('Il customer è già stato aggiunto!');
          return false;
        }
      }
    }
    if (companyGroupCodesSelected.size > 1) {
      setTextMessageDialog('Hai selezionato customer di gruppi diversi!');
    }
    return companyGroupCodesSelected.size <= 1;
  };

  const addSelectedCustomersInWorkspace = () => {
    // ! controllo per il bug: 120
    if (!props.singleSelection) {
      if (customersSelectedForAddingAreOfSameGroup()) {
        let newCustomersOfWorkspaceList = [
          ...customersOfSelectedWorkspace,
          ...selectedCustomersForAddingInWorkspace,
        ];
        setCustomersOfSelectedWorkspace(newCustomersOfWorkspaceList);
        //context.setSelectedCustomersForAddingInWorkspace([]);
        props.actionForSelectedCustomers();
        setOpen(false);
      } else {
        setOpenMessageDialog(true);
      }
    } else {
      // console.log({ selectedCustomersForAddingInWorkspace });

      setCustomersOfSelectedWorkspace(selectedCustomersForAddingInWorkspace);
      props.actionForSelectedCustomers();
      //context.setSelectedCustomersForAddingInWorkspace([]);
      setOpen(false);
    }
  };

  const searchCustomers = (queryString: string) => {
    // before bugfix
    setSelectedCustomersForAddingInWorkspace([]);
    getCustomers(queryString);
    // after bugfix
  };

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen} variant="contained">
        {props.customButtonLabel ? props.customButtonLabel : 'Aggiungi clienti'}
      </Button>
      <MessageDialog
        handleClose={handleCloseMessageDialog}
        text={textMessageDialog}
        open={openMessageDialog}
        title={'Attenzione'}
      ></MessageDialog>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle style={{ textAlign: 'center' }}>
          {props.customTitle
            ? props.customTitle
            : 'Scelta clienti da aggiungere al workspace'}
        </DialogTitle>
        <DialogContent>
          <div style={{ padding: '5px' }}>
            <SearchBar searchFunction={searchCustomers}></SearchBar>
          </div>
          <CustomersTable
            rows={props.customers ? props.customers : rows}
            visualizeMassiveDelete={false}
            deleteFunction={() => null}
            singleSelection={props.singleSelection}
            visualizePrincipalCustomer={false}
            deletableRow={false}
          ></CustomersTable>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Annulla
          </Button>
          <Button onClick={addSelectedCustomersInWorkspace}>Aggiungi</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { ScreenWrapper } from '../../components/layouts/AppLayout';
import ScreenToolbar from '../../components/ScreenToolbar';
import { ScreenToolbarToolsComposite } from '../../components/ScreenToolbarTools';
import TitleToolbar from '../../components/TitleToolbar';
import { useCrumbs } from '../../lib/useCrumbs';
import UserManagement from '../../pages/UserManagement';
import Filters from './components/FiltersAlt';

const All = () => {
  const [searchKey, setSearchKey] = useState('');
  const navigate = useNavigate();
  useCrumbs([{ label: 'Gestione Utenti START' }]);

  // const execSearch = (data) => {
  //   const searchKey = data.trim();

  //   if (searchKey === '')
  //     return client.invalidateQueries([REQUIRO_START_USERS_QUERY_KEY]);

  //   axios
  //     .get(`${boot.appServicesBaseUrl}/user?realm=master&search=${searchKey}`)
  //     .then((result) => {
  //       setUsers(get(result, 'data.data.users', []));
  //       setTotalCount(get(query, 'data.data.totalCount', 0));
  //     })
  //     .catch((error) => {
  //       enqueueSnackbar(extractMutationErrorMessage(error), {
  //         variant: 'error',
  //       });
  //     });
  // };

  const handleSearchByKey = (key: string = '') => {
    setSearchKey(key);
  };

  // const actionHandler = ({ data, action }: { data: any; action: string }) => {
  //   action === 'search' && setSearchData(data);
  // };

  return (
    <ScreenWrapper>
      <TitleToolbar title="Utenti START">
        <ScreenToolbarToolsComposite>
          <ScreenToolbarToolsComposite.AddButton />
          <ScreenToolbarToolsComposite.AddButton
            icon={<GroupAddOutlinedIcon />}
            label="Aggiungi utente alla directory"
            path="add"
          />
          <ScreenToolbarToolsComposite.SimpleSearchBox
            handleSearch={handleSearchByKey}
          />
          {/* <Filters actionHandler={actionHandler} /> */}
          {/* <Tooltip title="Aggiungi utente alla directory">
            <IconButton onClick={() => navigate('add')}>
              <GroupAddOutlinedIcon />
            </IconButton>
          </Tooltip> */}
        </ScreenToolbarToolsComposite>
        {/* <ScreenToolbarTools
          extra={
            <Tooltip title="Aggiungi utente alla directory">
              <IconButton onClick={() => navigate('add')}>
                <GroupAddOutlinedIcon />
              </IconButton>
            </Tooltip>
          }
        /> */}
      </TitleToolbar>
      <UserManagement searchKey={searchKey} />
    </ScreenWrapper>
  );
};

export default All;

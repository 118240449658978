import Brightness1Icon from "@mui/icons-material/Brightness1";
import { green, grey } from "@mui/material/colors";
import { styled } from "@mui/system";

export const DisabledIcon = styled('div')(({ theme }) => ({
  backgroundColor: grey[500],
  width: '24px',
  height: '24px',
  borderRadius: '50%',
}));

export const EnabledIcon = styled('div')(({ theme }) => ({
  backgroundColor: green[500],
  width: '24px',
  height: '24px',
  borderRadius: '50%',
}));

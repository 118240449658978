import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { ITaskLog } from '../../../../types';
import MessageBox from '../../../components/MessageBox';
import { dateTimeFormatter } from '../../../lib/utils';
import { useWorkspaceTaskLogs } from '../helpers/queries';

const LogDisplay = ({ data, date }: { data: ITaskLog; date: string }) => (
  <Box sx={{ p: 1, backgroundColor: 'rgba(0,0,0,0.05)', borderRadius: '8px' }}>
    <Typography
      variant="caption"
      component="div"
      sx={{ fontFamily: 'WotfardLight' }}
    >{`ID: ${data.id} [${date}]`}</Typography>
    <Typography variant="caption">{data.log}</Typography>
  </Box>
);

const LogsDisplay = ({ workspaceId, selectedTaskId }) => {
  const logsQuery = useWorkspaceTaskLogs(workspaceId, selectedTaskId, 'log');

  if (logsQuery.isLoading) return <MessageBox.Loading />;

  if (logsQuery.isError) return <MessageBox.QueryError query={logsQuery} />;

  return (
    <div>
      <Box
        sx={{
          backgroundColor: '#fff',
          overflowY: 'auto',
        }}
      >
        <Stack direction="column" spacing={2} sx={{ maxHeight: 360 }}>
          {logsQuery.data
            ? logsQuery.data.map((item) => {
                console.log('item :' + JSON.stringify(item));
                const date = new Date(item.timestamp);
                const [month, day, year] = [
                  date.getMonth(),
                  date.getDate(),
                  date.getFullYear(),
                ];
                const [hour, minutes, seconds] = [
                  date.getHours(),
                  date.getMinutes(),
                  date.getSeconds(),
                ];
                console.log('ore:' + hour, minutes, seconds);
                return (
                  <LogDisplay
                    key={item.id}
                    data={item}
                    date={`${day}/${month}/${year} ${hour}:${minutes}:${seconds}`}
                  />
                );
              })
            : null}
        </Stack>
      </Box>
    </div>
  );
};

export default LogsDisplay;

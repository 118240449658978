import { Customer, Order } from '../../types';

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

//TODO: Da eliminare, posso usare opportunamente il metodo di sotto che ho scritto parametrico rispetto a qualsiasi oggetto
export const isCustomerPresentInArray = (
  customer: Customer,
  array: Customer[],
) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i].CustomerCode === customer.CustomerCode) {
      return i;
    }
  }
  return -1;
};

export function searchEntityBySpecifiedKey<T>(
  key: keyof T,
  array: T[],
  searchedElement: T,
) {
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] === searchedElement[key]) {
      return i;
    }
  }
  return -1;
}

export function searchEntityByEmailJSON(array: any, searchedElement: any) {
  for (let i = 0; i < array.length; i++) {
    if (array[i].email === searchedElement) {
      return i;
    }
  }
  return -1;
}

export const isCustomerPrincipal = (customer: any, currentWorkspace) => {
  return (
    customer.BillingCode &&
    customer.CustomerCode &&
    customer.BillingCode === currentWorkspace.customerCodeBilling &&
    customer.CustomerCode === currentWorkspace.principalCustomerCode
  );
};

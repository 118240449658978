import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tag,
} from 'requiro-ui';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ClickAwayListener, IconButton, styled } from '@mui/material';
import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import { Order, Workspace, WorkspacesTableProps } from '../../../types';
import { tableRowsPerPageOptions } from '../../lib/config';
import { usePagination } from '../../lib/usePagination';
import { useApplicationContext } from '../../providers/ApplicationProvider';
import ConfirmSwitch from '../buttons/ConfirmSwitch';
import RowMenu from './RowMenu';

interface HeadCell {
  disablePadding: boolean;
  id: keyof Workspace;
  label: string;
  numeric: boolean;
  sortable?: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Nome',
    sortable: true,
  },
  {
    id: 'realm',
    numeric: false,
    disablePadding: true,
    label: 'Realm',
    sortable: true,
  },
  {
    id: 'principalCustomerCode',
    numeric: false,
    disablePadding: true,
    label: 'Customer Code Principale',
    sortable: true,
  },
  {
    id: 'principalCompanyName',
    numeric: false,
    disablePadding: true,
    label: 'Ragione Sociale',
    sortable: false,
  },
  {
    id: 'state',
    numeric: false,
    disablePadding: true,
    label: 'Stato',
    sortable: true,
  },
  {
    id: 'usersCount',
    numeric: false,
    disablePadding: true,
    label: 'N. utenti',
    sortable: true,
  },
  {
    id: 'modifiedOn',
    numeric: false,
    disablePadding: true,
    label: 'Ultima modifica',
    sortable: true,
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Workspace,
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Workspace) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow color="azure">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            //align={headCell.numeric ? 'right' : 'left'}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontWeight: '700' }}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <span>{headCell.label}</span>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// interface EnhancedTableToolbarProps {
//   numSelected: number;
// }

// const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
//   const { numSelected } = props;

//   return (
//     <Toolbar
//       sx={{
//         pl: { sm: 2 },
//         pr: { xs: 1, sm: 1 },
//       }}
//     >
//       <Typography
//         sx={{ flex: '1 1 100%' }}
//         variant="h6"
//         id="tableTitle"
//         component="div"
//       >
//         Lista Workspace
//       </Typography>
//       <Tooltip title="Crea Workspace">
//         <Link
//           to="/workspace"
//           style={{ color: 'inherit', textDecoration: 'none' }}
//         >
//           <IconButton>
//             <AddCircleIcon color="secondary" fontSize="large" />
//           </IconButton>
//         </Link>
//       </Tooltip>
//     </Toolbar>
//   );
// };

const StartTableCellSelectable = styled(TableCell)`
  z-index: 100 !important;
  position: relative !important;
  border-top: 1px solid #e8ebf0 !important;
  border-left: 1px solid #e8ebf0 !important;
  padding-left: 16px !important;
  border-bottom: 1px solid #e8ebf0 !important;
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
`;

const IntermediateTableCellSelectable = styled(TableCell)`
  z-index: 90 !important;
  position: relative !important;
  border-top: 1px solid #e8ebf0 !important;
  border-bottom: 1px solid #e8ebf0 !important;
`;

const EndTableCellSelectable = styled(TableCell)`
  z-index: 90 !important;
  position: relative !important;
  border-top: 1px solid #e8ebf0 !important;
  border-bottom: 1px solid #e8ebf0 !important;
  display: flex !important;
`;

export default function WorkspacesTable(props: WorkspacesTableProps) {
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Workspace>('modifiedOn');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { changeWorkspace } = useApplicationContext();
  const { rowsPerPage, onRowsPerPageChange } = usePagination();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [rowMenuData, setRowMenuData] = useState(null);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Workspace,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    props.handleRequestSort(event, property, isAsc ? 'DESC' : 'ASC');
  };

  const handleClick = (id: string) => {
    const ws = props.rows.find((item) => item.id === id);
    ws && changeWorkspace(ws);
    setSelected([id]);
  };

  // const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setDense(event.target.checked);
  // };

  const clickAwayHandler = () => {
    setSelected([]);
    changeWorkspace(null);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

  return (
    <ClickAwayListener onClickAway={clickAwayHandler}>
      <Box sx={{ width: '100%' }}>
        {/* <Paper sx={{ width: '100%', mb: 2 }}> */}
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table
            sx={{ width: '100%', margin: '16px 0' }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={() => null}
              onRequestSort={handleRequestSort}
              rowCount={props.rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
              {props && props.rows
                ? props.rows.map((row, index) => {
                    //stableSort(props.rows, getComparator(order, orderBy)).map(
                    //(row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(e: any) => handleClick(row.id)}
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        sx={{
                          cursor: 'pointer',
                          backgroundColor: isItemSelected
                            ? 'rgba(25, 118, 210, 0.08) !important'
                            : 'white',
                          borderRadius: 1,
                        }}
                      >
                        <StartTableCellSelectable align="left" component="th">
                          {row.name}
                        </StartTableCellSelectable>
                        <IntermediateTableCellSelectable
                          align="left"
                          component="th"
                        >
                          {row.realm}
                        </IntermediateTableCellSelectable>
                        <IntermediateTableCellSelectable
                          align="left"
                          component="th"
                        >
                          {row.principalCustomerCode}
                        </IntermediateTableCellSelectable>
                        <IntermediateTableCellSelectable
                          align="left"
                          component="th"
                        >
                          {row.principalCompanyName}
                        </IntermediateTableCellSelectable>
                        <IntermediateTableCellSelectable
                          align="left"
                          component="th"
                        >
                          {/* {row.state === 1 ? <EnabledIcon /> : <DisabledIcon />} */}
                          {row.state === 1 ? (
                            <Tag color="success" label="Attivo" />
                          ) : (
                            <Tag color="error" label="Non attivo" />
                          )}
                        </IntermediateTableCellSelectable>
                        <IntermediateTableCellSelectable
                          align="left"
                          component="th"
                        >
                          {row.usersCount}
                        </IntermediateTableCellSelectable>
                        {/* <IntermediateTableCellSelectable align="right">{row.creationDate}</IntermediateTableCellSelectable> */}
                        <IntermediateTableCellSelectable
                          align="left"
                          component="th"
                        >
                          {row.modifiedOn
                            ? new Date(
                                Number(row.modifiedOn) * 1000,
                              ).toLocaleString()
                            : ''}
                        </IntermediateTableCellSelectable>
                        <EndTableCellSelectable>
                          <ConfirmSwitch
                            checked={row.state === 1 ? true : false}
                            onChange={(_e: any) => props.onEnableDisable(row)}
                          />
                          {/* <IconButton
                            color="secondary"
                            onClick={(e) => {
                              setRowMenuData(row);
                              setAnchorEl(e.currentTarget);
                              setOpen(true);
                            }}
                            sx={{ ml: 2 }}
                          >
                            <MoreVertIcon />
                          </IconButton> */}
                        </EndTableCellSelectable>
                      </TableRow>
                    );
                  })
                : null}
              {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={tableRowsPerPageOptions}
          component="div"
          /* count={rows.length} */
          count={props.totalCount ? props.totalCount : props.rows.length}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onPageChange={props.handleChangePage}
          onRowsPerPageChange={props.handleChangeRowsPerPage}
        />
        {/* </Paper> */}
        <RowMenu
          open={open}
          anchorEl={anchorEl}
          data={rowMenuData}
          closeHandler={() => setOpen(false)}
        />
      </Box>
    </ClickAwayListener>
  );
}

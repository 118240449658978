import { get } from 'lodash';
import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';

import { ScreenWrapper } from '../../../../../components/layouts/AppLayout';
import MessageBox from '../../../../../components/MessageBox';
import TitleToolbar from '../../../../../components/TitleToolbar';
import {
  useSingleAppQuery,
  useClassesByCustomerCodeQuery,
  useSingleCustomerQuery,
  useConfigurationByClassname,
  fetchAuditByConfigurationId,
} from '../../../../../lib/queries';
import { useCrumbs } from '../../../../../lib/useCrumbs';
import { useApplicationContext } from '../../../../../providers/ApplicationProvider';
import { usePagination } from '../../../../../lib/usePagination';
import { tableRowsPerPageOptions } from '../../../../../lib/config';
import { useAppInitiator } from '../../../../../providers/AppInitiator';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from 'requiro-ui';

import { Order, ConfigurationAudit } from '../../../../../../types';
import {
  getComparator,
  stableSort,
} from '../../../../../utilities/UtilitiesFunction';
import KissflowClassSingleAudit from './KissflowClassSingleAudit';

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof ConfigurationAudit,
  ) => void;
  order: Order;
  orderBy: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof ConfigurationAudit;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'userId',
    numeric: false,
    disablePadding: true,
    label: 'Autore',
  },
  {
    id: 'lastModifyDate',
    numeric: false,
    disablePadding: true,
    label: 'Data ultima modifica',
  },
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Versione',
  },
];

const KissflowClassAudit = () => {
  const { boot } = useAppInitiator();
  const queryInfoApp = useSingleAppQuery();
  const { currentWorkspace } = useApplicationContext();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { customerCode, classId } = params;
  const queryInfoCustomer = useSingleCustomerQuery(customerCode);

  const queryInfo = useClassesByCustomerCodeQuery(customerCode);
  const queryInfoConfiguration = useConfigurationByClassname(
    customerCode,
    classId,
  );

  const { rowsPerPage, onRowsPerPageChange } = usePagination();

  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] =
    React.useState<keyof ConfigurationAudit>('lastModifyDate');
  const [page, setPage] = React.useState(0);
  const [audit, handleAudit] = React.useState(null);
  const [set, handleSet] = React.useState<boolean>(false);
  const [configuration, handleConfiguration] = React.useState(null);

  const app = get(queryInfoApp, 'data.data.data', []);
  const customer = get(queryInfoCustomer, 'data.data.data', null);
  const configurationBase = get(queryInfoConfiguration, 'data.data.data', null);
  const classes = get(queryInfo, 'data.data.data', []).filter(
    (el) =>
      (el.classname === classId && el.customercode === customerCode) ||
      (el.classname === classId && el.customercode === '000000'),
  );

  useCrumbs([
    { to: '/ws', label: 'Workspaces' },
    { to: `/ws/${currentWorkspace.id}`, label: `${currentWorkspace.name}` },
    { to: `/ws/${currentWorkspace.id}/apps`, label: `App` },
    {
      to: `/ws/${currentWorkspace.id}/apps/${app && app.id ? app.id : ''}`,
      label: app && app.name ? app.name : '',
    },
    {
      to: `/ws/${currentWorkspace.id}/apps/${
        app && app.id ? app.id : ''
      }/customer/${customerCode || ''}`,
      label: customer
        ? `${customer.CompanyName}`
        : customerCode
        ? customerCode
        : '',
    },
    {
      label: t('history', 'Storico'),
    },
  ]);

  const onSingleAuditClick = (row: ConfigurationAudit) => {
    navigate(`${location.pathname}/detail/${String(row.id)}`);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onRowsPerPageChange(event);
    setPage(0);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ConfigurationAudit,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const fetchData = async (id: string) => {
    const auditResult = await fetchAuditByConfigurationId(
      boot.appKissflowServicesBaseUrl,
      id,
    );

    if (auditResult && auditResult.data) {
      handleAudit(auditResult.data.data);
    }
  };

  useEffect(() => {
    if (
      !set &&
      configurationBase &&
      configurationBase.length > 0 &&
      classes.length > 0
    ) {
      const composedConfiguration =
        classes.length > 0 && configurationBase
          ? { ...configurationBase[0], label: classes[0].label }
          : null;
      handleConfiguration(composedConfiguration);
      handleSet(true);

      if (composedConfiguration) {
        fetchData(String(composedConfiguration.id));
      }
    }
  }, [configurationBase, classes]);

  if (queryInfo.isLoading) {
    return <MessageBox.Loading />;
  }
  if (queryInfo.isError) {
    return <MessageBox.QueryError query={queryInfo} />;
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof ConfigurationAudit) =>
      (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {/*<TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>*/}
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <strong>{headCell.label}</strong>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell key={'link'} align={'right'} padding={'normal'}>
            {' '}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const rows = audit || [];

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <ScreenWrapper>
      <TitleToolbar
        title={t('history', 'Storico')}
        subtitle={configuration ? configuration.label : null}
      />

      <TableContainer>
        <Table aria-labelledby="tableTitle" size="medium">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />

          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <KissflowClassSingleAudit
                    key={row.id}
                    row={row}
                    onClick={onSingleAuditClick}
                  />
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={tableRowsPerPageOptions}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </ScreenWrapper>
  );
};

export default KissflowClassAudit;

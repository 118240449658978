import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import LockResetIcon from '@mui/icons-material/LockReset';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { WorkspaceUsersMenuProps } from '../../../types';
import GroupIndex from '../../features/ws/groups/GroupIndex';
import { useWSUsersContext } from '../../features/ws/users/WSUsers';
import { LinkWrapper } from './Styled';
import { Button, Typography, Input } from 'requiro-ui';

export default function WorkspaceUsersMenu(props: WorkspaceUsersMenuProps) {
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('lg');
  const [usedForDialogAddUserOnAGroup, setUsedForDialogAddUserOnAGroup] =
    React.useState(true);
  const [usedForDeleteUserFromGroup, setUsedForDeleteUserFromGroup] =
    React.useState(false);
  const [usedForDialogImpersonate, setUsedForDialogImpersonate] =
    React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [otpError, setOtpError] = React.useState<boolean>(false);
  const refs: Array<HTMLInputElement | null> = [];

  const { actionHandler } = useWSUsersContext();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteUser = () => {
    handleClose();
    props.onDelete(props.workspace.id, props.workspaceUser.id);
  };

  const enableDisable = () => {
    handleClose();
    props.onActivateDeactivate(
      props.workspace.id,
      props.workspaceUser.id,
      props.workspaceUser,
    );
  };

  const resetPassword = () => {
    handleClose();
    props.resetPassword(props.workspace.id, props.workspaceUser.id);
  };

  const duplica = () => {
    handleClose();
    //props.onDuplicate(newWorkspace);
  };

  const impersonate = async () => {
    if (refs.length === 6) {
      const otp = refs
        ? refs
            .map((input) => (input ? input.value : null))
            .filter((el) => el)
            .join('')
        : '';

      const res: any = await actionHandler({
        action: 'impersonate',
        data: {
          id: props.workspace.id,
          idKeycloak: props.workspaceUser.id,
          otp: otp,
        },
      });

      if (!res || (res && res.status !== 200)) {
        setOtpError(true);
      } else {
        setOpenDialog(false);
      }
    }
  };

  const openAddGroupDialog = () => {
    handleClose(); //Close contextual menù
    setUsedForDialogImpersonate(false);
    setUsedForDeleteUserFromGroup(false);
    setUsedForDialogAddUserOnAGroup(true);
    setOpenDialog(true); //Open dialog
  };

  const openRemoveFromGroupsDialog = () => {
    handleClose(); //Close contextual menù
    setUsedForDialogAddUserOnAGroup(false);
    setUsedForDialogImpersonate(false);
    setUsedForDeleteUserFromGroup(true);
    setOpenDialog(true); //Open dialog
  };

  const openImpersonateDialog = () => {
    handleClose(); //Close contextual menù
    setUsedForDialogAddUserOnAGroup(false);
    setUsedForDeleteUserFromGroup(false);
    setUsedForDialogImpersonate(true);
    setOpenDialog(true); //Open dialog
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const handlePaste = (ev) => {
    const inputs = document.querySelectorAll('.otp-wrapper');
    const pasted = ev.clipboardData.getData('text');
    if (pasted.length > 1) {
      const pastedArray = pasted.split('');
      Array.from(inputs).map((el, index) => {
        const input = el.querySelector('input');
        if (pastedArray[index]) {
          input.value = pastedArray[index];
          input.focus();
        }
      });
    }
  };

  return (
    <div>
      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={openAddGroupDialog}>
          <ListItemIcon>
            <GroupAddIcon fontSize="small" />
          </ListItemIcon>
          Aggiungi a gruppi
        </MenuItem>
        <MenuItem onClick={openRemoveFromGroupsDialog}>
          <ListItemIcon>
            <GroupAddIcon fontSize="small" />
          </ListItemIcon>
          Rimuovi da gruppi
        </MenuItem>
        <MenuItem>
          <LinkWrapper
            to={
              (props.workspaceUser ? props.workspaceUser.id : '') +
              '/permissions'
            }
            state={{
              workspaceUser: props.workspaceUser ? props.workspaceUser : null,
            }}
          >
            <ListItemIcon>
              <InfoIcon fontSize="small" color="secondary" />
            </ListItemIcon>
            Visualizza permessi
          </LinkWrapper>
        </MenuItem>
        <MenuItem>
          <LinkWrapper
            to={'edit/' + (props.workspaceUser ? props.workspaceUser.id : '')}
            state={{
              workspaceUser: props.workspaceUser ? props.workspaceUser : null,
            }}
          >
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            Modifica
          </LinkWrapper>
        </MenuItem>
        {/*
        <MenuItem onClick={deleteUser}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          Cancella
        </MenuItem> */}
        {/* <MenuItem onClick={enableDisable}>
          <ListItemIcon>
            <ArchiveIcon fontSize="small" />
          </ListItemIcon>
          {props.workspaceUser && props.workspaceUser.state
            ? props.workspaceUser.state === 0
              ? 'Abilita'
              : 'Disabilita'
            : 'Abilita'}
        </MenuItem> */}
        {/* 
        <MenuItem>
          <ListItemIcon>
            <FileCopyIcon fontSize="small" />
          </ListItemIcon>
          Duplica
        </MenuItem>        
        */}
        {!props.workspaceUser.federated && (
          <MenuItem onClick={resetPassword}>
            <ListItemIcon>
              <LockResetIcon fontSize="small" />
            </ListItemIcon>
            Reset password
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            actionHandler({
              action: 'otpsend',
              data: {
                id: props.workspace.id,
                idKeycloak: props.workspaceUser.id,
              },
            });

            openImpersonateDialog();
          }}
        >
          <ListItemIcon>
            <EscalatorWarningIcon fontSize="small" />
          </ListItemIcon>
          Impersona
        </MenuItem>
      </Menu>
      <Dialog
        onClose={closeDialog}
        open={openDialog}
        fullWidth={usedForDialogImpersonate ? false : fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle>
          {usedForDialogAddUserOnAGroup
            ? 'Aggiungi ' +
              (props.workspaceUser ? props.workspaceUser.email : '') +
              ' ad un gruppo'
            : ''}
          {usedForDeleteUserFromGroup
            ? 'Elimina ' +
              (props.workspaceUser ? props.workspaceUser.email : '') +
              ' dai gruppi'
            : ''}
          {usedForDialogImpersonate && 'Codice OTP'}
        </DialogTitle>
        <DialogContent>
          {!usedForDialogImpersonate ? (
            <GroupIndex
              canCreate={false}
              usedForDialogAddUserOnAGroup={usedForDialogAddUserOnAGroup}
              usedForDeleteUserFromGroup={usedForDeleteUserFromGroup}
              workspaceUser={props.workspaceUser}
            ></GroupIndex>
          ) : (
            <div>
              <Typography family="light" sx={{ color: '#000' }}>
                Inserisci codice OTP ricevuto
              </Typography>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  margin: '0 -4px',
                }}
              >
                {Array.from({ length: 6 }).map((_, index) => {
                  return (
                    <span className="otp-wrapper">
                      <Input
                        key={`OTPInput${index}`}
                        sx={{ margin: '16px 4px 0', maxWidth: '40px' }}
                        autoFocus={index === 0}
                        inputProps={{
                          inputMode: 'numeric',
                          maxLength: 1,
                          style: {
                            padding: '10px 0px',
                            textAlign: 'center',
                            fontFamily: 'WotfardLight',
                            color: '#1A416B',
                            fontSize: '28px',
                          },
                          onKeyUp: (e) => {
                            if (e.key === 'Delete' || e.key === 'Backspace') {
                              if (refs[index - 1]) refs[index - 1].focus();
                              if (refs[index - 1]) refs[index - 1].select();
                              return;
                            }

                            if (e.key === 'Enter' && e.currentTarget.value) {
                              if (refs[index + 1]) refs[index + 1].focus();
                              return;
                            }
                          },
                          onInput: (e) => {
                            if (e.currentTarget.value) {
                              if (refs[index + 1]) refs[index + 1].focus();
                              return;
                            }
                          },
                          tabIndex: index,
                          onPaste: handlePaste,
                        }}
                        inputRef={(ref) => {
                          refs[index] = ref;
                        }}
                        fullWidth={false}
                      />
                    </span>
                  );
                })}
              </div>
            </div>
          )}
        </DialogContent>

        {otpError && (
          <Typography
            family="light"
            sx={{
              color: 'rgb(244, 67, 54)',
              padding: '10px',
              textAlign: 'center',
            }}
          >
            Qualcosa è andato storto.
            <br />
            Inserisci nuovamente il codice OTP!
          </Typography>
        )}

        {!usedForDialogImpersonate && <Divider />}
        <DialogActions>
          <Button onClick={closeDialog} size="small" color="secondary">
            Chiudi
          </Button>
          {usedForDialogImpersonate && (
            <Button onClick={impersonate} size="small">
              Verifica
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { ReactNode } from 'react';
import { Stack, Toolbar, Typography } from '@mui/material';

const TitleToolbar = ({
  title,
  subtitle,
  children,
}: {
  title: string;
  subtitle?: string;
  children?: ReactNode;
}) => (
  <Toolbar className="TitleToolbar" disableGutters>
    <div style={{ marginRight: '20px', flexGrow: 1 }}>
      <Typography variant="h5" color="secondary">
        {title}
      </Typography>
      {subtitle && (
        <Typography color="secondary" sx={{ fontFamily: 'WotfardLight' }}>
          {subtitle}
        </Typography>
      )}
    </div>

    {children ? (
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
        {children}
      </Stack>
    ) : null}
  </Toolbar>
);

export default TitleToolbar;

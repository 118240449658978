import axios, { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Workspace } from '../../types';
import { useAppInitiator } from '../providers/AppInitiator';
import {
  DEFAULT_LIMIT_REQUIRO_START_USERS,
  SHOW_LAST_USERS,
  SHOW_LAST_WORKSPACES,
} from './config';

export const WORKSPACES = 'workspaces';

export const WS_QUERY_KEY = 'ws';
export const WS_APPS_QUERY_KEY = 'ws_apps';
export const WS_USERS_QUERY_KEY = 'ws_users';
export const WS_GROUPS_QUERY_KEY = 'ws_groups';
export const USERS_QUERY_KEY = 'users';
export const USERS_OF_GROUP_QUERY_KEY = 'group/users';
export const REQUIRO_START_ONE_USER_QUERY_KEY = 'oneUserRequiroStart';
export const REQUIRO_START_USERS_QUERY_KEY = 'usersRequiroStart';
export const REQUIRO_START_USERS_EXT_QUERY_KEY = 'usersRequiroStartEXT';
export const REQUIRO_START_CLASSES = 'requiroStartClasses';
export const REQUIRO_START_CLASSES_CONFIG = 'requiroStartClassesConfig';
export const REQUIRO_SUBSCRIPTION_TYPES_KEY = 'requiroSubsciptionTypes';
export const REQUIRO_INTEGRATION_KEY = 'requiroIntegration';
export const REQUIRO_CLASSPROPS_KEY = 'requiroClassProperties';
export const REQUIRO_START_SIGN_CONFIG = 'requiroStartSignConfig';

export function endpoints(baseUrl: string) {}

/**
 *
 * @returns
 */
export function useAppsQuery() {
  const { boot } = useAppInitiator();
  return useQuery(
    WS_APPS_QUERY_KEY,
    () => axios.get(boot.appServicesBaseUrl + '/app'),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  );
}

/**
 *
 * @returns
 */
export function useSingleAppQuery() {
  const { boot } = useAppInitiator();
  const params = useParams();
  const { id } = params;

  return useQuery(
    [WS_APPS_QUERY_KEY, id],
    () => axios.get(boot.appServicesBaseUrl + '/app/' + id),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!id,
    },
  );
}

export function useWorkspaces() {
  const { boot } = useAppInitiator();
  const key = WORKSPACES;
  const fn = () => axios.get(`${boot.appServicesBaseUrl}/workspace`);

  console.log('useWorkspaces');

  return useQuery<
    Promise<AxiosResponse<any, any>>,
    Error,
    { totalCount: number; workspaces: Workspace[] }
  >(key, fn, {
    refetchOnWindowFocus: false,
    retry: 3,
    refetchOnMount: true,
    select: (data) => get(data, 'data.data'),
  });
}

/**
 *
 * @returns
 */
export function useWorkspaceAppsQuery() {
  const { boot } = useAppInitiator();
  const params = useParams();
  const { id } = params;

  return useQuery(
    [WS_APPS_QUERY_KEY, 'useWorkspaceAppsQuery'],
    () => axios.get(`${boot.appServicesBaseUrl}/workspace/${id}/app`),
    {
      refetchOnWindowFocus: false,
      // refetchOnMount: true,
    },
  );
}

/**
 *
 * @returns
 */
export function useWorkspaceUsersQuery(
  realm: string = '',
  limit: string | number = String(DEFAULT_LIMIT_REQUIRO_START_USERS),
  start: string | number = 0,
) {
  const { boot } = useAppInitiator();
  const params = useParams();
  const { id } = params;
  const url = `${boot.appServicesBaseUrl}/workspace/${id}/user?realm=${realm}&limit=${limit}&start=${start}`;

  return useQuery(
    [WS_USERS_QUERY_KEY, id, limit, start, realm],
    () => axios.get(url),
    {
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: true,
    },
  );
}

/**
 *
 * @returns workspace groups
 */
export function useWorkspaceGroupsQuery(config: {} = {}) {
  const { boot } = useAppInitiator();
  const params = useParams();
  const { id } = params;
  const url = boot.appServicesBaseUrl + '/workspace/' + id + '/group';
  return useQuery([WS_GROUPS_QUERY_KEY, id], () => axios.get(url), {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
    ...config,
  });
}

export function useSingleGroupQuery() {
  const { boot } = useAppInitiator();
  const params = useParams();
  const { id, idGroup } = params;
  const url = `${boot.appServicesBaseUrl}/workspace/${id}/group/${idGroup}`;
  return useQuery([WS_GROUPS_QUERY_KEY, id, idGroup], () => axios.get(url), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!idGroup && !!id,
    cacheTime: 1000,
  });
}

export function usePermissionSingleGroupQuery(edit) {
  const { boot } = useAppInitiator();
  const params = useParams();
  const { id, idGroup } = params;
  let url = `${boot.appServicesBaseUrl}/workspace/${id}/group/${idGroup}/permission`;
  url = edit ? url : `${url}?mode=have`;
  return useQuery(
    [WS_GROUPS_QUERY_KEY, id, idGroup, String(edit)],
    () => axios.get(url),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!idGroup && !!id,
      cacheTime: 1000,
    },
  );
}

export function usePermissionUser() {
  const { boot } = useAppInitiator();
  const params = useParams();
  const { id, idUser } = params;
  const url = `${boot.appServicesBaseUrl}/workspace/${id}/user/${idUser}/permission`;
  return useQuery([WS_GROUPS_QUERY_KEY, id, idUser], () => axios.get(url), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!idUser && !!id,
    cacheTime: 1000,
  });
}

export function useUserInformation() {
  const { boot } = useAppInitiator();
  const params = useParams();
  const { id, idUser } = params;
  const url = `${boot.appServicesBaseUrl}/workspace/${id}/user/${idUser}`;
  return useQuery(
    [WS_GROUPS_QUERY_KEY, id, idUser, 'UserInformation'],
    () => axios.get(url),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!idUser && !!id,
      cacheTime: 1000,
    },
  );
}

/**
 *
 * @returns
 */
export function useSingleWorkspace() {
  const { boot } = useAppInitiator();
  const params = useParams();
  const { id } = params;
  const url = `${boot.appServicesBaseUrl}/workspace/${id}`;
  return useQuery([WS_QUERY_KEY, id], () => axios.get(url), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!id,
    cacheTime: 1000,
  });
}

/**
 *
 * @returns
 */
export function useSingleWorkspaceById(id: number) {
  const { boot } = useAppInitiator();
  const url = `${boot.appServicesBaseUrl}/workspace/${id}`;
  return useQuery([WS_QUERY_KEY, id], () => axios.get(url), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!id,
    cacheTime: 1000,
  });
}

export function useWorkspacesInDashboard(
  limit: string = String(SHOW_LAST_WORKSPACES),
) {
  const { boot } = useAppInitiator();
  const url = `${boot.appServicesBaseUrl}/workspace?limit=${limit}`;

  return useQuery([WS_QUERY_KEY, 'ws', limit], () => axios.get(url), {
    refetchOnWindowFocus: false,
    retry: 3,
  });
}

export function useUsersInDashboard(max: string = String(SHOW_LAST_USERS)) {
  const { boot } = useAppInitiator();
  const url = `${boot.appServicesBaseUrl}/user?limit=${max}`;

  return useQuery([WS_USERS_QUERY_KEY, 'user', max], () => axios.get(url), {
    refetchOnWindowFocus: false,
    retry: 3,
  });
}

/**
 *
 * @param limit
 * @param start
 * @returns
 */
export function useRequiroStartUsers(
  limit: string = String(DEFAULT_LIMIT_REQUIRO_START_USERS),
  start: string = String(0),
) {
  const { boot } = useAppInitiator();
  const url = `${boot.appServicesBaseUrl}/user?realm=master&limit=${limit}&start=${start}`;

  return useQuery(
    [REQUIRO_START_USERS_QUERY_KEY, limit, start],
    () => axios.get(url),
    //() => axios.get(`${boot.appServicesBaseUrl}/user?realm=standard`),
    {
      refetchOnWindowFocus: false,
      retry: 3,
    },
  );
}

/**
 *
 * @returns
 */
export function useRequiroStartUsersEXT() {
  const { boot } = useAppInitiator();
  const url = `${boot.appServicesBaseUrl}/userExt?realm=master&exact=false`;

  return useQuery([REQUIRO_START_USERS_EXT_QUERY_KEY], () => axios.get(url), {
    refetchOnWindowFocus: false,
    retry: 1,
  });
}

/**
 *
 * @param realm
 * @returns
 */
export function fetchUserArchiva(realm: string = 'master') {
  const params = useParams();
  const { boot } = useAppInitiator();

  const url = `${boot.appServicesBaseUrl}/user/${params.id}?realm=${realm}`;

  return useQuery(
    [REQUIRO_START_ONE_USER_QUERY_KEY, params.id],
    () => axios.get(url),

    {
      refetchOnWindowFocus: false,
      cacheTime: 1000,
      retry: 1,
      enabled: !!params.id,
    },
  );
}

/**
 *
 * @param limit
 * @param start
 * @param outside
 * @returns
 */
export function useUsersInGroup(
  limit: string = String(DEFAULT_LIMIT_REQUIRO_START_USERS),
  start: string = String(0),
  outside: boolean = false,
) {
  const { boot } = useAppInitiator();
  const params = useParams();
  const { id, idGroup } = params;

  const url = `${boot.appServicesBaseUrl}/workspace/${id}/group/${idGroup}/user?outside=${outside}&limit=${limit}&start=${start}`;

  return useQuery(
    [USERS_OF_GROUP_QUERY_KEY, limit, start, id, idGroup, outside],
    () => axios.get(url),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
}

/**
 * Find all groups for a user
 * @returns groups for a user
 */
export function useGroupsOfCloudUserQuery(
  idKeycloak: string | number = String(0),
  outside: boolean = false,
  config: {} = {},
) {
  const { boot } = useAppInitiator();
  const params = useParams();
  const { id } = params;
  const url = `${boot.appServicesBaseUrl}/workspace/${id}/user/${idKeycloak}/group?outside=${outside}`;

  return useQuery(
    [WS_GROUPS_QUERY_KEY, id, idKeycloak, outside],
    () => axios.get(url),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      ...config,
    },
  );
}
/**
 *
 * @returns
 */
export function useSingleCustomerQuery(customerCode: string) {
  const { boot } = useAppInitiator();

  return useQuery(
    [WS_APPS_QUERY_KEY, customerCode],
    () => axios.get(boot.appCoreServicesBaseUrl + '/customers/' + customerCode),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!customerCode,
    },
  );
}

/**
 *
 * @returns
 */
export function useClassesByCustomerCodeQuery(customerCode: string) {
  const { boot } = useAppInitiator();

  return useQuery(
    [REQUIRO_START_CLASSES, customerCode],
    () =>
      axios.get(
        boot.appKissflowServicesBaseUrl + '/requiroClasses/' + customerCode,
      ),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!customerCode,
    },
  );
}

/**
 *
 * @returns
 */
export function useConfigurationByClassname(
  customerCode: string,
  className: string,
) {
  const { boot } = useAppInitiator();

  let qString = '';
  if (customerCode && className) {
    qString = '?customerCode=' + customerCode + '&className=' + className;
  }

  return useQuery(
    [REQUIRO_START_CLASSES_CONFIG, customerCode, className],
    () =>
      axios.get(boot.appKissflowServicesBaseUrl + '/configuration' + qString),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!customerCode && !!className,
    },
  );
}

/**
 *
 * @returns
 */
export function useConfigurationById(id: string) {
  const { boot } = useAppInitiator();

  return useQuery(
    [REQUIRO_START_SIGN_CONFIG, id],
    () => axios.get(boot.appKissflowServicesBaseUrl + '/configuration/' + id),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!id,
    },
  );
}

export async function fetchFullClassesByCustomerCodeQuery(
  baseUrl: string,
  customerCode: string,
) {
  let classes = null;
  try {
    const fetchClasses = await axios.get(
      baseUrl + '/requiroClasses/' + customerCode,
    );
    classes = fetchClasses.data.data;
  } catch (error) {
    classes = null;
  }

  const fullClasses = classes
    ? await Promise.all(
        classes.map(async (c) => {
          let qString = '';
          if (customerCode && c.classname) {
            qString =
              '?customerCode=' + customerCode + '&className=' + c.classname;
          }

          let config = null;
          try {
            config = await axios.get(baseUrl + '/configuration' + qString);
          } catch (error) {
            config = null;
          }

          return {
            ...c,
            configuration: config && config.data ? config.data.data[0] : null,
          };
        }),
      )
    : null;

  return fullClasses;
}

export async function fetchFullSignByCustomerCodeQuery(
  baseUrl: string,
  customerCode: string,
) {
  let sign = null;
  try {
    let qString = '';
    if (customerCode) {
      qString = '?customerCode=' + customerCode;
    }
    const fetchSign = await axios.get(baseUrl + '/signConfiguration' + qString);
    sign = fetchSign.data.data;
  } catch (error) {
    sign = { error: error };
  }

  return sign;
}

export async function fetchAuditByConfigurationId(baseUrl: string, id: string) {
  return await axios.get(baseUrl + '/configurationAudit/' + id);
}

/*
 * Find all customers in workspace
 * @returns customers of workspace
 */
export function useCustomersInWorkspace(
  id: string | number = String(0),
  config: {} = {},
) {
  const { boot } = useAppInitiator();
  const url = `${boot.appServicesBaseUrl}/workspace/${id}/customer`;

  return useQuery([WS_GROUPS_QUERY_KEY, id], () => axios.get(url), {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
    ...config,
  });
}

/*
 * Find all customers with a specific companyGroupCode
 * @returns customers with a specific companyGroupCode
 */
export function useCustomersWithcompanyGroupCode(
  companyGroupCode: string | number,
  config: {} = {},
) {
  const { boot } = useAppInitiator();
  const url = `${boot.appServicesBaseUrl}/customer?companyGroupCode=${companyGroupCode}`;

  return useQuery(
    [WS_GROUPS_QUERY_KEY, companyGroupCode],
    () => axios.get(url),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      enabled: Boolean(companyGroupCode),
      ...config,
    },
  );
}

/**
 * Find all customers in workspace
 * @returns customers of workspace
 */
export function useFetchCustomerGroupCodeOfPrincipalCustomer(
  principalCustomerCode: string | number,
  config: {} = {},
) {
  const { boot } = useAppInitiator();
  const url = `${boot.appCoreServicesBaseUrl}/customers?infoCustomer=${principalCustomerCode}`;

  return useQuery(
    [WS_GROUPS_QUERY_KEY, principalCustomerCode],
    () => axios.get(url),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      ...config,
    },
  );
}

/**
 *
 * @returns
 */
export function useSubscriptionTypesQuery() {
  const { boot } = useAppInitiator();
  return useQuery(
    REQUIRO_SUBSCRIPTION_TYPES_KEY,
    () => axios.get(boot.appKissflowServicesBaseUrl + '/subscriptionTypes'),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  );
}

/**
 *
 * @returns
 */
export function useIntegrationIdQuery(customerCode: string) {
  const { boot } = useAppInitiator();
  return useQuery(
    [REQUIRO_INTEGRATION_KEY, customerCode],
    () =>
      axios.get(
        boot.appKissflowServicesBaseUrl + '/integrationId/' + customerCode,
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: !!customerCode,
    },
  );
}

/**
 *
 * @returns
 */
export function useIntegrationIdSignQuery(customerCode: string) {
  const { boot } = useAppInitiator();
  return useQuery(
    [REQUIRO_INTEGRATION_KEY, customerCode],
    () =>
      axios.get(
        boot.appKissflowServicesBaseUrl + '/integrationId/' + customerCode,
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: !!customerCode,
    },
  );
}

export async function fetchSubscriptions(baseUrl: string, id: string) {
  let res = null;
  try {
    res = await axios.get(baseUrl + '/subscriptions?configurationId=' + id);
  } catch (error) {
    res = null;
  }

  return res;
}

/**
 *
 * @returns
 */
export function useClassPropertiesQuery(
  customerCode: string,
  className: string,
) {
  const { boot } = useAppInitiator();
  return useQuery(
    [REQUIRO_CLASSPROPS_KEY, customerCode, className],
    () =>
      axios.get(
        boot.appKissflowServicesBaseUrl +
          '/requiroClassProperties/' +
          customerCode +
          '/' +
          className,
      ),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!customerCode && !!className,
    },
  );
}

import React, { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem, TableCell, TableRow } from 'requiro-ui';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import { IconButton, Stack } from '@mui/material';
import { IHasChildren, TaskModel } from '../../../types';
import { IHeaderCell } from '../../features/jobs/helpers/tableCells';
import { useJobsContext } from '../../features/jobs/Jobs';
import { dateTimeFormatter, dateTimeFormatter2 } from '../../lib/utils';

/**
 * Tablecell components
 */

/**
 *
 * @param param0
 * @returns
 */
const Bool = ({
  children,
  cellInfo,
}: {
  children: ReactNode;
  cellInfo: IHeaderCell;
}) => <TableCell>{children}</TableCell>;

/**
 *
 * @param param0
 * @returns
 */
const Tag = ({
  children,
  cellInfo,
}: {
  children: ReactNode;
  cellInfo: IHeaderCell;
}) => {
  return <TableCell>{children}</TableCell>;
};

/**
 *
 * @param param0
 * @returns
 */
const DateTime = ({
  children,
  cellInfo,
}: {
  children: ReactNode;
  cellInfo: IHeaderCell;
}) => <TableCell>{dateTimeFormatter2(String(children))}</TableCell>;

/**
 * Actions associate alla riga del Job
 * @param param0
 * @returns
 */
const Actions = ({
  task,
  cellInfo,
}: {
  task: TaskModel;
  cellInfo: IHeaderCell;
}) => {
  return (
    <TableCell>
      <ActionButton task={task} />
      {/* <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: cellInfo.align || 'flex-end' }}
      >
        <IconButton onClick={() => ctx.action({ action: 'pause', data: job })}>
          <PauseCircleOutlineOutlinedIcon />
        </IconButton>
        <IconButton onClick={() => ctx.action({ action: 'download', data: job })}>
          <FileDownloadOutlinedIcon />
        </IconButton>
      </Stack> */}
    </TableCell>
  );
};

const ItemStack = ({ children }: IHasChildren) => (
  <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
    {children}
  </Stack>
);

const ActionButton = ({ task }: { task: TaskModel }) => {
  const ctx = useJobsContext();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const openHandler = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeHandler = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton onClick={openHandler}>
        <MoreVertOutlinedIcon />
      </IconButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={closeHandler}
        onClick={closeHandler}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => ctx.action({ action: 'log', data: task })}>
          <ItemStack>
            <InfoOutlinedIcon color="secondary" /> <span>Log</span>
          </ItemStack>
        </MenuItem>
        <MenuItem
          onClick={() => ctx.action({ action: 'download_output', data: task })}
        >
          <ItemStack>
            <FileDownloadOutlinedIcon /> <span>Download output csv</span>
          </ItemStack>
        </MenuItem>
        <MenuItem
          onClick={() => ctx.action({ action: 'download_input', data: task })}
        >
          <ItemStack>
            <FileDownloadOutlinedIcon /> <span>Download input csv</span>
          </ItemStack>
        </MenuItem>
      </Menu>
    </>
  );
};

/**
 * TableHeader -> TableCell
 * @param param0
 * @returns
 */
const Header = ({ info }: { info: IHeaderCell }) => {
  return <TableCell sx={{ fontWeight: '700' }}>{info.label}</TableCell>;
};

/**
 * Default TableCell
 * @param param0
 * @returns
 */
const Cell = ({
  children,
  cellInfo,
}: {
  children: ReactNode;
  cellInfo: IHeaderCell;
}) => <TableCell>{children}</TableCell>;

Cell.Actions = Actions;
Cell.Bool = Bool;
Cell.DateTime = DateTime;
Cell.Tag = Tag;
Cell.Header = Header;

const renderState = (state: number) => {
  switch (state) {
    case 0:
      return 'DISABLED';
    case 1:
      return 'ENABLED';
    case 2:
      return 'DELETED';
    case 3:
      return 'TODO';
    case 4:
      return 'IN PROGRESS';
    case 5:
      return 'DONE';
    case 6:
      return 'ERROR';
    case 7:
      return 'INTERRUPTED';

    default:
      break;
  }
};

/**
 * tableRow component
 */
const Row = ({ info, map }: { info: TaskModel; map: IHeaderCell[] }) => {
  return (
    <TableRow>
      {map.map((cell) => {
        const { type, id } = cell;
        let value = info[`${cell.id}`];

        // Translate state number to string
        if (type === 'tag' && id === 'state') value = renderState(value);

        if (type === 'boolean')
          return (
            <Cell.Bool key={id} cellInfo={cell}>
              {value}
            </Cell.Bool>
          );
        if (type === 'datetime')
          return (
            <Cell.DateTime key={id} cellInfo={cell}>
              {value}
            </Cell.DateTime>
          );
        if (type === 'action')
          return (
            <Cell.Actions key={id} cellInfo={cell} task={info}></Cell.Actions>
          );
        if (type === 'tag')
          return (
            <Cell.Tag key={id} cellInfo={cell}>
              {value}
            </Cell.Tag>
          );

        return (
          <Cell key={id} cellInfo={cell}>
            {value}
          </Cell>
        );
      })}
    </TableRow>
  );
};

export { Row as default, Cell };

import { get } from 'lodash';

/**
 * extract the error message
 * @param query
 * @returns
 */
export const extractErrorMessage = (query: any) => {
  const errorData = get(query, 'error.response.data.status', {
    code: 400,
    error: { message: 'Si è verificato un errore.' },
  });
  console.log('errorData', errorData);
  const { code, error } = errorData;
  return code >= 400 && code <= 500
    ? error.message
    : 'Si è verificato un errore.';
};

export const extractMutationErrorMessage = (fail: any) => {
  const errorData = get(fail, 'response.data.status', {
    code: 1,
    error: { message: 'Si è verificato un errore.' },
  });
  console.log({ fail, errorData });
  const { code, error } = errorData;
  return error.message;
};

/**
 * used in table for date time
 * @param value
 * @returns
 */
export function dateTimeFormatter(value?: string): string {
  return value ? new Date(Number(value) * 1000).toLocaleString() : '';
}

export function dateTimeFormatter2(value?: string): string {
  return value ? new Date(Number(value)).toLocaleString() : '';
}

/**
 *
 * @param currentUser
 * @returns
 */
export function createAvatarChars(currentUser: {
  firstName: string;
  lastName: string;
}) {
  const { firstName = '_', lastName = '_' } = currentUser;
  const chars = `${firstName.split('')[0]}${lastName.split('')[0]}`;
  return chars.toUpperCase();
}

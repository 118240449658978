import { get } from 'lodash';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import { t } from 'i18next';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from 'requiro-ui';

import { Order, WorkspaceInfo, WsCustomer } from '../../../../../../types';
import { ScreenWrapper } from '../../../../../components/layouts/AppLayout';
import MessageBox from '../../../../../components/MessageBox';
import TitleToolbar from '../../../../../components/TitleToolbar';
import {
  useSingleAppQuery,
  useSingleWorkspaceById,
} from '../../../../../lib/queries';
import { useCrumbs } from '../../../../../lib/useCrumbs';
import { usePagination } from '../../../../../lib/usePagination';
import { tableRowsPerPageOptions } from '../../../../../lib/config';
import { useApplicationContext } from '../../../../../providers/ApplicationProvider';

import {
  getComparator,
  stableSort,
} from '../../../../../utilities/UtilitiesFunction';

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof WsCustomer,
  ) => void;
  order: Order;
  orderBy: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof WsCustomer;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'companyName',
    numeric: false,
    disablePadding: true,
    label: 'Ragione Sociale',
  },
  {
    id: 'customerCode',
    numeric: false,
    disablePadding: true,
    label: 'Customer Code',
  },
];

const KissflowCustomers = () => {
  let { currentWorkspace } = useApplicationContext();
  const queryInfo = useSingleAppQuery();
  const queryInfoWorkspace = useSingleWorkspaceById(currentWorkspace.id);

  const location = useLocation();
  const navigate = useNavigate();

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof WsCustomer>('companyName');

  const { rowsPerPage, onRowsPerPageChange } = usePagination();
  const [page, setPage] = React.useState(0);

  const app = get(queryInfo, 'data.data.data', []);
  const currentWorkspaceInfo = get(
    queryInfoWorkspace,
    'data.data.data',
    {},
  ) as WorkspaceInfo;

  currentWorkspace = { ...currentWorkspace, ...currentWorkspaceInfo };

  useCrumbs([
    { to: '/ws', label: 'Workspaces' },
    { to: `/ws/${currentWorkspace.id}`, label: `${currentWorkspace.name}` },
    { to: `/ws/${currentWorkspace.id}/apps`, label: `App` },
    { label: app && app.name ? app.name : '' },
  ]);

  const handleClick = (
    event: React.MouseEvent<unknown>,
    code: string,
    customer: WsCustomer,
  ) => {
    navigate(`${location.pathname}/customer/${code}`);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onRowsPerPageChange(event);
    setPage(0);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof WsCustomer,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  if (queryInfo.isLoading) {
    return <MessageBox.Loading />;
  }
  if (queryInfo.isError) {
    return <MessageBox.QueryError query={queryInfo} />;
  }

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof WsCustomer) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <strong>{headCell.label}</strong>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell key={'link'} align={'right'} padding={'normal'}>
            {' '}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const rows = currentWorkspace.customers || [];

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <ScreenWrapper>
      <TitleToolbar
        title={t('clientsTitle', 'Clienti che accedono a questo Workspace')}
      />

      <TableContainer>
        <Table aria-labelledby="tableTitle" size="medium">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />

          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow
                    hover={true}
                    onClick={(event: React.MouseEvent<unknown, MouseEvent>) =>
                      handleClick(
                        event,
                        String(row.customerCode),
                        row as WsCustomer,
                      )
                    }
                    tabIndex={-1}
                    key={row.customerCode}
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <TableCell align="left">{row.companyName}</TableCell>
                    <TableCell align="left">{row.customerCode}</TableCell>
                    <TableCell align="right">
                      <KeyboardArrowRightIcon
                        style={{ bottom: '-3px', position: 'relative' }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={tableRowsPerPageOptions}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </ScreenWrapper>
  );
};

export default KissflowCustomers;

import axios from 'axios';
import { get } from 'lodash';

// export async function getUsersOfWorkspace(idWorkspace: string) {
//   return await axios.get(
//     process.env.REACT_APP_BASE_URL + '/workspace/' + idWorkspace + '/user',
//   );
//   //return await axios.get("/mockJson/workspaceUsers.json");
// }

// export async function getUser(idWorkspace: string, idUser: string) {
//   return await axios.get(
//     process.env.REACT_APP_BASE_URL +
//       '/workspace/' +
//       idWorkspace +
//       '/user/' +
//       idUser,
//   );
// }

export async function updateUser(
  baseUrl,
  idWorkspace: string,
  idUser: string,
  user: any,
) {
  return await axios.put(
    baseUrl + '/workspace/' + idWorkspace + '/user/' + idUser,
    user,
  );
}

export async function createUser(baseUrl, idWorkspace: string, user: any) {
  return await axios.post(
    baseUrl + '/workspace/' + idWorkspace + '/user',
    user,
  );
}

// export async function deleteUser(idWorkspace: string, idUser: string) {
//   return await axios.delete(
//     process.env.REACT_APP_BASE_URL +
//       '/workspace/' +
//       idWorkspace +
//       '/user/' +
//       idUser,
//   );
// }

export async function impersonateUser({
  baseUrl,
  id,
  idKeycloak,
  authServer,
  realm,
}: {
  baseUrl: string;
  id: string;
  idKeycloak: string;
  authServer: string;
  realm: string;
}) {
  try {
    // "/workspace/{id}/impersonate/{idKeycloak}"
    const url = `${baseUrl}/workspace/${id}/impersonate/${idKeycloak}`;
    // console.log('impersonateUser', { url });

    const result = await axios.get(url);
    const { access_token, token_type } = get(result, 'data.data', {});
    // console.log('impersonateUser', { access_token });

    //srv-rqxt01.architm.it/auth/realms/standard/rest/impersonate
    const body = {
      access_token,
    };
    const config = {
      headers: {
        // ['Authorization']: `${token_type} ${access_token}`,
        rqx_auth_impersonate: access_token,
      },
    };
    const url2 = `${authServer}realms/${realm}/rest/impersonate`;
    const postResult = await axios.post(url2, body);
    //console.log('impersonateUser', { postResult });

    window.open('http://srv-rqxt01.architm.it');
  } catch (error) {
    console.error({ error });
  }
}

export async function impersonateUserGET({
  baseUrl,
  id,
  idKeycloak,
  otp,
  authServer,
  realm,
  workspaceRealm,
}: {
  baseUrl: string;
  id: string;
  idKeycloak: string;
  otp?: string;
  authServer: string;
  realm: string;
  workspaceRealm: string;
}) {
  try {
    // "/workspace/{id}/impersonate/{idKeycloak}"
    const url = `${baseUrl}/workspace/${id}/impersonate/${idKeycloak}?otp=${otp}`;
    // console.log('impersonateUserGET', { url });

    const result = await axios.get(url);
    const { access_token, token_type } = get(result, 'data.data', {});
    const config = {
      headers: {
        ['Authorization']: `Bearer ${access_token}`,
      },
    };
    const url2 = `${authServer}realms/${workspaceRealm}/rest/impersonate?st=${access_token}`;
    window.open(url2);

    return result;
  } catch (error) {
    console.error({ error });
  }
}

export async function impersonateUserOTPSend({
  baseUrl,
  id,
  idKeycloak,
}: {
  baseUrl: string;
  id: string;
  idKeycloak: string;
}) {
  try {
    return await axios.post(
      `${baseUrl}/workspace/${id}/user/${idKeycloak}/otp`,
    );
  } catch (error) {
    console.error({ error });
  }
}

// export async function impersonateUserGET({
//   baseUrl,
//   id,
//   idKeycloak,
//   authServer,
//   realm,
// }: {
//   baseUrl: string;
//   id: string;
//   idKeycloak: string;
//   authServer: string;
//   realm: string;
// }) {
//   try {
//     // "/workspace/{id}/impersonate/{idKeycloak}"
//     const url = `${baseUrl}/workspace/${id}/impersonate/${idKeycloak}`;
//     console.log({ url });

//     const result = await axios.get(url);
//     const { access_token, token_type } = get(result, 'data.data', {});
//     console.log({ access_token });

//     //srv-rqxt01.architm.it/auth/realms/standard/rest/impersonate
//     // const body = {
//     //   access_token,
//     // };
//     const config = {
//       headers: {
//         ['Access-Control-Allow-Origin']: '*',
//         ['Authorization']: `Bearer ${access_token}`,
//         ['requiro_portal']: 'http://srv-rqxt01.archivagroup.it',
//         // rqx_auth_impersonate: access_token,
//       },
//     };
//     const url2 = `${authServer}realms/${realm}/rest/impersonate`;
//     const postResult = await axios.post(url2, undefined, config);
//     console.log({ postResult });
//   } catch (error) {
//     console.log({ error });
//   }
// }

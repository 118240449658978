import React from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton, Paper, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Img from '../../../components/Img';
import { AppModel } from '../../../generated/axios';
import defaultIcon from '../../../lib/defaultIcon';

const GridItemWrapper = styled('div')(({ theme }) => ({
  width: '25%',
  padding: theme.spacing(1),
}));

const View = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minHeight: '280px',
  padding: theme.spacing(3),

  marginBottom: theme.spacing(3),
  _outline: '1px solid red',
}));

export default ({ data }: { data: AppModel }) => {
  return (
    <GridItemWrapper>
      <View elevation={3}>
        <Stack direction="column" spacing={2} sx={{ flexGrow: 1 }}>
          <Stack direction="row" spacing={3} sx={{ alignItems: 'center' }}>
            <Img alt="alt" src="" altSrc={defaultIcon} className="ico" />
            <Typography variant="h6">{data.name}</Typography>
          </Stack>
          <Stack direction="column">
            <Typography variant="subtitle1">{data.description}</Typography>
            <Typography variant="subtitle2">Vendor: {data.vendor}</Typography>
            <Typography variant="subtitle2">
              Versione: {data.version}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          {data.id ? (
            <>
              <Link to={`${data.id}`} state={{ name: data.name }}>
                <IconButton>
                  <InfoIcon color="secondary" fontSize="medium" />
                </IconButton>
              </Link>
              <Link to={`edit/${data.id}`} state={{ name: data.name }}>
                <IconButton>
                  <EditIcon color="secondary" fontSize="medium" />
                </IconButton>
              </Link>
            </>
          ) : null}
        </Stack>
      </View>
    </GridItemWrapper>
  );
};

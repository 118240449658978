import React from 'react';
import { Box, Stack, styled, Typography } from '@mui/material';
import MessageBox from '../../../../components/MessageBox';
import { useUserInformation } from '../../../../lib/queries';

// import Img from '../../../../../components/Img';

const StyledSpan = styled('span')(({ theme }) => ({
  display: 'inline-block',
  minWidth: '120px',
  marginRight: theme.spacing(2),
  fontWeight: '400',
}));

const Row = ({ name, value = '' }: { name: string; value?: string }) => {
  return (
    <Typography
      color="secondary"
      sx={{ fontWeight: '700', backgroundColor: '#fff', p: 1, borderRadius: 1 }}
    >
      <StyledSpan>{name}:</StyledSpan>
      {value}
    </Typography>
  );
};

// const DetailsUser = ({ isLoadingInfo, dataInfo, isErrorInfo }: any) => {
const DetailsUser = () => {
  const {
    isLoading: isLoadingInfo,
    data: dataInfo,
    isError: isErrorInfo,
  } = useUserInformation();
  console.log('useUserInformation : ', { dataInfo });
  if (isLoadingInfo) {
    return <MessageBox.Loading />;
  }
  if (
    !isErrorInfo &&
    dataInfo &&
    dataInfo.data &&
    dataInfo.data.data
    // &&
    // dataInfo.data.data.attributes &&
    // dataInfo.data.data.attributes['requirohub.user']
  ) {
    // const requirohubUser = JSON.parse(
    //   dataInfo.data.data.attributes['requirohub.user'][0],
    // );
    const detailsUser = dataInfo.data.data;
    // console.log('data : ' + detailsUser);
    return (
      <Box sx={{ p: 2 }}>
        <Stack direction="column" spacing={1} sx={{ flexGrow: 1 }}>
          <Stack
            direction="row"
            spacing={3}
            sx={{ alignItems: 'center', marginBottom: 2 }}
          >
            {/* <Img alt="alt" src="" altSrc={defaultIcon} className="ico" /> */}
            <Typography variant="h6" color="secondary">
              {detailsUser.firstName} {detailsUser.lastName}
            </Typography>
          </Stack>
          <Stack direction="column" spacing={1} sx={{ paddingLeft: 9 }}>
            <Row name="Nome" value={detailsUser.firstName} />
            <Row name="Cognome" value={detailsUser.lastName} />
            <Row name="Id keycloak" value={detailsUser.id} />
            <Row name="Email" value={detailsUser.email} />
            <Row name="Username" value={detailsUser.username} />
            <Row name="Customer code" value={detailsUser.customerCode} />
            <Row
              name="Utente federato"
              value={detailsUser.federated ? 'Si' : 'No'}
            />
            <Row
              name="Fatturabile"
              value={detailsUser.billable ? 'Si' : 'No'}
            />
          </Stack>
        </Stack>
      </Box>
    );
  }

  return <MessageBox.Info>Qualcosa è andato storto.</MessageBox.Info>;
};

export default DetailsUser;

import axios from 'axios';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';
import { AppModel } from '../../../../generated/axios';
import { WS_APPS_QUERY_KEY } from '../../../../lib/queries';
import { useAppInitiator } from '../../../../providers/AppInitiator';
import { useApplicationContext } from '../../../../providers/ApplicationProvider';

// const BASE_URL = process.env.REACT_APP_BASE_URL;

export function useActions() {
  const { enqueueSnackbar } = useSnackbar();
  const { currentWorkspace } = useApplicationContext();
  const queryClient = useQueryClient();
  const { boot } = useAppInitiator();

  const baseUri = `${boot.appServicesBaseUrl}/workspace/${currentWorkspace.id}/app`;

  function refetch() {
    // console.log('refetch ....');

    queryClient.invalidateQueries(WS_APPS_QUERY_KEY);
  }

  function onSuccess(response: any) {
    // console.log({ response });
    enqueueSnackbar(t('res.saveOK'), { variant: 'success' });
  }

  function onError(error: any) {
    // console.log({ error });
    enqueueSnackbar(t('errors.generic'), { variant: 'error' });
  }

  const createHandler = (data: AppModel) => {
    axios.post(baseUri, data).then(onSuccess).catch(onError).finally(refetch);
  };

  const deleteHandler = (appId: string) => {
    // console.log({ appId });

    axios
      .delete(`${baseUri}/${appId}`)
      .then(onSuccess)
      .catch(onError)
      .finally(refetch);
  };

  return { createHandler, deleteHandler };
}

import { get } from 'lodash';
import React, { useState } from 'react';
import { ScreenWrapper } from '../../../../components/layouts/AppLayout';
import MessageBox from '../../../../components/MessageBox';
import { ScreenToolbarToolsComposite } from '../../../../components/ScreenToolbarTools';
import TitleToolbar from '../../../../components/TitleToolbar';
import { wsActions } from '../../../../lib/config';
import { usePermissionUser } from '../../../../lib/queries';
import { useCrumbs } from '../../../../lib/useCrumbs';
import { useApplicationContext } from '../../../../providers/ApplicationProvider';
import { CollapsibleTable } from './CollapsibleTable';
import DetailsUser from './DetailsUser';

const PermissionsUser = () => {
  const { currentWorkspace } = useApplicationContext();
  const { isLoading, data, isError } = usePermissionUser();
  const name = get(currentWorkspace, 'name', '');
  const [showAll, setShowAll] = useState(true);

  useCrumbs([
    { to: '/ws', label: 'Workspaces' },
    { to: `/ws/${currentWorkspace.id}`, label: `${name}` },
    { to: `/ws/${currentWorkspace.id}/utenti`, label: wsActions.groups },
    { label: 'Visualizza permessi' },
  ]);

  if (isLoading) {
    return <MessageBox.Loading />;
  }
  if (isError) {
    return <MessageBox.Info>Qualcosa è andato storto.</MessageBox.Info>;
  }

  // if (!isError && data && data.data && data.data.data && data.data.data.apps) {
  //   const rowApps = data.data.data.apps;

  const rowApps = get(data, 'data.data.apps', []);

  console.log({ rowApps });
  const filterFn = showAll ? (item) => item : (item) => item.selected;

  return (
    <ScreenWrapper>
      <TitleToolbar title="Dettagli utente"></TitleToolbar>
      <DetailsUser />
      <TitleToolbar title="Visualizza permessi">
        {/* <ScreenToolbarToolsComposite.Toggler
          value={showAll}
          toggle={setShowAll}
        /> */}
      </TitleToolbar>
      {rowApps.length > 0 ? (
        <CollapsibleTable
          rows={rowApps}
          rowsCopy={[...rowApps]}
          edit={false}
          filterFn={filterFn}
        />
      ) : (
        <MessageBox.Info>
          Nessun permesso per il gruppo disponibile.
        </MessageBox.Info>
      )}
    </ScreenWrapper>
  );
};

// return <MessageBox.Info>Qualcosa è andato storto.</MessageBox.Info>;
// };
export default PermissionsUser;

import axios from 'axios';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
// import { DefaultApi } from "../generated/axios";
import {
  UserModel,
  WorkspaceGroupModel,
  ConfigurationModel,
  SubscriptionModel,
} from '../generated/axios/api';
import { useAppInitiator } from '../providers/AppInitiator';

/**
 * workspace groups
 *
 * @param wsId
 * @param data
 * @returns
 */
export function createGroupInWS(config: any = {}) {
  const { boot } = useAppInitiator();
  return useMutation(
    ({
      idWorkspace,
      group,
    }: {
      idWorkspace: string | number;
      group: WorkspaceGroupModel;
    }) => {
      return axios.post(
        `${boot.appServicesBaseUrl}/workspace/${idWorkspace}/group`,
        group,
      );
      // ERRORE nel compiler TS
      // return api.addWorkspaceGroup(Number(idWorkspace), group)}
    },
    config,
  );
}

/**
 *
 * @param config
 * @returns
 */
export function createUserInWS(config: any = {}) {
  const { boot } = useAppInitiator();
  return useMutation(
    ({
      idWorkspace,
      user,
    }: {
      idWorkspace: string | number;
      user: UserModel;
    }) => {
      return axios.post(
        `${boot.appServicesBaseUrl}/workspace/${idWorkspace}/user`,
        user,
      );
      // ERRORE nel compiler TS
      // return api.addWorkspaceGroup(Number(idWorkspace), group)}
    },
    config,
  );
}

/**
 * update a workspsce info
 * @param config
 * @returns
 */
export function editWorkspaceGroup(config: any = {}) {
  const { boot } = useAppInitiator();
  return useMutation(
    ({
      idWorkspace,
      group,
    }: {
      idWorkspace: string | number;
      group: WorkspaceGroupModel;
    }) => {
      return axios.put(
        `${boot.appServicesBaseUrl}/workspace/${idWorkspace}/group/${group.id}`,
        group,
      );
    },
    config,
  );
}

/**
 * update a workspace user info
 * @param config
 * @returns
 */
export function editWorkspaceUser(config: any = {}) {
  const { boot } = useAppInitiator();
  return useMutation(
    ({
      idWorkspace,
      idUser,
      user,
    }: {
      idWorkspace: string | number;
      idUser: string | number;
      user: UserModel;
    }) => {
      return axios.put(
        `${boot.appServicesBaseUrl}/workspace/${idWorkspace}/user/${idUser}`,
        user,
      );
    },
    config,
  );
}

/**
 * Reset user of workspace password
 * @param config
 * @returns
 */
export function resetUserPassword(config: any = {}) {
  const { boot } = useAppInitiator();
  return useMutation(
    ({ idWorkspace, idUser }: { idWorkspace: string; idUser: string }) => {
      return axios.put(
        `${boot.appServicesBaseUrl}/workspace/${idWorkspace}/user/${idUser}/sendResetPassword`,
      );
    },
    config,
  );
}

/**
 * Reset user no workspace password
 * @param config
 * @returns
 */
export function resetUserPasswordNoWorkspace(config: any = {}) {
  const { boot } = useAppInitiator();
  return useMutation(({ idUser }: { idUser: string }) => {
    return axios.put(
      `${boot.appServicesBaseUrl}/user/${idUser}/sendResetPassword`,
    );
  }, config);
}

/**
 * Add user to a group
 * @param config
 * @returns
 */
export function addUserToGroup(config: any = {}) {
  const { boot } = useAppInitiator();
  return useMutation(
    ({
      id,
      idGroup,
      idKeycloak,
    }: {
      id: string | number;
      idGroup: string | number;
      idKeycloak: string | number;
    }) => {
      return axios.post(
        `${boot.appServicesBaseUrl}/workspace/${id}/group/${idGroup}/user/${idKeycloak}`,
      );
    },
    config,
  );
}

/**
 * Delete user from group
 * @param config
 * @returns
 */
export function deleteUserFromGroup(config: any = {}) {
  const { boot } = useAppInitiator();

  return useMutation(
    ({
      id,
      idGroup,
      idKeycloak,
    }: {
      id: string | number;
      idGroup: string | number;
      idKeycloak: string | number;
    }) => {
      return axios.delete(
        `${boot.appServicesBaseUrl}/workspace/${id}/group/${idGroup}/user/${idKeycloak}?realm=master`,
      );
    },
    config,
  );
}

export function createUserArchiva(config: any = {}) {
  const { boot } = useAppInitiator();

  return useMutation((user: UserModel) => {
    return axios.post(`${boot.appServicesBaseUrl}/user/`, user);
  }, config);
}

/**
 * Reset user of workspace password
 * @param config
 * @returns
 */
export function editUserArchiva(config: any = {}) {
  const params = useParams();
  const { boot } = useAppInitiator();
  return useMutation((user: UserModel) => {
    return axios.put(`${boot.appServicesBaseUrl}/user/${user.id}/`, user);
  }, config);
}

/**
 *
 * @param config
 * @returns
 */
export function disableUserArchiva(config: any = {}) {
  const { boot } = useAppInitiator();
  return useMutation((user: UserModel) => {
    return axios.put(`${boot.appServicesBaseUrl}/user/${user.id}/`, user);
  }, config);
}

/**
 *
 * @param config
 * @returns
 */
export function deleteUserArchiva(config: any = {}) {
  const { boot } = useAppInitiator();
  return useMutation((id: string) => {
    return axios.delete(`${boot.appServicesBaseUrl}/user/${id}`);
  }, config);
}

/**
 * Edit classname configuration
 * @param config
 * @returns
 */
export function editConfiguration(config: any = {}) {
  const { boot } = useAppInitiator();
  return useMutation((config: ConfigurationModel) => {
    const qs = Object.keys(config)
      .filter((key) => key !== 'id')
      .map((key) => `${key}=${config[key]}`)
      .join('&');
    return axios.put(
      `${boot.appKissflowServicesBaseUrl}/configuration/${config.id}?${qs}`,
    );
  }, config);
}

/**
 * Edit sign configuration
 * @param config
 * @returns
 */
export function editConfigurationSign(config: any = {}) {
  const { boot } = useAppInitiator();
  return useMutation((config: ConfigurationModel) => {
    const qs = Object.keys(config)
      .filter((key) => key !== 'id')
      .map((key) => `${key}=${config[key]}`)
      .join('&');
    return axios.put(
      `${boot.appKissflowServicesBaseUrl}/signConfiguration/${config.id}?${qs}`,
    );
  }, config);
}

/**
 * Delete classname configuration
 * @param config
 * @returns
 */
export function deleteConfiguration(config: any = {}) {
  const { boot } = useAppInitiator();
  return useMutation((config: ConfigurationModel) => {
    return axios.delete(
      `${boot.appKissflowServicesBaseUrl}/configuration/${config.id}?userName=${config.userName}`,
    );
  }, config);
}

/**
 * Edit classname configuration
 * @param config
 * @returns
 */
export function addConfiguration(config: any = {}) {
  const { boot } = useAppInitiator();
  return useMutation((config: ConfigurationModel) => {
    return axios.post(
      `${boot.appKissflowServicesBaseUrl}/configuration`,
      config,
    );
  }, config);
}

/**
 * Edit classname configuration
 * @param config
 * @returns
 */
export function addConfigurationSign(config: any = {}) {
  const { boot } = useAppInitiator();
  return useMutation((config: ConfigurationModel) => {
    return axios.post(
      `${boot.appKissflowServicesBaseUrl}/signConfiguration`,
      config,
    );
  }, config);
}

/**
 * Add subscription
 * @param config
 * @returns
 */
export function addSubscription(config: any = {}) {
  const { boot } = useAppInitiator();
  return useMutation((config: SubscriptionModel) => {
    return axios.post(
      `${boot.appKissflowServicesBaseUrl}/subscriptions`,
      config,
    );
  }, config);
}

/**
 * Edit subscription
 * @param config
 * @returns
 */
export function editSubscription(config: any = {}) {
  const { boot } = useAppInitiator();
  return useMutation((config: SubscriptionModel) => {
    const qs = Object.keys(config)
      .filter((key) => key !== 'id')
      .map((key) => `${key}=${config[key]}`)
      .join('&');
    return axios.put(
      `${boot.appKissflowServicesBaseUrl}/subscriptions/${config.id}?${qs}`,
    );
  }, config);
}

// POST    /workspace/{id}/task ✔️
// DELETE  /workspace/{id}/task/{idTask} ✔️

import axios from 'axios';
import { useMutation } from 'react-query';
import { TaskModel } from '../../../../types';
import { useAppInitiator } from '../../../providers/AppInitiator';

/**
 * create a new task
 * @param config
 * @returns
 */
export function createTaskMutation(config = {}) {
  const { boot } = useAppInitiator();

  const fn = ({ workspaceId, task }: { workspaceId: number; task: unknown }) =>
    axios.post(
      `${boot.appServicesBaseUrl}/workspace/${workspaceId}/task`,
      task,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

  return useMutation(fn, config);
}

/**
 * delete existing task
 * @param config
 * @returns
 */
export function deleteTaskMutation(config = {}) {
  const { boot } = useAppInitiator();

  const fn = ({
    workspaceId,
    taskId,
  }: {
    workspaceId: number;
    taskId: string | number;
  }) =>
    axios.delete(
      `${boot.appServicesBaseUrl}/workspace/${workspaceId}/task/${taskId}`,
    );

  return useMutation(fn, config);
}

import React, { createContext, useContext, useState } from 'react';
import {
  impersonateUser,
  impersonateUserGET,
  impersonateUserOTPSend,
} from '../../../api/workspaceUsers';
import { ScreenWrapper } from '../../../components/layouts/AppLayout';
import ScreenToolbar from '../../../components/ScreenToolbar';
import { ScreenToolbarToolsComposite } from '../../../components/ScreenToolbarTools';
import TitleToolbar from '../../../components/TitleToolbar';
import { wsActions } from '../../../lib/config';
import { useCrumbs } from '../../../lib/useCrumbs';
import WorkspaceUsersManagement from '../../../pages/WorkspaceUsersManagement';
import { useAppInitiator } from '../../../providers/AppInitiator';
import { useApplicationContext } from '../../../providers/ApplicationProvider';

const WSUsersContext = createContext({
  actionHandler: ({
    action,
    data,
  }: {
    action: string;
    data: { id: string; idKeycloak: string; otp?: string };
  }) => {},
});

const WSUsers = () => {
  const [searchKey, setSearchKey] = useState('');
  const { currentWorkspace } = useApplicationContext();
  const { boot, realm } = useAppInitiator();

  useCrumbs([
    { to: '/ws', label: 'Workspaces' },
    { to: `/ws/${currentWorkspace.id}`, label: `${currentWorkspace.name}` },
    { label: wsActions.users },
  ]);

  const handleSearchByKey = (key: string = '') => {
    setSearchKey(key);
  };

  const actionHandler = async ({
    action,
    data,
  }: {
    action: string;
    data: { id: string; idKeycloak: string; otp?: string };
  }) => {
    // {action: 'impersonate', data: {id: props.workspace.id, idKeycloak: props.workspaceUser.id}}
    // impersonateUser({ baseUrl: boot.appServicesBaseUrl, ...data, ...realm });
    if (action === 'impersonate') {
      return await impersonateUserGET({
        baseUrl: boot.appServicesBaseUrl,
        workspaceRealm: currentWorkspace.realm,
        ...data,
        ...realm,
      });
    }

    if (action === 'otpsend') {
      impersonateUserOTPSend({
        baseUrl: boot.appServicesBaseUrl,
        ...data,
      });
    }
  };

  return (
    <ScreenWrapper>
      <TitleToolbar title="Utenti del workspace">
        <ScreenToolbarToolsComposite>
          <ScreenToolbarToolsComposite.AddButton />
          <ScreenToolbarToolsComposite.SimpleSearchBox
            handleSearch={handleSearchByKey}
          />
        </ScreenToolbarToolsComposite>
        {/* <ScreenToolbarTools /> */}
      </TitleToolbar>
      <WSUsersContext.Provider value={{ actionHandler }}>
        <WorkspaceUsersManagement searchKey={searchKey} />
      </WSUsersContext.Provider>
    </ScreenWrapper>
  );
};

export function useWSUsersContext() {
  const ctx = useContext(WSUsersContext);
  if (!ctx) throw new Error('WSUsersContext cannot be undefined');
  return ctx;
}

export default WSUsers;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CatalogIndex from '../features/catalog/CatalogIndex';
import CatalogItem from '../features/catalog/Item';
import CatalogForm from '../features/catalog/Form';
import { EDIT, NEW } from '../lib/config';

const Catalog = () => {
  return (
    <Routes>
      <Route path="/" element={<CatalogIndex />} />
      <Route path="/:id" element={<CatalogItem />} />
      <Route path={`/${NEW}`} element={<CatalogForm />} />
      <Route path={`/${EDIT}/:id`} element={<CatalogForm />} />
    </Routes>
  );
};

export default Catalog;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import CatalogItem from '../features/catalog/Item';
import JobRoutes from '../features/jobs';
import Catalog from '../pages/Catalog';
import HomePage from '../pages/HomePage';
import Login from '../pages/Login';
import Permissions from '../pages/Permissions';
import Users from '../pages/Users';
import Workspace from '../pages/Workspace';
import Workspaces from '../pages/Workspaces';
import WorkspacesManagement from '../pages/WorkspacesManagement';
import WorkspaceUsersManagement from '../pages/WorkspaceUsersManagement';
import RequireAuth from '../utilities/RequireAuth';
import Form from '../features/users/Form';

export const AppRouter = () => {
  const { initialized } = useKeycloak();
  if (!initialized) {
    return <h3>Loading ... !!!</h3>;
  }
  return (
    <Routes>
      <Route
        index
        element={
          <RequireAuth>
            {/* <Login /> */}
            <Workspaces />
          </RequireAuth>
        }
      />
      <Route
        path="home"
        element={
          <RequireAuth>
            <HomePage />
          </RequireAuth>
        }
      />
      <Route
        path="workspaces"
        element={
          <RequireAuth>
            <WorkspacesManagement />
          </RequireAuth>
        }
      />
      <Route
        path="workspace"
        element={
          <RequireAuth>
            <Workspace />
          </RequireAuth>
        }
      />
      <Route
        path="workspaceUsers"
        element={
          <RequireAuth>
            <WorkspaceUsersManagement />
          </RequireAuth>
        }
      />
      {/* <Route
        path="addUser"
        element={
          <RequireAuth>
            <WorkspaceRoute>
              <WorkspaceUserFormPage />
            </WorkspaceRoute>
          </RequireAuth>
        }
      /> */}
      <Route
        path="users/*"
        element={
          <RequireAuth>
            <Users />
          </RequireAuth>
        }
      />
      {/* <Route
        path="ws/:idWorkspace/groups/*"
        element={
          <RequireAuth>
            <Groups />
          </RequireAuth>
        }
      /> */}
      <Route
        path="workspace/:idWorkspace/apps/:id"
        element={
          <RequireAuth>
            <CatalogItem />
          </RequireAuth>
        }
      />
      <Route
        path="catalog/*"
        element={
          <RequireAuth>
            <Catalog />
          </RequireAuth>
        }
      />
      <Route
        path="ws/*"
        element={
          <RequireAuth>
            <Workspaces />
          </RequireAuth>
        }
      />
      <Route
        path="permissions/*"
        element={
          <RequireAuth>
            <Permissions />
          </RequireAuth>
        }
      />
      <Route
        path="jobs/*"
        element={
          <RequireAuth>
            <JobRoutes />
          </RequireAuth>
        }
      />
      <Route
        path="account/:id"
        element={
          <RequireAuth>
            <Form myAccount={true} />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { EDIT, NEW } from '../lib/config';
import All from '../features/permissions/All';
import Item from '../features/permissions/Item';
import Form from '../features/permissions/Form';

const Permissions = () => {
  return (
    <Routes>
      <Route path="/" element={<All />} />
      <Route path="/:id" element={<Item />} />
      <Route path={`/:id/${EDIT}`} element={<Form />} />
    </Routes>
  );
};

export default Permissions;

import React from 'react';
import { ScreenWrapper } from '../../components/layouts/AppLayout';
import { useCrumbs } from '../../lib/useCrumbs';
import WorkspacesManagement from '../../pages/WorkspacesManagement';

const WSIndex = () => {
  useCrumbs([{ label: 'Workspaces' }]);
  return (
    <ScreenWrapper>
      <WorkspacesManagement />
    </ScreenWrapper>
  );
};

export default WSIndex;

import { Formik } from 'formik';
import { t } from 'i18next';
import { get, pick } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar, Button, Input, Switch } from 'requiro-ui';
import { Box, Divider, FormControlLabel, Grid } from '@mui/material';
import { ScreenWrapper } from '../../components/layouts/AppLayout';
import MessageBox from '../../components/MessageBox';
import TitleToolbar from '../../components/TitleToolbar';
import { editUserArchiva } from '../../lib/mutations';
import { fetchUserArchiva } from '../../lib/queries';
import { useCrumbs } from '../../lib/useCrumbs';
import {
  createAvatarChars,
  extractMutationErrorMessage,
} from '../../lib/utils';
import { UserManagementContext } from '../../pages/UserManagement';

const _initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  enabled: false,
};
interface Iprops {
  myAccount?: Boolean;
}

const Form = (props: Iprops) => {
  const [initialValues, seTinitialValues] = useState(_initialValues);
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { actionHandler } = React.useContext(UserManagementContext);
  // query that runs conditionally
  const query = fetchUserArchiva();
  const mutation = editUserArchiva({
    onError: (error) => {
      enqueueSnackbar(extractMutationErrorMessage(error), {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar(t('res.saveOK'), { variant: 'success' });
      navigate('/users');
    },
  });

  useEffect(() => {
    if (query.isSuccess) {
      const data = get(query, 'data.data.data', initialValues);
      const partialData = pick(data, [
        'firstName',
        'lastName',
        'email',
        'enabled',
      ]);

      seTinitialValues({ ...initialValues, ...partialData });
    }
  }, [query.status]);

  useCrumbs([
    { to: '/users', label: 'Gestione Utenti Archiva' },
    {
      label:
        params.id && !props.myAccount
          ? 'modifica utente'
          : props.myAccount
          ? 'Il mio account'
          : 'crea utente',
    },
  ]);

  return (
    <ScreenWrapper>
      <TitleToolbar
        title={
          params.id && !props.myAccount
            ? 'Modifica utente START'
            : props.myAccount
            ? 'Modifica il mio account'
            : 'Crea utente START'
        }
      />
      {query.isLoading ? (
        <MessageBox.Loading />
      ) : query.isError ? (
        <MessageBox.QueryError query={query} />
      ) : (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values) => {
            const data = get(query, 'data.data.data', {});
            const user = { ...data, ...values };
            mutation.mutate(user);
          }}
        >
          {({ handleSubmit, values, handleChange, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                {params.id ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Avatar
                        alt={createAvatarChars(initialValues)}
                        src=""
                        variant="round"
                      />
                      <Button
                        variant="outlined"
                        type="submit"
                        size="small"
                        color="secondary"
                        style={{ marginLeft: '1rem' }}
                        onClick={() =>
                          actionHandler({
                            data: { id: params.id },
                            action: 'resetpsw',
                          })
                        }
                      >
                        Reset password
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider variant="middle" />
                    </Grid>
                  </>
                ) : null}
                <Grid item xs={6}>
                  <Input
                    fullWidth
                    label="Nome"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    fullWidth
                    label="Cognome"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    fullWidth
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    disabled={params.id && !props.myAccount}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    fullWidth
                    label="Ruolo"
                    name="role"
                    value={values.role}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <FormControlLabel
                      sx={{ ml: '8px', mr: '8px' }}
                      control={
                        <Switch
                          size="small"
                          checked={values.enabled}
                          color="secondary"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue('enabled', e.target.checked)
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={
                        <span style={{ marginLeft: '16px' }}>
                          {`Utente attivo: ${values.enabled ? 'SI' : 'NO'}`}
                        </span>
                      }
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Button variant="contained" type="submit" size="small">
                    Conferma
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      )}
    </ScreenWrapper>
  );
};

export default Form;

import React from 'react';
import { useParams } from 'react-router-dom';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { styled } from '@mui/system';
import { ScreenWrapper } from '../../components/layouts/AppLayout';
import MessageBox from '../../components/MessageBox';
import TitleToolbar from '../../components/TitleToolbar';
import { useCrumbs } from '../../lib/useCrumbs';
import { useJob } from './helpers/queries';

const Input = styled('input')({
  display: 'none',
});

const Job = () => {
  useCrumbs([{ to: '/jobs', label: 'Job Manager' }, { label: 'Job' }]);
  const params = useParams();
  const query = useJob(params.id);

  console.log({ query });

  return (
    <ScreenWrapper>
      <TitleToolbar title="Dettaglio job" />
      {query.data ? (
        <MessageBox.Info>dati caricati</MessageBox.Info>
      ) : query.isLoading ? (
        <MessageBox.Loading />
      ) : (
        <MessageBox.QueryError query={query} />
      )}
    </ScreenWrapper>
  );
};

export default Job;

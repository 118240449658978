import { Paper, Box } from '@mui/material';
import { styled } from '@mui/system';

export const GridItemWrapper = styled('div')(({ theme }) => ({
  width: '25%',
  padding: theme.spacing(1),
}));

export const View = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minHeight: '280px',
  padding: theme.spacing(3),

  marginBottom: theme.spacing(3),
  _outline: '1px solid red',
}));

export const ListItemWrapper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const BoxModalWrapper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  bgcolor: 'background.paper',
  color: '#1A416B',
  outline: '2px solid #1A416B',
  borderRadius: '12px',
  boxShadow: '24',
  borderTop: '0',
  overflow: 'hidden',
}));

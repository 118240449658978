import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { ScreenWrapper } from '../../../components/layouts/AppLayout';
import MessageBox from '../../../components/MessageBox';
import ScreenToolbar from '../../../components/ScreenToolbar';
import { ScreenToolbarToolsComposite } from '../../../components/ScreenToolbarTools';
import TitleToolbar from '../../../components/TitleToolbar';
import { AppModel } from '../../../generated/axios';
import { wsActions } from '../../../lib/config';
import { useWorkspaceAppsQuery } from '../../../lib/queries';
import { useCrumbs } from '../../../lib/useCrumbs';
import { useApplicationContext } from '../../../providers/ApplicationProvider';
import { dataViewMode } from '../../../state/viewModeState';
import CatalogIndexView from '../../catalog/CatalogIndex.view';
import GridItem from './components/GridItem';
import ListItem from './components/ListItem';

const WSApps = () => {
  // const { changeViewMode, currentViewMode } = useApplicationContext();
  const [currentViewMode, changeViewMode] = useRecoilState(dataViewMode);
  const [searchKey, setSearchKey] = useState<any>('');
  const queryInfo = useWorkspaceAppsQuery();
  const { currentWorkspace } = useApplicationContext();
  const location = useLocation();
  const [apps, setApps] = useState<AppModel[]>();

  // console.log({ queryInfo });

  const name = currentWorkspace.name;

  useCrumbs([
    { to: '/ws', label: 'Workspaces' },
    { to: `/ws/${currentWorkspace.id}`, label: `${currentWorkspace.name}` },
    { label: wsActions.app },
  ]);

  useEffect(() => {
    if (queryInfo.data) {
      setApps(get(queryInfo, 'data.data.data', []));
    }
  }, [queryInfo.isSuccess]);

  if (queryInfo.isLoading) {
    return <MessageBox.Loading />;
  }
  if (queryInfo.isError) {
    return <MessageBox.QueryError query={queryInfo} />;
  }

  // const { data: apps } = queryInfo!.data!.data!;

  const filteredData =
    apps && searchKey
      ? apps.filter(
          ({ name }: { name: string }) =>
            name.toLowerCase().indexOf(searchKey.toLowerCase()) !== -1,
        )
      : apps;

  return (
    <ScreenWrapper>
      <TitleToolbar title="Applicazioni del workspace">
        <ScreenToolbarToolsComposite.AddButton label="Aggiungi app" />
        {/* <ScreenToolbarToolsComposite.Grid action={changeViewMode} />
        <ScreenToolbarToolsComposite.List action={changeViewMode} /> */}
        {apps && (
          <>
            <ScreenToolbarToolsComposite.ToggleViewButton
              currentViewMode={currentViewMode}
              action={changeViewMode}
            />
            {/* <ScreenToolbarToolsComposite.SearchBox
          options={filteredData}
          setSearchKey={setSearchKey}
          searchKey={searchKey}
        /> */}
            <ScreenToolbarToolsComposite.SearchBoxManual
              options={filteredData}
              handleSearch={setSearchKey}
              // searchKey={searchKey}
            />
          </>
        )}
        {/* <ScreenToolbarTools
          setSearchKey={setSearchKey}
          setViewMode={changeViewMode}
          searchKey={searchKey}
          options={filteredData}
        /> */}
      </TitleToolbar>
      {isEmpty(apps) ? (
        <Link to="new">
          <MessageBox.Info>
            Non ci sono app in questo workspace. Aggiungine una.
          </MessageBox.Info>
        </Link>
      ) : (
        <CatalogIndexView
          data={filteredData}
          viewMode={currentViewMode}
          GridItem={GridItem}
          ListItem={ListItem}
          children={undefined}
        />
      )}
    </ScreenWrapper>
  );
};

export default WSApps;

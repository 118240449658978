import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Form from '../../catalog/Form';
import Item from '../../catalog/Item';
import AppSelector from './components/AppSelector';
import WSApps from './WSApps';
import KissflowCustomers from './components/kissflow/KissflowCustomers';
import KissflowClasses from './components/kissflow/KissflowClasses';
import KissflowClassConfiguration from './components/kissflow/KissflowClassConfiguration';
import KissflowSignConfiguration from './components/kissflow/KissflowSignConfiguration';
import KissflowClassConfigurationAdd from './components/kissflow/KissflowClassConfigurationAdd';
import KissflowSignConfigurationAdd from './components/kissflow/KissflowSignConfigurationAdd';
import KissflowClassSubscriptions from './components/kissflow/KissflowClassSubscriptions';
import KissflowClassAudit from './components/kissflow/KissflowClassAudit';
import KissflowClassAuditDetail from './components/kissflow/KissflowClassAuditDetail';

const AppsRouter = () => {
  return (
    <Routes>
      <Route path="" element={<WSApps />} />
      <Route path=":id" element={<KissflowCustomers />} />
      <Route path=":id/customer/:customerCode" element={<KissflowClasses />} />
      <Route
        path=":id/customer/:customerCode/configuration/:classId"
        element={<KissflowClassConfiguration />}
      />
      <Route
        path=":id/customer/:customerCode/signconfiguration/:configid"
        element={<KissflowSignConfiguration />}
      />
      <Route
        path=":id/customer/:customerCode/configuration/add"
        element={<KissflowClassConfigurationAdd />}
      />
      <Route
        path=":id/customer/:customerCode/signconfiguration/add"
        element={<KissflowSignConfigurationAdd />}
      />
      <Route
        path=":id/customer/:customerCode/subscription/:classId"
        element={<KissflowClassSubscriptions />}
      />
      <Route
        path=":id/customer/:customerCode/history/:classId"
        element={<KissflowClassAudit />}
      />
      <Route
        path=":id/customer/:customerCode/history/:classId/detail/:configId"
        element={<KissflowClassAuditDetail />}
      />
      {/* <Route path="edit/:id" element={<Form />} /> */}
      <Route path="new" element={<AppSelector />} />
    </Routes>
  );
};

export default AppsRouter;

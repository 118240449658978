import React from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { TableCell, TableRow } from 'requiro-ui';

import { ConfigurationAudit } from '../../../../../../types';

type KissflowClassSingleAuditProps = {
  row: any;
  onClick: Function;
};

const KissflowClassSingleAudit = ({
  row,
  onClick,
}: KissflowClassSingleAuditProps) => {
  return (
    <TableRow
      hover={true}
      tabIndex={-1}
      onClick={() => onClick(row as ConfigurationAudit)}
      sx={{
        cursor: 'pointer',
      }}
    >
      <TableCell align="left">{row.userId}</TableCell>
      <TableCell align="left">
        {new Date(Number(row.lastModifyDate) * 1000).toLocaleString()}
      </TableCell>
      <TableCell align="left">{row.version}</TableCell>
      <TableCell align="right">
        <KeyboardArrowRightIcon
          style={{ bottom: '-3px', position: 'relative' }}
        />
      </TableCell>
    </TableRow>
  );
};

export default KissflowClassSingleAudit;

import React from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { Divider, Theme, Toolbar } from '@mui/material';
import { styled } from '@mui/system';
import WSNav from '../../features/ws/WSNav';
import { useNavContext } from '../../providers/NavProvider';
import AppCrumbs from '../AppCrumbs';
import UserMenu from '../UserMenu';
import { AppBar } from './Styled';

const logoWhite = './assets/logo_white.svg';

const Logo = styled(Link)(() => ({
  display: 'flex',
  color: 'inherit',
  textDecoration: 'none',
  flexGrow: 1,
}));

const ApplicationBar = () => {
  const { toggle, open } = useNavContext();
  return (
    <AppBar
      color="secondary"
      open={open}
      sx={{
        pl: 8,
        pr: 8,
        boxShadow: 'none',
        backgroundColor: 'rgb(245 245 245)',
      }}
    >
      <Toolbar
        sx={{
          alignItems: 'center',
          backgroundColor: (theme: Theme) => theme.palette.secondary.main,
          borderEndEndRadius: '8px',
          borderEndStartRadius: '8px',
          // boxShadow: '0 4px 8px 0 rgb(56, 96, 165, 0.15)',
        }}
      >
        {/* <IconButton
          color="inherit"
          onClick={toggle}
          sx={{ mr: 2, ...(open && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton> */}
        <Logo to="/">
          <img src={logoWhite} alt="Requiro START" />
        </Logo>
        <UserMenu />
      </Toolbar>
      <div style={{ width: '82%', margin: 'auto' }}>
        <AppCrumbs />
      </div>
      <Divider component="div" sx={{ my: 1, borderColor: 'transparent' }} />
      <WSNav />
    </AppBar>
  );
};

export default ApplicationBar;

import { create, get } from 'lodash';
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { t } from 'i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { Paper } from '@mui/material';
import Add from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';
import * as XLSX from 'xlsx';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Input,
  Select,
  MenuItem,
  ButtonLabel,
  Box as RequiroBox,
  Button,
} from 'requiro-ui';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Alert from '@mui/material/Alert';

import { ScreenWrapper } from '../../../../../components/layouts/AppLayout';
import MessageBox from '../../../../../components/MessageBox';
import TitleToolbar from '../../../../../components/TitleToolbar';
import SaveBar from '../../components/Savebar';
import {
  useSingleAppQuery,
  useClassesByCustomerCodeQuery,
  useSingleCustomerQuery,
  useConfigurationByClassname,
  useSubscriptionTypesQuery,
  fetchSubscriptions,
  useClassPropertiesQuery,
} from '../../../../../lib/queries';
import { useCrumbs } from '../../../../../lib/useCrumbs';
import { useApplicationContext } from '../../../../../providers/ApplicationProvider';
import {
  SubscriptionRow,
  Subscription,
  SubscriptionExtra,
} from '../../../../../../types';
import { useAppInitiator } from '../../../../../providers/AppInitiator';
import {
  editSubscription,
  addSubscription,
} from '../../../../../lib/mutations';

function isValidDate(dateString) {
  // First check for the pattern
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateString.match(regEx)) return false; // Invalid format
  var d = new Date(dateString);
  var dNum = d.getTime();
  if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0, 10) === dateString;
}

interface HeadCell {
  id: keyof SubscriptionRow;
  label: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'unique',
    label: 'ID',
  },
  {
    id: 'kissflow_field',
    label: 'Kissflow Data Model',
  },
  {
    id: 'requiro_field',
    label: 'Metadati Requiro',
  },
  {
    id: 'update',
    label: 'Update',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'rule',
    label: 'Condition',
  },
  {
    id: 'ruleValue',
    label: '',
  },
];

const rules_options = [
  { label: '>', value: '>' },
  { label: '>=', value: '>=' },
  { label: '<', value: '<' },
  { label: '<=', value: '<=' },
  { label: '=', value: '=' },
  { label: '!=', value: '!=' },
  { label: 'Starts with', value: 'startsWith' },
  { label: 'Ends with', value: 'endsWith' },
  { label: 'Contains', value: 'contains' },
  { label: 'Is Null', value: 'isNull' },
  { label: 'Is Not Null', value: 'isNotNull' },
];

const operators_options = [
  { label: 'AND', value: 'AND' },
  { label: 'OR', value: 'OR' },
  { label: 'NOT', value: 'NOT' },
];

const defaultExtraFields = [
  {
    requiro_field: 'branch',
    rule: rules_options[4].value,
    ruleValue: null,
    type: 'text',
    operator: 'AND',
  },
  {
    requiro_field: 'sub_branch',
    rule: rules_options[4].value,
    ruleValue: null,
    type: 'text',
    operator: 'AND',
  },
  {
    requiro_field: 'year',
    rule: rules_options[0].value,
    ruleValue: null,
    type: 'number',
    operator: 'AND',
  },
  {
    requiro_field: 'multirecord',
    rule: rules_options[4].value,
    ruleValue: false,
    type: 'boolean',
    operator: 'AND',
  },
];

const isUnique = (f) => {
  return f && f !== 'null' && f !== 'undefined';
};

const genRandom = (index: number) =>
  Math.floor(Math.random() * 1000000000) + index;

const formatDate = (string: string) => {
  const d = new Date(string);
  if (!d) return null;

  let day: number | string = d.getDate();
  if (day < 10) day = `0${day}`;

  let m = (d.getMonth() - 1).toString();
  if (parseInt(m, 10) < 10) m = `0${m}`;
  return `${d.getFullYear()}-${m}-${day}`;
};

const KissflowClassSubscriptions = () => {
  const createValue = 1;
  const updateValue = 2;

  const { boot } = useAppInitiator();
  const { enqueueSnackbar } = useSnackbar();
  const queryInfoApp = useSingleAppQuery();
  const { currentWorkspace } = useApplicationContext();
  const navigate = useNavigate();
  const params = useParams();
  const { customerCode, classId } = params;
  const queryInfoCustomer = useSingleCustomerQuery(customerCode);

  const queryInfoSubscriptionTypes = useSubscriptionTypesQuery();
  const queryInfo = useClassesByCustomerCodeQuery(customerCode);
  const queryInfoConfiguration = useConfigurationByClassname(
    customerCode,
    classId,
  );
  const queryInfoClassProperties = useClassPropertiesQuery(
    customerCode,
    classId,
  );

  const [subscriptions, handleSubscriptions] =
    React.useState<Subscription[]>(null);
  const [subscriptionId, handleSubscriptionId] = React.useState<string>(null);
  const [triggerCriteria, handleTriggerCriteria] = React.useState<string>('');
  const [checkFilter, handleCheckFilter] = React.useState<number>(1);
  const [importHeader, handleImportHeader] = React.useState<number>(1);
  const [saveLoading, handleSaveLoading] = React.useState<boolean>(false);
  const [isFetch, handleFetch] = React.useState<boolean>(false);
  const [importWarning, handleImportWarning] = React.useState<boolean>(false);
  const [errors, handleErrors] = React.useState({
    [createValue]: {},
    [updateValue]: {},
    extraFields: {
      branch: false,
      sub_branch: false,
      year: false,
      multirecord: false,
    },
  });
  const [extraFields, handleExtraFields] = React.useState(defaultExtraFields);

  const app = get(queryInfoApp, 'data.data.data', []);
  const customer = get(queryInfoCustomer, 'data.data.data', null);
  const configurationBase = get(queryInfoConfiguration, 'data.data.data', null);
  const subscriptionTypes = get(
    queryInfoSubscriptionTypes,
    'data.data.data',
    null,
  );
  const classes = get(queryInfo, 'data.data.data', []).filter(
    (el) =>
      (el.classname === classId && el.customercode === customerCode) ||
      (el.classname === classId && el.customercode === '000000'),
  );
  const classProperties = get(
    queryInfoClassProperties,
    'data.data.data',
    [],
  ).map((el) => ({ label: el.label, value: el.propertyname, type: el.type }));

  const configuration =
    classes.length > 0 && configurationBase
      ? { ...configurationBase[0], label: classes[0].label }
      : null;

  const addSub = addSubscription({});
  const editSub = editSubscription({});

  const onImportTrigger = () => {
    document.getElementById('import_file').click();

    if (importWarning) handleImportWarning(false);
  };

  const processExcel = (data) => {
    const workbook = XLSX.read(data, {
      type: 'binary',
    });

    return JSON.parse(to_json(workbook));
  };

  const to_json = (workbook) => {
    const result = [];
    workbook.SheetNames.forEach(function (sheetName) {
      const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
        header: 1,
      });
      if (roa.length) result.push(roa);
    });
    return JSON.stringify(result.flat(Infinity));
  };

  const onImport = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      const fileformat = file.name.split('.').pop();

      if (!['csv', 'xls', 'xlsx'].includes(fileformat)) {
        enqueueSnackbar(t('errors.forma', 'Incorrect file format'), {
          variant: 'error',
        });
        return;
      }

      reader.onload = (e) => {
        const fullXLS = processExcel(e.target.result);
        const contents = processExcel(e.target.result);
        if (importHeader === 1) {
          contents.shift();
        }
        composeSubscription(contents, fullXLS);
      };
      reader.readAsBinaryString(file);
    }
  };

  const onChangeFilter = (val: number) => {
    handleCheckFilter(val);
    if (val === 1) {
      const newSubscriptions = subscriptions
        ? JSON.parse(JSON.stringify(subscriptions)).map((s: Subscription) => {
            s.fieldsMapping = s.fieldsMapping.map((f: SubscriptionRow) => {
              f.update = false;
              return f;
            });
            return s;
          })
        : null;

      handleSubscriptions(newSubscriptions);
    }
  };

  const onChangeImportHeader = (val: number) => {
    handleImportHeader(val);
  };

  const checkTypeOk = (field: SubscriptionRow) => {
    const isTypeString = field.type === 'STRING' || field.type === 'LONGSTRING';
    const isTypeInt = field.type === 'INTEGER';
    const isTypeFloat = field.type === 'FLOAT';

    let isTypeOk = true;
    if (isTypeString && typeof field.ruleValue !== 'string') {
      isTypeOk = false;
    }

    if (isTypeInt) {
      if (field.ruleValue.indexOf('.') !== -1) {
        isTypeOk = false;
      } else if (
        !Number.isFinite(parseInt(field.ruleValue, 10)) &&
        !Number.isInteger(parseInt(field.ruleValue, 10))
      ) {
        isTypeOk = false;
      }
    }

    if (isTypeFloat && !Number.isFinite(parseFloat(field.ruleValue))) {
      isTypeOk = false;
    }
    return isTypeOk;
  };

  const checkErrors = () => {
    let isError = false;
    const resErrors = JSON.parse(JSON.stringify(errors));
    if (!subscriptions || subscriptions.length === 0) {
      isError = true;
    } else {
      subscriptions.map((s) => {
        s.fieldsMapping.map((f) => {
          if (f.kissflow_field === '') {
            if (!resErrors[s.subscriptionTypeId][f.date]) {
              resErrors[s.subscriptionTypeId][f.date] = {
                kissflow_field: true,
              };
            } else {
              resErrors[s.subscriptionTypeId][f.date].kissflow_field = true;
            }

            isError = true;
          }

          if (f.ruleValue) {
            if (!checkTypeOk(f)) {
              if (!resErrors[s.subscriptionTypeId][f.date]) {
                resErrors[s.subscriptionTypeId][f.date] = {
                  ruleValue: true,
                };
              } else {
                resErrors[s.subscriptionTypeId][f.date].ruleValue = true;
              }
              isError = true;
            }
          }
        });
      });
    }

    if (isError) handleErrors(resErrors);

    return !isError;
  };

  const onSave = async () => {
    handleSaveLoading(true);

    if (subscriptions && checkErrors()) {
      const clonedSubscriptions = JSON.parse(JSON.stringify(subscriptions));

      if (clonedSubscriptions.length > 0) {
        const sub = clonedSubscriptions[0];
        const uniques = sub.fieldsMapping
          .map((el: SubscriptionRow) => (el.unique ? el.requiro_field : null))
          .filter((el: string) => el);
        const subToSave: any = {
          id: subscriptionId || null,
          configurationId: configuration.id,
          subscriptionTypeId: checkFilter,
          rules: triggerCriteria,
          fieldsMapping: JSON.stringify(
            sub.fieldsMapping.map((el: SubscriptionRow) => ({
              requiro_field: el.requiro_field,
              kissflow_field: el.kissflow_field,
              rule: `${el.rule} ${el.ruleValue}###${el.operator}`,
              type: el.type,
              update: el.update,
            })),
          ),
          // fieldsUnique: uniques.length > 0 ? JSON.stringify(uniques) : null,
          extraFields: JSON.stringify(
            extraFields.map((ef) => ({
              requiro_field: ef.requiro_field,
              rule: `${ef.rule} ${ef.ruleValue}###${ef.operator}`,
            })),
          ),
        };

        if (uniques.length > 0) {
          subToSave.fieldsUnique = JSON.stringify(uniques);
        }

        if (!subToSave.id) {
          delete subToSave.id;
          try {
            await addSub.mutateAsync(subToSave);
            enqueueSnackbar(t('res.saveOK'), {
              variant: 'success',
            });
          } catch (error) {
            enqueueSnackbar(t('errors.generic'), { variant: 'error' });
          }
        } else {
          try {
            const editSubsToSave = JSON.parse(JSON.stringify(subToSave));
            editSubsToSave.fieldsMapping = encodeURIComponent(
              editSubsToSave.fieldsMapping,
            );

            if (subToSave.fieldsUnique) {
              editSubsToSave.fieldsUnique = encodeURIComponent(
                editSubsToSave.fieldsUnique,
              );
            }

            editSubsToSave.extraFields = encodeURIComponent(
              editSubsToSave.extraFields,
            );
            await editSub.mutateAsync(editSubsToSave);
            enqueueSnackbar(t('res.saveOK'), {
              variant: 'success',
            });
          } catch (error) {
            enqueueSnackbar(t('errors.generic'), { variant: 'error' });
          }
        }
      }

      handleSaveLoading(false);
    } else {
      handleSaveLoading(false);
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  const composeSubscription = (fields, allFields) => {
    if (configuration) {
      const newSubscriptions = [
        {
          subscriptionTypeId: checkFilter,
          fieldsMapping: fields.map((field, i) => {
            const r =
              classProperties[0] &&
              classProperties[0].type &&
              getType(classProperties[0].type) === 'text'
                ? rules_options[4].value
                : rules_options[0].value;
            return {
              kissflow_field: field,
              requiro_field: classProperties[0]
                ? classProperties[0].value
                : null,
              type: classProperties[0] ? classProperties[0].type : null,
              update: false,
              unique: false,
              date: genRandom(i),
              rule: r,
              ruleValue: null,
              operator: 'AND',
            };
          }),
          fieldsUnique: [],
          rules: '',
        },
      ];

      handleSubscriptions(newSubscriptions);

      if (fields.length < allFields.length) {
        handleImportWarning(true);
      }
    }
  };

  const onAddSubscription = () => {
    if (configuration) {
      let clonedSubscriptions = subscriptions
        ? JSON.parse(JSON.stringify(subscriptions))
        : [
            {
              subscriptionTypeId: checkFilter,
              fieldsMapping: [],
              fieldsUnique: [],
              rules: '',
            },
          ];

      clonedSubscriptions = clonedSubscriptions.map((s, i) => {
        const r =
          classProperties[0] &&
          classProperties[0].type &&
          getType(classProperties[0].type) === 'text'
            ? rules_options[4].value
            : rules_options[0].value;
        s.subscriptionTypeId = checkFilter;
        s.fieldsMapping.push({
          kissflow_field: '',
          requiro_field: classProperties[0] ? classProperties[0].value : null,
          type: classProperties[0] ? classProperties[0].type : null,
          update: false,
          unique: false,
          date: genRandom(i),
          rule: r,
          ruleValue: null,
          operator: 'AND',
        });
        return s;
      });

      handleSubscriptions(clonedSubscriptions);

      if (importWarning) handleImportWarning(false);
    }
  };

  const onExtraFieldChange = (
    requiro_field: string,
    key: string,
    value: any,
  ) => {
    let clonedExtraFields = JSON.parse(JSON.stringify(extraFields));
    clonedExtraFields = clonedExtraFields.map((e) => {
      if (e.requiro_field === requiro_field) e[key] = value;
      return e;
    });
    handleExtraFields(clonedExtraFields);

    const clonedErrors = JSON.parse(JSON.stringify(errors));
    clonedErrors.extraFields[requiro_field] = false;
    handleErrors(clonedErrors);
  };

  const onRowChange = (
    index: number,
    key: string,
    value: any,
    type?: string,
  ) => {
    let clonedSubscriptions = JSON.parse(JSON.stringify(subscriptions));

    if (key === 'delete') {
      clonedSubscriptions = clonedSubscriptions.map((s) => {
        s.fieldsMapping = s.fieldsMapping.filter((el) => el.date !== index);
        return s;
      });
    } else {
      clonedSubscriptions = clonedSubscriptions.map((s: Subscription) => {
        s.fieldsMapping = s.fieldsMapping.map((el: SubscriptionRow) => {
          if (el.date === index) {
            el[key] = value;
            if (type && type === 'date') {
              el[key] =
                value && isValidDate(formatDate(value))
                  ? formatDate(value)
                  : null;
            }

            if (key === 'requiro_field') {
              if (type) el.type = type;
              el.rule =
                getType(type) !== 'text'
                  ? rules_options[0].value
                  : rules_options[4].value;
              el.ruleValue = null;
            }

            const isRuleValueEmpty =
              value === 'isNull' || value === 'isNotNull';
            if (key === 'rule' && isRuleValueEmpty) {
              el['ruleValue'] = null;
            }
          }
          return el;
        });
        return s;
      });
    }

    if (key === 'kissflow_field') {
      if (
        errors &&
        errors[checkFilter] &&
        errors[checkFilter][index] &&
        errors[checkFilter][index].kissflow_field
      ) {
        const clonedErrors = JSON.parse(JSON.stringify(errors));
        clonedErrors[checkFilter][index].kissflow_field = false;
        handleErrors(clonedErrors);
      }
    }

    if (key === 'ruleValue') {
      if (
        errors &&
        errors[checkFilter] &&
        errors[checkFilter][index] &&
        errors[checkFilter][index].ruleValue
      ) {
        const clonedErrors = JSON.parse(JSON.stringify(errors));
        clonedErrors[checkFilter][index].ruleValue = false;
        handleErrors(clonedErrors);
      }
    }

    handleSubscriptions(clonedSubscriptions);

    if (importWarning) handleImportWarning(false);
  };

  useCrumbs([
    { to: '/ws', label: 'Workspaces' },
    { to: `/ws/${currentWorkspace.id}`, label: `${currentWorkspace.name}` },
    { to: `/ws/${currentWorkspace.id}/apps`, label: `App` },
    {
      to: `/ws/${currentWorkspace.id}/apps/${app && app.id ? app.id : ''}`,
      label: app && app.name ? app.name : '',
    },
    {
      to: `/ws/${currentWorkspace.id}/apps/${
        app && app.id ? app.id : ''
      }/customer/${customerCode || ''}`,
      label: customer
        ? `${customer.CompanyName}`
        : customerCode
        ? customerCode
        : '',
    },
    {
      label: t('subscription', 'Subscription'),
    },
  ]);

  const fetchData = async () => {
    const subsRes = await fetchSubscriptions(
      boot.appKissflowServicesBaseUrl,
      configuration.id,
    );

    const subs = subsRes ? subsRes.data.data : null;

    if (subs) {
      const stateSubs = subs.map((el) => {
        return {
          fieldsMapping: JSON.parse(el.fieldsMapping).map((e, i) => {
            const [r, ...rV] =
              e.rule.indexOf('###') !== -1
                ? e.rule.split('###')[0].split(' ')
                : e.rule.split(' ');
            const ruleOperator =
              e.rule.indexOf('###') !== -1 ? e.rule.split('###')[1] : 'AND';

            const val = rV.join(' ');
            const ruleValue = val && val !== 'null' ? val : null;

            e.date = genRandom(i);
            e.unique = isUnique(el.fieldsUnique)
              ? JSON.parse(el.fieldsUnique).filter((u) => u === e.requiro_field)
                  .length > 0
              : false;
            e.rule = r || rules_options[0].value;
            e.ruleValue = ruleValue;
            e.operator = ruleOperator;
            e.type = e.type || '';
            return e;
          }),
          fieldsUnique: isUnique(el.fieldsUnique)
            ? JSON.parse(el.fieldsUnique)
            : null,
          rules: el.rules,
          subscriptionTypeId: el.subscriptionTypeId,
          configurationId: el.configurationId,
          id: el.id,
        };
      });

      if (subs && subs[0] && subs[0].extraFields) {
        const extra = JSON.parse(subs[0].extraFields).map(
          (e: SubscriptionExtra) => {
            const [r, ...rV] =
              e.rule.indexOf('###') !== -1
                ? e.rule.split('###')[0].split(' ')
                : e.rule.split(' ');
            const ruleOperator =
              e.rule.indexOf('###') !== -1 ? e.rule.split('###')[1] : 'AND';

            const val = rV.join(' ');
            const ruleValue = val && val !== 'null' ? val : null;

            return {
              requiro_field: e.requiro_field,
              rule: r,
              ruleValue: ruleValue,
              operator: ruleOperator,
              type: defaultExtraFields.filter(
                (d) =>
                  d.requiro_field === e.requiro_field ||
                  (d.requiro_field === 'sub_branch' &&
                    e.requiro_field === 'subbranch'),
              )[0].type,
            };
          },
        );

        handleExtraFields(extra);
      }

      handleSubscriptions(stateSubs);
      if (stateSubs.length > 0) {
        handleSubscriptionId(stateSubs[0].id);
        handleTriggerCriteria(stateSubs[0].rules);
        handleCheckFilter(stateSubs[0].subscriptionTypeId);
      }
    }
  };

  const cleanTriggers = (trigger: string) => {
    const triggersArray = trigger.trim().split(' ');

    if (
      triggersArray[triggersArray.length - 1] === 'AND' ||
      triggersArray[triggersArray.length - 1] === 'OR' ||
      triggersArray[triggersArray.length - 1] === 'NOT'
    ) {
      triggersArray.pop();
    }

    return triggersArray.join(' ');
  };

  const composeTriggersCriteria = (
    fields: SubscriptionRow[],
    extra: SubscriptionExtra[],
  ) => {
    let criteria = '';
    fields.map((f) => {
      const isNullCheck =
        f.kissflow_field &&
        !f.ruleValue &&
        (f.rule === 'isNull' || f.rule === 'isNotNull');

      let ruleValue = f.ruleValue ? `"${f.ruleValue}"` : '""';

      if (f.ruleValue || isNullCheck) {
        const isNotStandard =
          f.rule === 'startsWith' ||
          f.rule === 'endsWith' ||
          f.rule === 'contains' ||
          f.rule === 'isNull' ||
          f.rule === 'isNotNull';
        const isRuleValueEmpty = f.rule === 'isNull' || f.rule === 'isNotNull';

        const ruleAndValue = isNotStandard
          ? `${f.rule}(${isRuleValueEmpty ? '' : ruleValue ? ruleValue : '""'})`
          : `${f.rule} ${ruleValue || '""'}`;
        let completeCriteria = isNotStandard
          ? `${f.kissflow_field}.${ruleAndValue}`
          : `${f.kissflow_field} ${ruleAndValue}`;

        if (f.rule === 'isNull' || f.rule === 'isNotNull') {
          completeCriteria = completeCriteria
            .replace('isNull', 'isEmpty')
            .replace('isNotNull', 'isEmpty');
        }
        if (f.rule === 'isNotNull') {
          completeCriteria = `!${completeCriteria}`;
        }
        criteria += `${completeCriteria}`;
        criteria += ` ${f.operator} `;
      }
    });

    extra.map((f) => {
      if (f.ruleValue || f.ruleValue === false) {
        let ruleValue =
          f.ruleValue || f.ruleValue === false ? f.ruleValue.toString() : '""';
        if (ruleValue === 'false' || ruleValue === 'true') {
          ruleValue = ruleValue.toUpperCase();
        }

        criteria += `${f.requiro_field || '""'} ${f.rule} `;
        criteria +=
          f.type === 'text' || f.type === 'number'
            ? `"${ruleValue}"`
            : ruleValue;
        criteria += ` ${f.operator} `;
      }
    });

    handleTriggerCriteria(cleanTriggers(criteria));
  };

  const getType = (fieldType: string) => {
    switch (fieldType) {
      case 'STRING':
      case 'LONGSTRING':
      case 'ANAGRAFICA':
        return 'text';
      case 'INTEGER':
      case 'PROTOCOLLO':
      case 'FLOAT':
        return 'number';
      case 'DATE':
      case 'PROT_DATE':
        return 'date';
      default:
        return 'text';
    }
  };

  useEffect(() => {
    if (configuration && !isFetch) {
      fetchData();
      handleFetch(true);
    }
  }, [configurationBase, classes]);

  useEffect(() => {
    if (subscriptions && subscriptions.length > 0) {
      composeTriggersCriteria(subscriptions[0].fieldsMapping, extraFields);
    }
  }, [subscriptions, extraFields]);

  const renderError = () => {
    if (
      (!queryInfo.isLoading && !subscriptions) ||
      (subscriptions &&
        (subscriptions.length === 0 ||
          (subscriptions.length > 0 &&
            subscriptions[0].fieldsMapping.length === 0)))
    ) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '50px 0',
          }}
        >
          <RequiroBox type="azure" sx={{ width: '280px' }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <strong
                style={{
                  display: 'block',
                  textAlign: 'center',
                  marginBottom: '16px',
                }}
              >
                {t('importFields', 'Importazione campi')}
              </strong>

              <RadioGroup
                value={importHeader}
                onChange={(e) => {
                  onChangeImportHeader(Number(e.target.value));
                }}
              >
                <div
                  style={{
                    marginBottom: '16px',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Radio
                      value={1}
                      name="ingestionMode"
                      color="secondary"
                      size="small"
                    />
                    <span
                      style={{
                        fontFamily: 'WotfardLight',
                        marginLeft: '8px',
                        marginRight: '8px',
                        fontSize: '14px',
                      }}
                    >
                      {t('xlsWithHeader', 'Rimuovi prima riga')}
                    </span>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '-8px',
                      fontSize: '14px',
                    }}
                  >
                    <Radio
                      value={2}
                      name="ingestionMode"
                      color="secondary"
                      size="small"
                    />
                    <span
                      style={{
                        fontFamily: 'WotfardLight',
                        marginLeft: '8px',
                        marginRight: '8px',
                      }}
                    >
                      {t('xlsWithoutHeader', 'Mantieni prima riga')}
                    </span>
                  </Box>
                </div>
              </RadioGroup>

              <input
                value=""
                type="file"
                id="import_file"
                onChange={onImport}
                hidden
              />
              <Button
                onClick={onImportTrigger}
                color="primary"
                startIcon={<CloudUploadIcon />}
                variant="contained"
                disableElevation
                size="small"
              >
                {t('import', 'Importa')}
              </Button>
            </div>
          </RequiroBox>
        </div>
      );
    }
  };

  const renderBody = () => {
    if (!subscriptions) return null;
    return subscriptions.map((s) => {
      const { fieldsMapping } = s;
      return fieldsMapping.map((f: SubscriptionRow) => {
        const rules_opts =
          getType(f.type) === 'text'
            ? rules_options.filter(
                (r) =>
                  r.value === '=' ||
                  r.value === '!=' ||
                  r.value === 'startsWith' ||
                  r.value === 'endsWith' ||
                  r.value === 'contains' ||
                  r.value === 'isNull' ||
                  r.value === 'isNotNull',
              )
            : rules_options.filter(
                (r) =>
                  r.value !== 'startsWith' &&
                  r.value !== 'endsWith' &&
                  r.value !== 'contains',
              );

        const isErrorRow =
          (errors &&
            errors[checkFilter] &&
            errors[checkFilter][f.date] &&
            errors[checkFilter][f.date].kissflow_field) ||
          (errors &&
            errors[checkFilter] &&
            errors[checkFilter][f.date] &&
            errors[checkFilter][f.date].ruleValue);

        return (
          <TableRow key={f.date}>
            <TableCell
              key="unique"
              size="small"
              sx={isErrorRow ? { padding: '16px 8px' } : { padding: '6px 8px' }}
            >
              <Checkbox
                checked={f.unique}
                onChange={() => {
                  onRowChange(f.date, 'unique', !f.unique);
                }}
              />
            </TableCell>
            <TableCell
              key="kissflow_field"
              size="small"
              sx={{ padding: '6px 8px' }}
            >
              <Input
                placeholder={t('insertText', 'Inserisci testo')}
                fullWidth
                value={f.kissflow_field ? f.kissflow_field : ''}
                onChange={(e) => {
                  onRowChange(f.date, 'kissflow_field', e.target.value);
                }}
                error={
                  errors && errors[checkFilter] && errors[checkFilter][f.date]
                    ? errors[checkFilter][f.date].kissflow_field
                    : false
                }
                small
              />
              {errors &&
              errors[checkFilter] &&
              errors[checkFilter][f.date] &&
              errors[checkFilter][f.date].kissflow_field ? (
                <div
                  style={{
                    color: 'rgb(211, 47, 47)',
                    position: 'absolute',
                    fontSize: '10px',
                  }}
                >
                  {t('emptyField', 'Empty field')}
                </div>
              ) : null}
            </TableCell>
            <TableCell
              key="requiro_field"
              size="small"
              sx={{ padding: '6px 8px', width: '266px' }}
            >
              <Select
                value={f.requiro_field}
                onChange={(e) => {
                  const thisClassProp = classProperties.filter(
                    (p) => p.value === e.target.value,
                  )[0];
                  onRowChange(
                    f.date,
                    'requiro_field',
                    e.target.value,
                    thisClassProp.type,
                  );
                }}
                sx={{ width: '100%' }}
                small
                autoWidth={false}
              >
                {classProperties.map((o) => {
                  return (
                    <MenuItem key={o.value} value={o.value}>
                      {o.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </TableCell>
            <TableCell key="update" size="small" sx={{ padding: '6px 8px' }}>
              <Checkbox
                checked={f.update}
                onChange={() => {
                  onRowChange(f.date, 'update', !f.update);
                }}
                disabled={checkFilter === 1}
                sx={{
                  '& svg': {
                    color: checkFilter === 1 ? '#e1e7f2 !important' : null,
                  },
                }}
              />
            </TableCell>
            <TableCell
              key="type"
              size="small"
              sx={{ padding: '6px 8px', width: '100px' }}
            >
              {f.type}
            </TableCell>
            <TableCell
              key="rule"
              size="small"
              sx={{ padding: '6px 8px', width: '125px' }}
            >
              <Select
                value={f.rule}
                onChange={(e) => {
                  onRowChange(f.date, 'rule', e.target.value);
                }}
                sx={{ width: '100%' }}
                small
                autoWidth={false}
              >
                {rules_opts.map((o) => {
                  return (
                    <MenuItem key={o.value} value={o.value}>
                      {o.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </TableCell>
            <TableCell key="ruleValue" size="small" sx={{ padding: '6px 8px' }}>
              {getType(f.type) !== 'date' ? (
                <Input
                  type="text"
                  placeholder={t('insertText', 'Inserisci testo')}
                  fullWidth
                  value={f.ruleValue ? f.ruleValue : ''}
                  onChange={(e) => {
                    onRowChange(f.date, 'ruleValue', e.target.value);
                  }}
                  small
                  error={
                    errors && errors[checkFilter] && errors[checkFilter][f.date]
                      ? errors[checkFilter][f.date].ruleValue
                      : false
                  }
                  disabled={f.rule === 'isNull' || f.rule === 'isNotNull'}
                />
              ) : (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={f.ruleValue ? new Date(f.ruleValue) : null}
                    defaultCalendarMonth={new Date()}
                    inputFormat="yyyy-MM-dd"
                    mask="____-__-__"
                    renderInput={(params) => {
                      return (
                        <Input
                          {...params}
                          endAdornment={params.InputProps.endAdornment}
                          small
                          fullWidth
                        />
                      );
                    }}
                    onChange={(val) => {
                      onRowChange(f.date, 'ruleValue', val, 'date');
                    }}
                    disabled={f.rule === 'isNull' || f.rule === 'isNotNull'}
                  />
                </LocalizationProvider>
              )}
              {errors &&
              errors[checkFilter] &&
              errors[checkFilter][f.date] &&
              errors[checkFilter][f.date].ruleValue ? (
                <div
                  style={{
                    color: 'rgb(211, 47, 47)',
                    position: 'absolute',
                    fontSize: '10px',
                  }}
                >
                  {f.ruleValue === ''
                    ? t('emptyField', 'Empty field')
                    : t('wrongformat', 'Wrong format')}
                </div>
              ) : null}
            </TableCell>
            <TableCell
              key="operator"
              size="small"
              sx={{ padding: '6px 8px', width: '96px' }}
            >
              <Select
                value={f.operator}
                onChange={(e) => {
                  onRowChange(f.date, 'operator', e.target.value);
                }}
                sx={{ width: '100%' }}
                small
                autoWidth={false}
              >
                {operators_options.map((o) => {
                  return (
                    <MenuItem key={o.value} value={o.value}>
                      {o.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </TableCell>
            <TableCell
              key={'delete'}
              align={'right'}
              size="small"
              sx={{ padding: '6px 8px' }}
            >
              <IconButton
                onClick={() => {
                  onRowChange(f.date, 'delete', true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      });
    });
  };

  const renderHeader = () => {
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              sx={{
                width:
                  headCell.id === 'requiro_field'
                    ? '266px'
                    : headCell.id === 'rule'
                    ? '96px'
                    : 'auto',
              }}
            >
              <strong
                style={{
                  marginLeft:
                    headCell.id === 'unique'
                      ? '12px'
                      : headCell.id === 'update'
                      ? '3px'
                      : '-8px',
                }}
              >
                {headCell.label}
              </strong>
            </TableCell>
          ))}
          <TableCell
            key={'operator'}
            sx={{
              width: '96px',
            }}
          >
            <strong
              style={{
                marginLeft: '-8px',
              }}
            >
              {t('operator', 'Operator')}
            </strong>
          </TableCell>
          <TableCell
            key={'delete'}
            align={'right'}
            sx={{ padding: '0 16px 0 8px' }}
          >
            {' '}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  if (queryInfo.isLoading) {
    return <MessageBox.Loading />;
  }
  if (queryInfo.isError) {
    return <MessageBox.QueryError query={queryInfo} />;
  }

  return (
    <ScreenWrapper>
      <TitleToolbar title={t('ingestionMode', 'Modalità di Ingestion')} />
      <div style={{ marginTop: '0' }}>
        <RadioGroup
          value={checkFilter}
          onChange={(e) => {
            onChangeFilter(Number(e.target.value));
          }}
        >
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              margin: '16px -16px 0',
            }}
          >
            {subscriptionTypes &&
              subscriptionTypes.map((subType) => {
                return (
                  <div
                    style={{
                      width: '25%',
                      padding: '0 16px',
                      marginBottom: '16px',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Radio
                        value={subType.id}
                        name="ingestionMode"
                        color="secondary"
                      />
                      <span
                        style={{
                          fontFamily: 'WotfardLight',
                          marginLeft: '8px',
                          marginRight: '8px',
                        }}
                      >
                        {subType.name}
                      </span>
                      <Tooltip
                        title={t(
                          `${subType.name}-info`,
                          subType.id === 1
                            ? 'Se un documento non è presente in Kissflow procedere alla creazione'
                            : "Se un documento non è presente in Kissflow procedere alla creazione, se il documento è presente procedere all'aggiornamento dei metadati mappati per l'aggiornamento",
                        )}
                        placement="top"
                        arrow
                      >
                        <InfoOutlinedIcon
                          sx={{ fontSize: '18px', color: '#1A416B' }}
                        />
                      </Tooltip>
                    </Box>
                  </div>
                );
              })}
          </div>
        </RadioGroup>
      </div>

      <TitleToolbar title={t('subscription', 'Subscriptions')} />

      <div style={{ marginTop: '20px' }}>
        {importWarning && (
          <Alert
            variant="filled"
            severity="warning"
            sx={{ marginBottom: '16px', marginTop: '-16px' }}
          >
            {t(
              'importAlertRowsCount',
              'Il numero di righe del file e il numero delle righe importate non corrisponde.',
            )}
          </Alert>
        )}
        <TableContainer>
          <Table aria-labelledby="tableTitle" size="medium">
            {renderHeader()}
            <TableBody>{renderBody()}</TableBody>
          </Table>
        </TableContainer>
        {renderError()}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <ButtonLabel
            icon={<Add />}
            label={t('add', 'Aggiungi')}
            onClick={onAddSubscription}
          />
        </div>
      </div>

      <div style={{ marginTop: '20px' }}>
        <TitleToolbar title={t('extraFields', 'Extra Fields')} />
        <Table>
          <TableBody>
            {extraFields.map((field, i) => {
              const opts =
                field.type === 'boolean' || field.type === 'text'
                  ? rules_options.filter(
                      (r) => r.value === '=' || r.value === '!=',
                    )
                  : rules_options;

              return (
                <TableRow key={field.requiro_field}>
                  <TableCell
                    key="name"
                    size="small"
                    sx={{ padding: '6px 8px', width: '120px' }}
                  >
                    <span style={{ textTransform: 'uppercase' }}>
                      {field.requiro_field}
                    </span>
                  </TableCell>
                  <TableCell
                    key="rule"
                    size="small"
                    sx={{ padding: '6px 8px', width: '96px' }}
                  >
                    <Select
                      value={field.rule}
                      onChange={(e) => {
                        onExtraFieldChange(
                          field.requiro_field,
                          'rule',
                          e.target.value,
                        );
                      }}
                      sx={{
                        width: '100%',
                      }}
                      small
                    >
                      {opts.map((o) => {
                        return (
                          <MenuItem key={o.value} value={o.value}>
                            {o.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </TableCell>
                  <TableCell
                    key="ruleValue"
                    size="small"
                    sx={{ padding: '6px 8px', width: '350px' }}
                  >
                    {field.type === 'text' || field.type === 'number' ? (
                      <Input
                        type={field.type}
                        placeholder={t('insertText', 'Inserisci testo')}
                        fullWidth
                        value={field.ruleValue}
                        onChange={(e) => {
                          if (field.requiro_field !== 'year') {
                            onExtraFieldChange(
                              field.requiro_field,
                              'ruleValue',
                              e.target.value,
                            );
                          } else {
                            if (e.target.value.toString().length <= 4) {
                              onExtraFieldChange(
                                field.requiro_field,
                                'ruleValue',
                                e.target.value,
                              );
                            }
                          }
                        }}
                        small
                        error={
                          errors && errors.extraFields
                            ? errors.extraFields[field.requiro_field]
                            : false
                        }
                        inputProps={{
                          maxlength:
                            field.requiro_field === 'year' ? 4 : 100000000000,
                        }}
                      />
                    ) : (
                      <Select
                        value={field.ruleValue}
                        onChange={(e) => {
                          onExtraFieldChange(
                            field.requiro_field,
                            'ruleValue',
                            e.target.value,
                          );
                        }}
                        sx={{
                          width: '100%',
                        }}
                        small
                        error={
                          errors && errors.extraFields
                            ? errors.extraFields[field.requiro_field]
                            : false
                        }
                      >
                        <MenuItem key="true" value={true}>
                          TRUE
                        </MenuItem>
                        <MenuItem key="false" value={false}>
                          FALSE
                        </MenuItem>
                      </Select>
                    )}
                  </TableCell>
                  {i < extraFields.length - 1 ? (
                    <TableCell
                      key="operator"
                      size="small"
                      sx={{ padding: '6px 8px', width: '96px' }}
                    >
                      <Select
                        value={field.operator}
                        onChange={(e) => {
                          onExtraFieldChange(
                            field.requiro_field,
                            'operator',
                            e.target.value,
                          );
                        }}
                        sx={{ width: '100%' }}
                        small
                      >
                        {operators_options.map((o) => {
                          return (
                            <MenuItem key={o.value} value={o.value}>
                              {o.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </TableCell>
                  ) : (
                    <TableCell>&nbsp;</TableCell>
                  )}
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>

      <div style={{ marginTop: '20px' }}>
        <TitleToolbar title={t('triggerCriteria', 'Trigger Criteria')} />
        <Paper
          sx={{
            padding: '16px',
            minHeight: '200px',
            fontFamily: 'WotfardLight',
            color: '#9daec0',
          }}
        >
          {triggerCriteria}
        </Paper>
      </div>

      <div style={{ marginTop: '20px' }}>
        <SaveBar
          onCancelLabel={t('cancel', 'Cancel')}
          onCancel={onCancel}
          onSaveLabel={t('save', 'Salva')}
          onSave={onSave}
          saveDisabled={!subscriptions}
        />
      </div>
    </ScreenWrapper>
  );
};

export default KissflowClassSubscriptions;

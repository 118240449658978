import axios from 'axios';
import { useEffect, useRef } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { setAuthHeader, setReqHeaders } from './AxiosInterceptors';

import type { AxiosInstance } from 'axios';

export const useAxios = (baseURL: string) => {
  const axiosInstance = useRef<AxiosInstance>();
  const { keycloak, initialized } = useKeycloak();
  const kcToken: string = keycloak && keycloak.token ? keycloak.token : '';

  useEffect(() => {
    axiosInstance.current = axios.create({
      baseURL,
      headers: {
        Authorization: initialized ? `Bearer ${kcToken}` : '',
      },
    });

    setAuthHeader(kcToken);
    setReqHeaders(kcToken);

    return () => {
      axiosInstance.current = undefined;
    };
  }, [baseURL, initialized, kcToken, keycloak]);

  return axiosInstance;
};

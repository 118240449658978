import React, { useContext, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
    TableSortLabel
} from 'requiro-ui';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { alpha, styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import { Customer, CustomersTableProps, Order } from '../../../types';
import { tableRowsPerPageOptions } from '../../lib/config';
import { usePagination } from '../../lib/usePagination';
import { ApplicationContext, useApplicationContext } from '../../providers/ApplicationProvider';
import { getComparator, isCustomerPrincipal, stableSort } from '../../utilities/UtilitiesFunction';

interface HeadCell {
  disablePadding: boolean;
  id: keyof Customer;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'CustomerCode',
    numeric: false,
    disablePadding: true,
    label: 'Customer Code',
  },
  {
    id: 'CompanyGroupCode',
    numeric: false,
    disablePadding: true,
    label: 'Customer Code Principale',
  },
  {
    id: 'CompanyName',
    numeric: false,
    disablePadding: true,
    label: 'Ragione Sociale',
  },
  {
    id: 'VatNumber',
    numeric: false,
    disablePadding: true,
    label: 'Partita IVA',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Customer,
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Customer) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {/*<TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>*/}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  title: string;
  visualizeMassiveDelete: boolean;
  deleteFunction: any;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected, title, visualizeMassiveDelete, deleteFunction } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.secondary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected}
          {numSelected === 1 ? ' Cliente selezionato' : ' Clienti selezionati'}
        </Typography>
      ) : /*<div style={{ width: "100%", textAlign: "center" }}>
                    <Typography sx={{ flex: '1 1 100%', padding: "3px", paddingBottom: "5px" }} variant="h6" id="tableTitle" component="div" > {props.title} </Typography>
                    <Paper
                        component="form"
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Cerca"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                        <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </div>
                */
      null}
      {numSelected > 0 && visualizeMassiveDelete ? (
        <Tooltip title="Rimuovi" onClick={props.deleteFunction}>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
};

const StartTableCellSelectable = styled(TableCell)`
  z-index: 100 !important;
  position: relative !important;
  border-top: 1px solid #e8ebf0 !important;
  border-left: 1px solid #e8ebf0 !important;
  padding-left: 16px !important;
  border-bottom: 1px solid #e8ebf0 !important;
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
`;

const IntermediateTableCellSelectable = styled(TableCell)`
  z-index: 90 !important;
  position: relative !important;
  border-top: 1px solid #e8ebf0 !important;
  border-bottom: 1px solid #e8ebf0 !important;
`;

const EndTableCellSelectable = styled(TableCell)`
  z-index: 90 !important;
  position: relative !important;
  border-top: 1px solid #e8ebf0 !important;
  border-bottom: 1px solid #e8ebf0 !important;
`;

export default function CustomersTable(props: CustomersTableProps) {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Customer>('CompanyName');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedCustomers, setSelectedCustomers] = React.useState<Customer[]>(
    [],
  );
  const { rowsPerPage, onRowsPerPageChange } = usePagination();
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [title, setTitle] = React.useState(props.title ? props.title : '');
  //const [rows, setRows] = React.useState<Customer[]>(props.rows ? props.rows : []);
  const { currentWorkspace } = useApplicationContext();

  const context = useContext(ApplicationContext);

  useEffect(() => {
    // console.log(selectedCustomers);
    if (context) {
      context.setSelectedCustomersForAddingInWorkspace(selectedCustomers);
    }
    //setRows(props.rows);
  }, [selectedCustomers]);

  useEffect(() => {
    //setRows(props.rows);
    setSelectedCustomers([]);
    setSelected([]);
  }, [props.rows]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Customer,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = props.rows.map((n) => n.CustomerCode);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    name: string,
    customer: Customer,
  ) => {
    if (props.singleSelection) {
      setSelectedCustomers([customer]);
      setSelected([name]);
    } else {
      const selectedIndex = selected.indexOf(name);
      let newSelected: string[] = [];
      let newSelectedCustomersObj: Customer[] = JSON.parse(
        JSON.stringify(selectedCustomers),
      );

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
        newSelectedCustomersObj.push(customer);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
        newSelectedCustomersObj = selectedCustomers.slice(1);
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        newSelectedCustomersObj = selectedCustomers.slice(0, -1);
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
        newSelectedCustomersObj = newSelectedCustomersObj.concat(
          selectedCustomers.slice(0, selectedIndex),
          selectedCustomers.slice(selectedIndex + 1),
        );
      }
      // console.log(newSelectedCustomersObj);
      // console.log(newSelected);
      setSelectedCustomers(newSelectedCustomersObj);
      setSelected(newSelected);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onRowsPerPageChange(event);
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      {props.visualizeMassiveDelete ? (
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={title}
          visualizeMassiveDelete={props.visualizeMassiveDelete}
          deleteFunction={props.deleteFunction}
        />
      ) : null}
      <TableContainer>
        <Table
          sx={{ margin: '10px', width: '98%' }}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={props.rows.length}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
            {stableSort(props.rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(String(row.CustomerCode));
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover={true}
                    onClick={(event: React.MouseEvent<unknown, MouseEvent>) =>
                      handleClick(
                        event,
                        String(row.CustomerCode),
                        row as Customer,
                      )
                    }
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.CustomerCode}
                    selected={isItemSelected}
                    sx={{
                      cursor: 'pointer',
                      backgroundColor: isItemSelected
                        ? 'rgba(25, 118, 210, 0.08) !important'
                        : 'white',
                    }}
                  >
                    <StartTableCellSelectable align="left" component="th">
                      {row.CustomerCode}
                    </StartTableCellSelectable>

    
                    <TableCell align="left">
                      {row.CompanyGroupCode
                        ? row.CompanyGroupCode
                        : currentWorkspace.principalCustomerCode}
                    </TableCell>
                    <TableCell align="left">{row.CompanyName}</TableCell>
                    <TableCell align="left">{row.VatNumber}</TableCell>
                    <TableCell align="left">
                      {props.visualizePrincipalCustomer &&
                      isCustomerPrincipal(row, currentWorkspace) ? (
                        <Tooltip title="Customer Principale">
                          <LocalPoliceIcon color="secondary" />
                        </Tooltip>
                      ) : props.deletableRow ? (
                        <Tooltip
                          title="Elimina"
                          onClick={(_e) => props.deleteFunction(row)}
                        >
                          <DeleteOutlineIcon color="primary" />
                        </Tooltip>
                      ) : null}
                    </TableCell>
                  </TableRow>
                );
              })}
            {/* {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={tableRowsPerPageOptions}
        component="div"
        count={props.rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

import axios from 'axios';
import { useState, useEffect } from 'react';

export const useRealm : any = (url: any, domainCode: string) => {
  const [realm, setRealm] = useState(null);
  useEffect(() => {
    if (!url) return;
    async function exec() {
      try {
        const { data } = await axios.get(url + (domainCode ? '/' + domainCode : ''));
        setRealm(data.data);
      } catch (error) {
        console.log('useRealm failure', error);
      }
    }
    exec();
  }, [url]);

  return realm;
};
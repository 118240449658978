import axios, { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { useQuery } from 'react-query';
import { ITaskLog, TaskModel } from '../../../../types';
import { useAppInitiator } from '../../../providers/AppInitiator';
import mocks, { IJob } from './mocks';

// GET     /workspace/{id}/task ✔️
// GET     /workspace/{id}/task/{idTask} ✔️
// GET     /workspace/{id}/task/{idTask}/log ✔️ // testo in Jobs.tsx

const keysFactory = {
  base: ['workspace-tasks'] as const,
  list: () => [...keysFactory.base, 'list'],
  single: () => [...keysFactory.base, 'single'],
  byWorkspaceId: (workspaceId: string) =>
    [...keysFactory.list(), workspaceId] as const,
  singleTask: (workspaceId: string, taskId: string) => [
    ...keysFactory.single(),
    workspaceId,
    taskId,
  ],
  singleTaskLogs: (workspaceId: string, taskId: string) =>
    [...keysFactory.singleTask(workspaceId, taskId), 'log'] as const,
};

/**
 * fetch all workspace tasks
 * @param workspaceId
 * @param config
 * @returns
 */
export function useWorkspaceTasks(workspaceId, config = {}) {
  const { boot } = useAppInitiator();

  const key = keysFactory.byWorkspaceId(workspaceId);
  const fn = (workspaceId) => () =>
    axios.get(`${boot.appServicesBaseUrl}/workspace/${workspaceId}/task`);

  return useQuery<
    Promise<AxiosResponse<any, any>>,
    Error,
    { totalCount: number; tasks: TaskModel[] }
  >(key, fn(workspaceId), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(workspaceId),
    select: (data) => get(data, 'data.data'),
    ...config,
  });
}

/**
 * fetch a single workspace task
 * @param workspaceId
 * @param taskId
 * @param config
 * @returns
 */
export function useWorkspaceTask(workspaceId, taskId, config = {}) {
  const { boot } = useAppInitiator();

  const key = keysFactory.singleTask(workspaceId, taskId);
  const fn = (workspaceId) => () =>
    axios.get(
      `${boot.appServicesBaseUrl}/workspace/${workspaceId}/task/${taskId}`,
    );

  return useQuery<
    Promise<AxiosResponse<any, any>>,
    Error,
    { totalCount: number; tasks: TaskModel[] }
  >(key, fn(workspaceId), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(workspaceId),
    select: (data) => get(data, 'data.data'),
    ...config,
  });
}

/**
 * fetch a single workspace task specifing an operation input/output/log
 * @param workspaceId
 * @param taskId
 * @param config
 * @returns
 */
export function useWorkspaceTaskLogs(workspaceId, taskId, config = {}) {
  const { boot } = useAppInitiator();

  const key = keysFactory.singleTaskLogs(workspaceId, taskId);
  const fn = () =>
    axios.get(
      `${boot.appServicesBaseUrl}/workspace/${workspaceId}/task/${taskId}/log`,
    );

  return useQuery(key, fn, {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(workspaceId) && Boolean(taskId),
    select: (data) => get(data, 'data.data'),
    ...config,
  });
}

/**
 *
 *
 *
 *
 * OLD IMPLEMENTTAION
 *
 *
 *
 *
 */

// const wsKeys = {
//   all: ['ws'] as const,
//   lists: () => [...wsKeys.all, 'list'] as const,
//   list: (filters: string) => [...wsKeys.lists(), { filters }] as const,
//   details: () => [...wsKeys.all, 'detail'] as const,
//   detail: (id: number) => [...wsKeys.details(), id] as const,
// };

// const wsGroupKeys = {
//   all: () => [...wsKeys.all, 'groups'] as const,
//   detail: (id: string) => [...wsGroupKeys.all(), id] as const,
// };

const jobKeys = {
  all: ['tasks'] as const,
  list: () => [...jobKeys.all, 'list'] as const,
  detail: (id: string) => [...jobKeys.all, id] as const,
};

const fetchJobsMock = () =>
  new Promise<IJob[]>((resolve, reject) => {
    const f = Math.random() > 0 ? resolve : reject;
    setTimeout(f, 1000, mocks);
  })
    .then((result) => result)
    .catch((error) => {
      throw new Error('unlucky event');
    });

const fetchSingleJobMock = (id) =>
  new Promise<IJob[]>((resolve, reject) => {
    const job = mocks.filter((item) => item.id == id);
    const f = Math.random() > 0 ? resolve : reject;
    setTimeout(f, 1000, job);
  })
    .then((result) => result)
    .catch((error) => {
      throw new Error('unlucky event');
    });

/**
 * all jobs
 */
export function useJobs(config = {}) {
  return useQuery<IJob[], Error>(jobKeys.list(), fetchJobsMock, config);
}

/**
 * job by id
 */
export function useJob(id: string, config = {}) {
  return useQuery<IJob[], Error>(
    jobKeys.detail(id),
    () => fetchSingleJobMock(id),
    config,
  );
}

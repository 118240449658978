import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button } from 'requiro-ui';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { ScreenWrapper } from '../../components/layouts/AppLayout';
import MessageBox from '../../components/MessageBox';
import { ScreenToolbarToolsComposite } from '../../components/ScreenToolbarTools';
import TitleToolbar from '../../components/TitleToolbar';
import { useCrumbs } from '../../lib/useCrumbs';
import { extractMutationErrorMessage } from '../../lib/utils';
import { workspaceAtom } from '../../state/jobs';
import { createTaskMutation } from './helpers/mutations';
import { useJob } from './helpers/queries';
const exampleCSV = require('../../Asset/Start_import_example.csv');

const Input = styled('input')({
  display: 'none',
});

const Job = () => {
  useCrumbs([{ to: '/jobs', label: 'Job Manager' }, { label: 'Aggiungi job' }]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const selectedWorkspace = useRecoilValue(workspaceAtom);
  const params = useParams();
  const [file, setFile] = useState<File | null>(null);
  const mutation = createTaskMutation({
    onError: (error) => {
      enqueueSnackbar(extractMutationErrorMessage(error), {
        variant: 'error',
      });
    },
    onSuccess: () => {
      enqueueSnackbar(t('res.saveOK'), { variant: 'success' });
      navigate('/jobs');
    },
  });

  const onSubmit = () => {
    if (file) {
      const formData = new FormData();
      formData.append('type', '0');
      //@ts-ignore
      formData.append('input', file);

      mutation.mutate({
        workspaceId: Number(selectedWorkspace.id),
        task: formData,
      });

      // const reader = new FileReader();
      // reader.addEventListener('load', () => {
      //   const formData = new FormData();
      //   formData.append('type', '1');
      //   //@ts-ignore
      //   formData.append('input', reader.result);

      //   mutation.mutate({
      //     workspaceId: Number(selectedWorkspace.id),
      //     task: formData,
      //   });
      // });
      // reader.readAsText(file);
    }
  };

  if (!selectedWorkspace) return <Navigate to="/jobs" />;

  return (
    <ScreenWrapper>
      <TitleToolbar title="Impostazione utente">
        <a
          href={exampleCSV}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
          download
        >
          <ScreenToolbarToolsComposite.Button
            size="small"
            endIcon={<FileDownloadOutlinedIcon />}
          >
            Scarica il file di esempio
          </ScreenToolbarToolsComposite.Button>
        </a>
      </TitleToolbar>
      {selectedWorkspace ? (
        <Typography
          color="secondary"
          sx={{ p: 2, backgroundColor: '#fff', borderRadius: '16px' }}
        >{`Workspace selezionato: ${selectedWorkspace.name}`}</Typography>
      ) : null}

      <Grid container spacing={0} sx={{ marginTop: '64px !important' }}>
        <Grid item xs={6}>
          <Stack direction="column" spacing={4}>
            <div>
              <label
                htmlFor="icon-button-file"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  border: '0px solid rgba(0,0,0,0.25)',
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                }}
              >
                <Typography component="span" sx={{ flexGrow: '1', pl: 2 }}>
                  {file ? file.name : ''}
                </Typography>
                <Input
                  accept="text/csv"
                  id="icon-button-file"
                  type="file"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    console.log(event);
                    setFile(event.target.files[0]);
                  }}
                />
                <Button
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  size="small"
                  variant="contained"
                  elevation={0}
                  startIcon={<FileUploadIcon />}
                >
                  Seleziona il file
                </Button>
              </label>
              <Typography variant="caption" color="secondary">
                Il file deve essere in formato csv.
              </Typography>
            </div>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={onSubmit}
            >
              Accoda
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={4}>
          <Typography color="secondary">
            Il file csv deve rispettare le seguenti regole:
          </Typography>
          <ul>
            <li>
              <Typography color="secondary" sx={{ fontFamily: 'WotfardLight' }}>
                deve essere codificato in UTF-8
              </Typography>
            </li>
            <li>
              <Typography color="secondary" sx={{ fontFamily: 'WotfardLight' }}>
                deve essere un csv e ammette <strong>SOLO</strong> ';' come
                separatore{' '}
              </Typography>
            </li>
            <li>
              <Typography color="secondary" sx={{ fontFamily: 'WotfardLight' }}>
                ...
              </Typography>
            </li>
          </ul>
        </Grid>
      </Grid>
    </ScreenWrapper>
  );
};

export default Job;

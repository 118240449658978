import { t } from 'i18next';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { WorkspaceUser } from '../../types';
import MessageBox from '../components/MessageBox';
import WorkspaceUsersTable from '../components/tables/WorkspaceUsersTable';
import { useFilteredWorkpaceUsers } from '../features/users/helpers/queries';
import { DEFAULT_PAGINATION } from '../lib/config';
// import { updateUser } from '../api/workspaceUsers';
import { editWorkspaceUser, resetUserPassword } from '../lib/mutations';
import { useApplicationContext } from '../providers/ApplicationProvider';

function WorkspaceUsersManagement(props: any) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_PAGINATION);
  const { currentWorkspace } = useApplicationContext();
  const params = useParams();
  // const query = useWorkspaceUsersQuery(
  //   currentWorkspace.realm,
  //   rowsPerPage,
  //   page,
  // );

  const query = useFilteredWorkpaceUsers(
    params.id,
    `?realm=${currentWorkspace.realm}&limit=${rowsPerPage}&start=${page}&search=${props.searchKey}`,
  );

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { id } = params;
  const rows = get(query, 'data.users', []);
  const totalCount = get(query, 'data.totalCount', 0);

  // console.log({ query });

  const onError = (error: any) => {
    enqueueSnackbar(t('errors.generic'), { variant: 'error' });
  };

  const onSuccess = (data: any) => {
    enqueueSnackbar(t('res.saveOK'), { variant: 'success' });
    navigate(`/ws/${currentWorkspace.id}/users`);
  };

  const successResetPassword = (data: any) => {
    //enqueueSnackbar(t('res.saveOK'), { variant: 'success' });
    //TODO: usare t
    enqueueSnackbar('Mail di reset password inviata con successo', {
      variant: 'success',
    });
    //navigate(`/ws/${currentWorkspace.id}/users`);
  };

  const editMutation = editWorkspaceUser({
    onError,
    onSuccess,
  });

  const resetPasswordMutation = resetUserPassword({
    onError,
    onSuccess: successResetPassword,
  });

  const handleDelete = (idWorkspace: string, idUser: string) => {
    // deleteUser(idWorkspace, idUser)
    //   .then((response: any) => {
    //     console.log(response);
    //     getUser();
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const handleEnableDisable = (
    idWorkspace: string,
    idUser: string,
    user: WorkspaceUser,
  ) => {
    user.state = user.state === 1 ? 0 : 1;
    editMutation.mutate({
      idWorkspace: currentWorkspace.id,
      idUser,
      user,
    });
  };

  const toggleState = (user: WorkspaceUser) => {
    user.state = user.state === 1 ? 0 : 1;
    editMutation.mutate({
      idWorkspace: currentWorkspace.id,
      idUser: user.id,
      user,
    });
  };

  const resetPassword = (idWorkspace: string, idUser: string) => {
    resetPasswordMutation.mutate({
      idWorkspace,
      idUser,
    });
  };

  if (query.isLoading) {
    return <MessageBox.Loading />;
  }
  if (query.isError) {
    return <MessageBox.QueryError query={query} />;
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {rows && rows.length ? (
        <WorkspaceUsersTable
          rows={rows}
          totalCount={totalCount ? totalCount : -1}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          workspace={currentWorkspace}
          onDelete={handleDelete}
          toggleState={toggleState}
          onActivateDeactivate={handleEnableDisable}
          resetPassword={resetPassword}
        />
      ) : (
        <MessageBox.Info>Non ci sono risultati.</MessageBox.Info>
      )}
    </>
  );
}

export default WorkspaceUsersManagement;

import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { Workspace, WorkspaceInfo } from '../../types';
import {
  createWorkspace,
  getWorkspace,
  getWorkspaces,
  updateWorkspace,
} from '../api/workspaceApi';
import ConfirmationDialog from '../components/dialogs/ConfirmationDialog';
import MessageBox from '../components/MessageBox';
import ScreenToolbar from '../components/ScreenToolbar';
import { ScreenToolbarToolsComposite } from '../components/ScreenToolbarTools';
import WorkspacesTable from '../components/tables/WorkspacesTable';
import TitleToolbar from '../components/TitleToolbar';
import WorkspacesFilterBar from '../components/WorkspacesFilterBar';
import { useAxios } from '../lib/useAxios';
import { useCrumbs } from '../lib/useCrumbs';
import { usePagination } from '../lib/usePagination';
import { useAppInitiator } from '../providers/AppInitiator';
import { useApplicationContext } from '../providers/ApplicationProvider';

function WorkspacesManagement() {
  useCrumbs([{ label: 'Workspaces' }]);
  const { boot, axiosBaseServicesClient } = useAppInitiator();
  const { changeWorkspace } = useApplicationContext();
  // const axiosInstance = useAxios(
  //   process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : '',
  // );

  const axiosInstance = useAxios(boot.appServicesBaseUrl || '');
  // const axiosInstance = useAxios(
  //   process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : '',
  // );
  const { rowsPerPage, onRowsPerPageChange } = usePagination();

  const [rows, setRows] = React.useState<Workspace[]>([]);
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_PAGINATION);
  const [queryString, setQueryString] = React.useState<string>(
    'limit=' +
      rowsPerPage +
      '&sort=' +
      encodeURI(
        JSON.stringify([{ property: 'modifiedOn', direction: 'DESC' }]),
      ),
  );
  // used after filter workspeces > disable/enable, to keep the filter alive
  const lastKey = useRef('');
  const [parametriRicerca, setParametriRicerca] = React.useState<any>({
    limit: rowsPerPage,
    sort: encodeURI(
      JSON.stringify([
        {
          property: 'modifiedOn',
          direction: 'DESC',
        },
      ]),
    ),
  });
  const [textForMessageDialog, setTextForMessageDialog] = React.useState('');
  const [openMessageDialog, setOpenMessageDialog] = React.useState(false);
  const [workspaceSelezionato, setWorkspaceSelezionato] =
    React.useState<WorkspaceInfo>();

  let workspaceSelezionatoExtraInfo: WorkspaceInfo | null;

  //It is used to avoid memory leaks
  const _isMounted = useRef(true); // Initial value _isMounted = true

  useEffect(() => {
    changeWorkspace();
    fetchWorkspaces(queryString);
    return () => {
      // ComponentWillUnmount in Class Component
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    fetchWorkspaces(queryString);
  }, [queryString, axiosInstance]);

  useEffect(() => {
    buildQueryString();
  }, [parametriRicerca]);

  async function fetchWorkspaces(queryString: string = '') {
    getWorkspaces(boot.appServicesBaseUrl, queryString)
      .then((response: any) => {
        if (response && response.data && response.data.data) {
          if (_isMounted.current) {
            setRows(
              response.data.data.workspaces
                ? response.data.data.workspaces
                : [],
            );
            setTotalCount(response.data.data.totalCount);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setRows([]);
        setTotalCount(0);
      });
  }

  async function fetchWorkspace(
    id: string = '',
    doPostFetchAction: () => void = () => null,
  ) {
    getWorkspace(boot.appServicesBaseUrl, '/' + id)
      .then((response: any) => {
        if (response && response.data && response.data.data) {
          if (_isMounted.current) {
            setWorkspaceSelezionato(response.data.data);
            workspaceSelezionatoExtraInfo = response.data.data;
            doPostFetchAction();
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const buildQueryString = () => {
    let newQueryString = '';
    let i = 0;
    for (const key in parametriRicerca) {
      const value = parametriRicerca[key];
      if (value && value !== '') {
        i === 0
          ? (newQueryString += key + '=' + value)
          : (newQueryString += '&' + key + '=' + value);
      }
      //console.log(key, value);
      i++;
    }
    console.log('newQueryString', newQueryString);

    setQueryString(newQueryString);
  };

  const handleSearch = (parametriDiRicerca: any) => {
    parametriDiRicerca.limit = rowsPerPage;
    //parametriDiRicerca.start = page;
    setPage(0);
    parametriDiRicerca.start = 0;
    setParametriRicerca({ ...parametriRicerca, ...parametriDiRicerca });
  };

  const handleSearchByKey = (key: string) => {
    // usa il flusso esistente by Francesco
    lastKey.current = key;
    setPage(0);
    fetchWorkspaces(`search=${key}`);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    let newPageObj = { start: newPage };
    setParametriRicerca({ ...parametriRicerca, ...newPageObj });
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Workspace,
    direction: string,
  ) => {
    let sortParameters = {
      sort: encodeURI(JSON.stringify([{ property, direction }])),
    };
    setParametriRicerca({ ...parametriRicerca, ...sortParameters });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onRowsPerPageChange(event);
    setPage(0);
    let newRowsPerPage = { limit: event.target.value, start: 0 };
    setParametriRicerca({ ...parametriRicerca, ...newRowsPerPage });
  };

  const handleDuplicate = (workspace: WorkspaceInfo) => {
    console.log(workspace);
    workspace.id = undefined;
    console.log(workspace);
    let testoPerDialog =
      "Vuoi davvero duplicare il workspace denominato '" +
      workspace.name +
      "' avente customer code principale " +
      workspace.principalCustomerCode;
    setWorkspaceSelezionato(workspace);
    setTextForMessageDialog(testoPerDialog);
    setOpenMessageDialog(true);
  };

  const handleActivationOrDeactivation = async (workspace: Workspace) => {
    console.log(workspace);
    fetchWorkspace(workspace.id, updateSelectedWorkspace);
  };

  const updateSelectedWorkspace = () => {
    if (workspaceSelezionatoExtraInfo) {
      workspaceSelezionatoExtraInfo.state === 1
        ? (workspaceSelezionatoExtraInfo.state = 0)
        : (workspaceSelezionatoExtraInfo.state = 1);
      updateWorkspace(boot.appServicesBaseUrl, workspaceSelezionatoExtraInfo)
        .then((response: any) => {
          console.log(response);
          fetchWorkspaces(`search=${lastKey.current}`);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleCloseConfirmationDialog = () => {
    setOpenMessageDialog(false);
  };

  const handleConfirmConfirmationDialog = () => {
    // console.log(workspaceSelezionato);
    createWorkspace(boot.appServicesBaseUrl, workspaceSelezionato)
      .then((response: any) => {
        // console.log(response);
        fetchWorkspaces(queryString);
        setOpenMessageDialog(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {/* <Box>
        <TitleToolbar title="Cerca per:" />
        <WorkspacesFilterBar handleSearch={handleSearch} />
      </Box> */}
      <Box>
        <TitleToolbar title="Workspaces:">
          <ScreenToolbarToolsComposite.AddButton path="/workspace" />
          <ScreenToolbarToolsComposite.SimpleSearchBox
            handleSearch={handleSearchByKey}
          />
        </TitleToolbar>
        {rows.length ? (
          <WorkspacesTable
            rows={rows}
            totalCount={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            onDuplicate={handleDuplicate}
            onEnableDisable={handleActivationOrDeactivation}
            handleRequestSort={handleRequestSort}
          />
        ) : (
          <MessageBox.Info>Non ci sono risultati.</MessageBox.Info>
        )}
      </Box>
      <ConfirmationDialog
        handleConfirmation={handleConfirmConfirmationDialog}
        handleClose={handleCloseConfirmationDialog}
        text={textForMessageDialog}
        open={openMessageDialog}
        title={'Duplicazione workspace'}
      ></ConfirmationDialog>
    </>
  );
}

export default WorkspacesManagement;

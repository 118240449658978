import axios from 'axios';
import { t } from 'i18next';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import MessageDialog from '../components/dialogs/ConfirmationDialog';
import MessageBox from '../components/MessageBox';
import UsersTable from '../components/tables/UsersTable';
import Filters from '../features/users/components/Filters';
import {
  deleteUserArchiva,
  disableUserArchiva,
  resetUserPasswordNoWorkspace,
} from '../lib/mutations';
import {
  REQUIRO_START_USERS_QUERY_KEY,
  useRequiroStartUsers,
} from '../lib/queries';
import { usePagination } from '../lib/usePagination';
import { extractMutationErrorMessage } from '../lib/utils';
import { useAppInitiator } from '../providers/AppInitiator';

interface IProps {
  open: boolean;
  text: string;
  title: string;
  handleClose: () => void;
  handleConfirmation: () => void;
}

const deleteDialogPropsInitial = {
  open: false,
  text: "Sei sicuro di voler cancellare l'utente?",
  title: '',
  handleClose: () => {},
  handleConfirmation: () => {},
};

export const UserManagementContext = React.createContext({
  actionHandler: (info: any) => {},
});

function UserManagement({ searchKey }: { searchKey: string }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const successResetPassword = (data: any) => {
    enqueueSnackbar('Mail di reset password inviata con successo', {
      variant: 'success',
    });
  };
  const onError = (error: any) => {
    enqueueSnackbar(t('errors.generic'), { variant: 'error' });
  };
  const resetUserPasswordNoWorkspaceMutation = resetUserPasswordNoWorkspace({
    onError,
    onSuccess: successResetPassword,
  });

  const [deleteDialogProps, setDeleteDialogProps] = useState<IProps>(
    deleteDialogPropsInitial,
  );

  const { boot } = useAppInitiator();
  // pagination
  const [page, setPage] = React.useState(0);
  const { rowsPerPage, onRowsPerPageChange } = usePagination();

  // users
  const [users, setUsers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const userToDelete = useRef<any | null>(null);

  const client = useQueryClient();

  const query = useRequiroStartUsers(String(rowsPerPage), String(page));

  // execute search based on data passed from parent, now Filters are hosted by the parent
  useEffect(() => {
    if (searchKey) {
      execSearch(searchKey);
    } else {
      query.refetch();
    }
  }, [searchKey]);

  const disableMutation = disableUserArchiva({
    onError: (error) => {
      enqueueSnackbar(extractMutationErrorMessage(error), {
        variant: 'error',
      });
    },
    onSuccess: () => {
      // show message
      enqueueSnackbar(t('res.saveOK'), { variant: 'success' });
      // refetch data
      client.invalidateQueries([REQUIRO_START_USERS_QUERY_KEY]);
    },
  });

  const deleteMutation = deleteUserArchiva({
    onError: (error) => {
      enqueueSnackbar(extractMutationErrorMessage(error), {
        variant: 'error',
      });
      setDeleteDialogProps(deleteDialogPropsInitial);
    },
    onSuccess: () => {
      // show message
      enqueueSnackbar(t('res.saveOK'), { variant: 'success' });
      // refetch data
      client.invalidateQueries([REQUIRO_START_USERS_QUERY_KEY]);
      // close dialog
      setDeleteDialogProps(deleteDialogPropsInitial);
    },
  });

  useEffect(() => {
    if (!!query.data) {
      setUsers(get(query, 'data.data.data.users', []));
      setTotalCount(get(query, 'data.data.data.totalCount', 0));
    }
  }, [query.data]);

  if (query.isError) {
    return <MessageBox.QueryError query={query} />;
  }
  if (query.isLoading) {
    return <MessageBox.Loading />;
  }

  const handleConfirmation = () => {
    if (userToDelete.current) {
      const data = {
        ...userToDelete.current,
        enabled: !userToDelete.current.enabled,
      };
      deleteMutation.mutate(data.id);
      userToDelete.current = null;
    }
  };

  const handleClose = () => setDeleteDialogProps(deleteDialogPropsInitial);

  const execDelete = (data) => {
    userToDelete.current = data;

    setDeleteDialogProps({
      ...deleteDialogPropsInitial,
      open: true,
      handleClose,
      handleConfirmation,
    });
  };

  const execSearch = (data) => {
    const searchKey = data.trim();

    if (searchKey === '')
      return client.invalidateQueries([REQUIRO_START_USERS_QUERY_KEY]);

    axios
      .get(`${boot.appServicesBaseUrl}/user?realm=master&search=${searchKey}`)
      .then((result) => {
        setUsers(get(result, 'data.data.users', []));
        setTotalCount(get(query, 'data.data.totalCount', 0));
      })
      .catch((error) => {
        enqueueSnackbar(extractMutationErrorMessage(error), {
          variant: 'error',
        });
      });
  };

  const actionHandler = ({ data, action }: { data: any; action: string }) => {
    action === 'delete' && execDelete(data);
    // filters are hosted in the parent component
    // action === 'search' && execSearch(data);
    action === 'view' && navigate(`edit/${data.id}`);
    action === 'resetpsw' &&
      resetUserPasswordNoWorkspaceMutation.mutate({ idUser: data.id });
    action === 'toggleState' &&
      disableMutation.mutate({ ...data, enabled: !data.enabled });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    onRowsPerPageChange(event);
    setPage(0);
  };

  return (
    <UserManagementContext.Provider value={{ actionHandler }}>
      {/* <Filters actionHandler={actionHandler} /> */}
      {users && !!users.length ? (
        <UsersTable
          rows={users}
          totalCount={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        <MessageBox.Info>Non ci sono risultati.</MessageBox.Info>
      )}
      <MessageDialog {...deleteDialogProps} />
    </UserManagementContext.Provider>
  );
}

export default UserManagement;

export interface IHeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
  sortable: boolean;
}

export const headCells: readonly IHeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Nome',
    sortable: true,
  },
  {
    id: 'createOn',
    numeric: false,
    disablePadding: true,
    label: 'Creato il',
    sortable: true,
  },
  {
    id: 'modifiedOn',
    numeric: false,
    disablePadding: true,
    label: 'Modificato il',
    sortable: true,
  },
  {
    id: 'state',
    numeric: false,
    disablePadding: true,
    label: 'Stato',
    sortable: false,
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: true,
    label: '',
    sortable: false,
  },
];

import React, { createContext, useContext, useState } from 'react';
import { IHasChildren } from '../../types';

const NavContext = createContext({
  open: true,
  toggle: () => {},
});

const NavProvider = ({ children }: IHasChildren) => {
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen(!open);

  const contextvalue = {
    open,
    toggle,
  };
  return (
    <NavContext.Provider value={contextvalue}>{children}</NavContext.Provider>
  );
};

export function useNavContext() {
  const ctx = useContext(NavContext);
  if (!ctx) throw new Error('NavContext does not exists!');
  return ctx;
}

export default NavProvider;

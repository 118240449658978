import { get } from 'lodash';
import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Input } from 'requiro-ui';

import { ScreenWrapper } from '../../../../../components/layouts/AppLayout';
import MessageBox from '../../../../../components/MessageBox';
import TitleToolbar from '../../../../../components/TitleToolbar';
import SaveBar from '../../components/Savebar';
import {
  useSingleAppQuery,
  useConfigurationByClassname,
  useSingleCustomerQuery,
  useClassesByCustomerCodeQuery,
} from '../../../../../lib/queries';
import { editConfiguration } from '../../../../../lib/mutations';
import { useCrumbs } from '../../../../../lib/useCrumbs';
import { useApplicationContext } from '../../../../../providers/ApplicationProvider';
import { DocumentalClassConfig } from '../../../../../../types';
import ConfirmationDialog from '../../../../../components/dialogs/ConfirmationDialog';

const KissflowClassConfiguration = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryInfoApp = useSingleAppQuery();
  const { currentWorkspace } = useApplicationContext();
  const navigate = useNavigate();
  const params = useParams();
  const { customerCode, classId } = params;

  const queryInfo = useClassesByCustomerCodeQuery(customerCode);
  const queryInfoCustomer = useSingleCustomerQuery(customerCode);
  const queryInfoConfiguration = useConfigurationByClassname(
    customerCode,
    classId,
  );

  const [saveLoading, handleSaveLoading] = React.useState<boolean>(false);
  const [openDialog, handleOpenDialog] = React.useState<boolean>(false);
  const [set, handleSet] = React.useState<boolean>(false);
  const [configuration, handleConfiguration] =
    React.useState<DocumentalClassConfig>(null);

  const app = get(queryInfoApp, 'data.data.data', []);
  const configurationBase = get(queryInfoConfiguration, 'data.data.data', null);
  const customer = get(queryInfoCustomer, 'data.data.data', null);
  const classes = get(queryInfo, 'data.data.data', []).filter(
    (el) =>
      (el.classname === classId && el.customercode === customerCode) ||
      (el.classname === classId && el.customercode === '000000'),
  );

  const mutation = editConfiguration({
    onError: (data: any) => {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
      queryInfoConfiguration.refetch();
      handleSaveLoading(false);
    },
    onSuccess: (error: any) => {
      enqueueSnackbar(t('res.saveOK'), { variant: 'success' });
      queryInfoConfiguration.refetch();
      handleSaveLoading(false);
    },
  });

  const onCancel = () => {
    navigate(-1);
  };

  const onSave = () => {
    if (configuration) {
      handleSaveLoading(true);
      const configurationToSave = {
        id: configuration.id,
        userName: JSON.parse(localStorage.currentUser).preferred_username,
        customerCode: configuration.customerCode,
        className: configuration.className,
        kfUrl: configuration.kfUrl,
        kfDateFormat: configuration.kfDateFormat,
        kfAccountId: configuration.kfAccountId,
        kfProcessId: configuration.kfProcessId,
        kfAccessSecret: configuration.kfAccessSecret,
        kfKeyId: configuration.kfKeyId,
        // domainCode: configuration.domainCode,
        enabled: configuration.enabled,
        applicationCode: currentWorkspace.appCode,
        domainCode: currentWorkspace.realm,
      };

      Object.keys(configurationToSave).forEach((key) => {
        const isToDelete =
          (!configurationToSave[key] && configurationToSave[key] !== 0) ||
          (key !== 'id' &&
            key !== 'customerCode' &&
            key !== 'className' &&
            configurationBase &&
            configurationBase[0] &&
            configurationToSave[key] === configurationBase[0][key]);

        if (isToDelete) delete configurationToSave[key];
      });

      mutation.mutate(configurationToSave);

      handleOpenDialog(false);
    }
  };

  const onOpenConfirmationDialog = () => {
    handleOpenDialog(true);
  };

  const onCloseConfirmationDialog = () => {
    handleOpenDialog(false);
  };

  const onChange = (key, value) => {
    const clonedConfiguration = JSON.parse(JSON.stringify(configuration));

    clonedConfiguration[key] = value;
    handleConfiguration(clonedConfiguration);
  };

  useCrumbs([
    { to: '/ws', label: 'Workspaces' },
    { to: `/ws/${currentWorkspace.id}`, label: `${currentWorkspace.name}` },
    { to: `/ws/${currentWorkspace.id}/apps`, label: `App` },
    {
      to: `/ws/${currentWorkspace.id}/apps/${app && app.id ? app.id : ''}`,
      label: app && app.name ? app.name : '',
    },
    {
      to: `/ws/${currentWorkspace.id}/apps/${
        app && app.id ? app.id : ''
      }/customer/${customerCode || ''}`,
      label: customer
        ? `${customer.CompanyName}`
        : customerCode
        ? customerCode
        : '',
    },
    {
      label: configuration ? t('configuration', 'Configurazione') : '',
    },
  ]);

  useEffect(() => {
    if (
      !set &&
      configurationBase &&
      configurationBase.length > 0 &&
      classes.length > 0
    ) {
      const composedConfiguration =
        classes.length > 0 && configurationBase
          ? { ...configurationBase[0], label: classes[0].label }
          : null;
      handleConfiguration(composedConfiguration);
      handleSet(true);
    }
  }, [configurationBase, classes]);

  if (queryInfo.isLoading) {
    return <MessageBox.Loading />;
  }
  if (queryInfo.isError) {
    return <MessageBox.QueryError query={queryInfo} />;
  }

  const titleForDialog = t('alert', 'Attenzione');
  const textForDialog = t(
    'editConfigText',
    'Vuoi salvare la configurazione modifcata?',
  );

  return (
    <ScreenWrapper>
      <TitleToolbar
        title={t('configuration', 'Configurazione')}
        subtitle={configuration ? configuration.label : null}
      />

      <div
        style={{
          marginTop: '40px',
          pointerEvents: saveLoading ? 'none' : 'auto',
        }}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0 -16px' }}>
          <div
            style={{
              width: '50%',
              padding: '0 16px',
              marginBottom: '16px',
              opacity: saveLoading ? 0.5 : 1,
            }}
          >
            <Input
              label={t('url', 'URL')}
              placeholder={t('insertText', 'Inserisci testo')}
              fullWidth
              value={configuration ? configuration.kfUrl : ''}
              onChange={(e) => {
                onChange('kfUrl', e.target.value);
              }}
            />
          </div>
          <div
            style={{
              width: '50%',
              padding: '0 16px',
              marginBottom: '16px',
              opacity: saveLoading ? 0.5 : 1,
            }}
          >
            <Input
              label={t('accountId', 'Account ID')}
              placeholder={t('insertText', 'Inserisci testo')}
              fullWidth
              value={configuration ? configuration.kfAccountId : ''}
              onChange={(e) => {
                onChange('kfAccountId', e.target.value);
              }}
            />
          </div>
          <div
            style={{
              width: '50%',
              padding: '0 16px',
              marginBottom: '16px',
              opacity: saveLoading ? 0.5 : 1,
            }}
          >
            <Input
              label={t('processId', 'Process ID')}
              placeholder={t('insertText', 'Inserisci testo')}
              fullWidth
              value={configuration ? configuration.kfProcessId : ''}
              onChange={(e) => {
                onChange('kfProcessId', e.target.value);
              }}
            />
          </div>
          <div
            style={{
              width: '50%',
              padding: '0 16px',
              marginBottom: '16px',
              opacity: saveLoading ? 0.5 : 1,
            }}
          >
            <Input
              label={t('keyId', 'Key ID')}
              placeholder={t('insertText', 'Inserisci testo')}
              fullWidth
              value={configuration ? configuration.kfKeyId : ''}
              onChange={(e) => {
                onChange('kfKeyId', e.target.value);
              }}
            />
          </div>
          <div
            style={{
              width: '50%',
              padding: '0 16px',
              marginBottom: '16px',
              opacity: saveLoading ? 0.5 : 1,
            }}
          >
            <Input
              label={t('accessSecret', 'Access Secret')}
              placeholder={t('insertText', 'Inserisci testo')}
              fullWidth
              value={configuration ? configuration.kfAccessSecret : ''}
              onChange={(e) => {
                onChange('kfAccessSecret', e.target.value);
              }}
            />
          </div>

          <div style={{ width: '100%', padding: '0 16px' }}>
            <SaveBar
              onCancelLabel={t('cancel', 'Cancel')}
              onCancel={onCancel}
              onSaveLabel={t('save', 'Salva')}
              onSave={onOpenConfirmationDialog}
            />
          </div>

          <ConfirmationDialog
            handleConfirmation={onSave}
            handleClose={onCloseConfirmationDialog}
            text={textForDialog}
            open={openDialog}
            title={titleForDialog}
          ></ConfirmationDialog>
        </div>
      </div>
    </ScreenWrapper>
  );
};

export default KissflowClassConfiguration;

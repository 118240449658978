import axios from 'axios';
import { useState, useEffect } from 'react';

export const useBoot: any = () => {
  const [boot, setBoot] = useState<any>(null);

  useEffect(() => {
    async function exec() {
      try {
        const { data } = await axios.get(process.env.REACT_APP_BOOT_URL);
        setBoot(data);
      } catch (error) {
        console.log('useBoot failure', error);
      }
    }
    exec();
  }, []);

  return boot;
};

import React from 'react';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ConfirmSwitch from '../../../../components/buttons/ConfirmSwitch';
import { WorkspaceGroupModel } from '../../../../generated/axios';
import { wsActions } from '../../../../lib/config';
import { IActionHandlerParams } from '../GroupIndex';

// import { UserManagementContext } from '../../pages/UserManagement';

export default function MenuRow({
  actionHandler,
  data,
}: {
  actionHandler: ({ action, group }: IActionHandlerParams) => void;
  data: WorkspaceGroupModel;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  //const { actionHandler } = React.useContext(UserManagementContext);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ justifyContent: 'flex-end', display: 'flex' }}>
      <ConfirmSwitch
        checked={data.state}
        onChange={(_e: any) =>
          actionHandler({
            action: wsActions.toggleState,
            group: data,
          })
        }
      />
      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            actionHandler({
              action: wsActions.edit,
              group: data,
            });
          }}
        >
          <ListItemIcon>
            <ModeEditOutlineOutlinedIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          Modifica
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            actionHandler({
              action: wsActions.info,
              group: data,
            });
          }}
        >
          <ListItemIcon>
            <InfoIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          Info
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            actionHandler({
              action: wsActions.permissionEdit,
              group: data,
            });
          }}
        >
          <ListItemIcon>
            <ModeEditOutlineOutlinedIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          Modifica permessi
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            actionHandler({
              action: wsActions.permissionShow,
              group: data,
            });
          }}
        >
          <ListItemIcon>
            <InfoIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          Visualizza permessi
        </MenuItem>
      </Menu>
    </Box>
  );
}

import React, { useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputBase, Paper, Typography } from '@mui/material';

export function SearchBar(props: any) {
  const [searchText, setSearchText] = React.useState<string>('');

  useEffect(() => {
    props.searchFunction('');
  }, []);

  const handleTextfieldsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    let text = '';
    if (event.target) {
      text = event.target.value;
      setSearchText(text);
      // console.log(text);
    }
  };

  const search = () => {
    // console.log(searchText);
    props.searchFunction('infoCustomer=' + searchText);
  };

  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <Typography
        sx={{ flex: '1 1 100%', padding: '3px', paddingBottom: '5px' }}
        variant="h6"
        id="tableTitle"
        component="div"
      ></Typography>
      <Paper component="form" sx={{ display: 'flex', alignItems: 'center' }}>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Cerca"
          inputProps={{ 'aria-label': 'search' }}
          onKeyPress={(e) => {
            e.key === 'Enter' && e.preventDefault();
            e.key === 'Enter' ? search() : null;
          }}
          onChange={(event) => handleTextfieldsChange(event)}
        />
        <IconButton
          onClick={search}
          onSubmit={search}
          sx={{ p: '10px' }}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
      </Paper>
    </div>
  );
}

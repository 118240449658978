import axios from 'axios';
import { omit } from 'lodash';

// set Authorization header
export const setAuthHeader = (token: string) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  //setResponseInterceptor();
};

export const setReqHeaders = (token: string) => {
  axios.interceptors.request.use(
    function (config) {
      if (config.url.endsWith('impersonate')) {
        const { headers } = config;
        const { common } = headers;
        const newCommon = omit(common as unknown as object, 'Authorization');
        return {
          ...config,
          headers: {
            ...config.headers,
            common: newCommon,
          },
        };
      }

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    },
  );
};

const setResponseInterceptor = () => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      alert('Si è verificato un errore');
    },
  );
};

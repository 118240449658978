import { atom, selector } from 'recoil';
import { Workspace } from '../../types';

const keys = {
  workspace: 'jobsWorkspace',
  workspaceId: 'jobsWorkspaceId',
};

const workspaceAtom = atom<Workspace>({
  key: keys.workspace,
  default: null,
});

const workspaceIdAtom = selector({
  key: keys.workspaceId,
  get: ({ get }) => {
    const workspace = get(workspaceAtom);

    return workspace ? workspace.id : null;
  },
});

export { workspaceAtom, workspaceIdAtom };

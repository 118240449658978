import React from 'react';
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from 'requiro-ui';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { createData } from './fakeData';
import { rowType, rowTypeForCopy } from './interface';

export function Row(props: {
  row: ReturnType<typeof createData>;
  rows: rowType[];
  edit: boolean;
  filterFn?: (item: any) => boolean;
}) {
  let { row, rows, edit } = props;
  const [open, setOpen] = React.useState(false);
  const [rowPermissions, setRowPermissions] =
    React.useState<rowTypeForCopy>(null);

  const handleClick = (permissionsRow) => {
    rows = rows.map((rowOriginal) => {
      if (rowOriginal.name === row.name) {
        rowOriginal.permissions.map((permissionRowOriginal) => {
          if (permissionRowOriginal.name === permissionsRow.name) {
            permissionRowOriginal.selected = !permissionsRow.selected;
          }
          return permissionRowOriginal;
        });
        row = rowOriginal;
      }
      return rowOriginal;
    });
    setRowPermissions({
      nameApp: row.name,
      namePermission: permissionsRow.name,
      permission: permissionsRow,
    });
  };

  // stop using filtered data, data are fetched already filtered by backend services
  // const filteredPermissions = props.filterFn
  //   ? row.permissions.filter(props.filterFn)
  //   : row.permissions;
  const filteredPermissions = row.permissions;

  console.log('props.filterFn', props.filterFn);

  // console.log({ filteredPermissions });
  // console.log('row.permissions', row.permissions);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            sx={{ border: 'none !important' }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Permessi
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell component="th" scope="row">
                      Permesso
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Descrizione
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredPermissions.map((permissionsRow) => {
                    console.log(
                      'permissionsRow.selected',
                      permissionsRow.selected,
                    );

                    return (
                      <TableRow key={permissionsRow.name}>
                        <TableCell component="th" scope="row">
                          <Checkbox
                            checked={permissionsRow.selected}
                            disabled={!edit}
                            onClick={() => {
                              edit && handleClick(permissionsRow);
                            }}
                            icon={
                              edit ? (
                                <CircleOutlinedIcon />
                              ) : (
                                <CheckIcon sx={{ opacity: 0 }} />
                              )
                            }
                            checkedIcon={
                              edit ? (
                                <CheckCircleOutlineOutlinedIcon />
                              ) : (
                                <CloseIcon sx={{ opacity: 0 }} />
                              )
                            }
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {permissionsRow.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {permissionsRow.description}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default Row;

import { get } from 'lodash';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, Menu, MenuItem } from 'requiro-ui';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { IUserMenuItem } from '../../types';

interface Props extends IUserMenuItem {
  onClick: () => void;
}

const Item = ({ onClick, icon, ...info }: Props) => (
  <MenuItem>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText onClick={onClick}>
      {info.to ? (
        <Link to={info.to} style={{ textDecoration: 'none' }}>
          <Typography
            color="secondary"
            variant="body2"
            sx={{ fontFamily: 'WotfardLight' }}
          >
            {info.label}
          </Typography>
        </Link>
      ) : (
        <Typography
          color="secondary"
          variant="body2"
          sx={{ fontFamily: 'WotfardLight' }}
        >
          {info.label}
        </Typography>
      )}
    </ListItemText>
  </MenuItem>
);

const menuItems: IUserMenuItem[] = [
  // {
  //   to: '/',
  //   label: 'Modifica',
  //   icon: <EditOutlinedIcon color="secondary" />,
  // },
  // {
  //   to: '/catalog',
  //   label: 'Catalogo App',
  //   icon: <GridViewOutlinedIcon color="secondary" />,
  // },
  // {
  //   to: '/permissions',
  //   label: 'Gestione Permessi',
  //   icon: <AssignmentTurnedInOutlinedIcon color="secondary" />,
  // },
  {
    to: '/users',
    label: 'Utenti START',
    icon: <PersonOutlinedIcon color="secondary" />,
  },
  {
    to: '/jobs',
    label: 'Job Manager',
    icon: <WorkOutlineOutlinedIcon color="secondary" />,
  },
  {
    to: '/account',
    label: 'Account',
    icon: <ManageAccountsIcon color="secondary" />,
  },
];

const UserMenu = () => {
  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  // function handleCloseNavMenu(to?: string) {
  //   if (to) {
  //     navigate(to);
  //   } else {
  //     keycloak.logout();
  //     localStorage.removeItem('rqx-sso-token');
  //   }
  //   setAnchorElNav(null);
  // }

  const logout = () => {
    keycloak.logout();
    localStorage.removeItem('rqx-sso-token');
  };
  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar
            alt={get(keycloak, 'idTokenParsed.name', 'AA')}
            src="/static/images/avatar/2.jpg"
            variant="circular"
          />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {menuItems.map((item: IUserMenuItem) => {
          if (item.label.toLowerCase() === 'account') {
            item.to = `account/${get(keycloak, 'idTokenParsed.sub', 'AA')}`;
          }
          return (
            <Item key={item.label} {...item} onClick={handleCloseUserMenu} />
          );
        })}
        <MenuItem>
          <ListItemIcon>
            <LogoutOutlinedIcon color="secondary" />
          </ListItemIcon>
          <ListItemText onClick={logout}>
            <Typography
              color="secondary"
              variant="body2"
              sx={{ fontFamily: 'WotfardLight' }}
            >
              Logout
            </Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserMenu;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem } from 'requiro-ui';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ElectricalServicesOutlinedIcon from '@mui/icons-material/ElectricalServicesOutlined';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Stack } from '@mui/material';

const RowMenu = ({ anchorEl, closeHandler, open, data }) => {
  const navigate = useNavigate();

  if (!data) return null;

  return (
    <Menu
      dense
      anchorEl={anchorEl}
      open={open}
      onClose={closeHandler}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={() => navigate(`/ws/${data.id}`)}>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          <InfoOutlinedIcon color="secondary" /> <span>Info</span>
        </Stack>
      </MenuItem>
      <MenuItem onClick={() => navigate(`/ws/${data.id}/apps`)}>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          <GridViewOutlinedIcon color="secondary" /> <span>App</span>
        </Stack>
      </MenuItem>
      <MenuItem onClick={() => navigate(`/ws/${data.id}/customers`)}>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          <FactoryOutlinedIcon color="secondary" /> <span>Clienti</span>
        </Stack>
      </MenuItem>
      <MenuItem onClick={() => navigate(`/ws/${data.id}/groups`)}>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          <GroupWorkOutlinedIcon color="secondary" /> <span>Gruppi utente</span>
        </Stack>
      </MenuItem>
      <MenuItem onClick={() => navigate(`/ws/${data.id}/users`)}>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          <AccountCircleOutlinedIcon color="secondary" /> <span>Utenti</span>
        </Stack>
      </MenuItem>
      {/* <MenuItem onClick={() => navigate(`/ws/${data.id}/`)}>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          <ElectricalServicesOutlinedIcon color="secondary" /> <span>API</span>
        </Stack>
      </MenuItem> */}
    </Menu>
  );
};

export default RowMenu;

import { get } from 'lodash';
import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Input } from 'requiro-ui';

import { ScreenWrapper } from '../../../../../components/layouts/AppLayout';
import MessageBox from '../../../../../components/MessageBox';
import TitleToolbar from '../../../../../components/TitleToolbar';
import SaveBar from '../Savebar';
import {
  useSingleAppQuery,
  useClassesByCustomerCodeQuery,
  useSingleCustomerQuery,
  useConfigurationByClassname,
  fetchAuditByConfigurationId,
} from '../../../../../lib/queries';
import { editConfiguration } from '../../../../../lib/mutations';
import { useCrumbs } from '../../../../../lib/useCrumbs';
import { useApplicationContext } from '../../../../../providers/ApplicationProvider';
import ConfirmationDialog from '../../../../../components/dialogs/ConfirmationDialog';
import { useAppInitiator } from '../../../../../providers/AppInitiator';

const KissflowClassAuditDetail = () => {
  const { boot } = useAppInitiator();
  const { enqueueSnackbar } = useSnackbar();
  const queryInfoApp = useSingleAppQuery();
  const { currentWorkspace } = useApplicationContext();
  const navigate = useNavigate();
  const params = useParams();
  const { customerCode, configId, classId } = params;

  const queryInfo = useClassesByCustomerCodeQuery(customerCode);
  const queryInfoCustomer = useSingleCustomerQuery(customerCode);
  const queryInfoConfiguration = useConfigurationByClassname(
    customerCode,
    classId,
  );

  const [saveLoading, handleSaveLoading] = React.useState<boolean>(false);
  const [openDialog, handleOpenDialog] = React.useState<boolean>(false);
  const [auditFull, handleAuditFull] = React.useState(null);
  const [audit, handleAudit] = React.useState(null);
  const [set, handleSet] = React.useState<boolean>(false);
  const [configuration, handleConfiguration] = React.useState(null);

  const app = get(queryInfoApp, 'data.data.data', []);
  const customer = get(queryInfoCustomer, 'data.data.data', null);
  const configurationBase = get(queryInfoConfiguration, 'data.data.data', null);
  const classes = get(queryInfo, 'data.data.data', []).filter(
    (el) =>
      (el.classname === classId && el.customercode === customerCode) ||
      (el.classname === classId && el.customercode === '000000'),
  );

  const mutation = editConfiguration({
    onError: (data: any) => {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
      handleSaveLoading(false);
    },
    onSuccess: (error: any) => {
      enqueueSnackbar(t('res.saveOK'), { variant: 'success' });
      handleSaveLoading(false);
      navigate(-1);
    },
  });

  const onConfirmationDialog = () => {
    handleSaveLoading(true);
    const configurationToSave = {
      id: audit.id,
      userName: JSON.parse(localStorage.currentUser).preferred_username,
      customerCode: audit.customerCode,
      className: audit.className,
      kfUrl: audit.kfUrl,
      kfDateFormat: 'yyyy-MM-dd',
      kfAccountId: audit.kfAccountId,
      kfProcessId: audit.kfProcessId,
      kfAccessSecret: audit.kfAccessSecret,
      kfKeyId: audit.kfKeyId,
      domainCode: audit.domainCode,
      enabled: audit.enabled,
      applicationCode: currentWorkspace.appCode,
    };

    Object.keys(configurationToSave).forEach((key) => {
      const isToDelete =
        (!configurationToSave[key] && configurationToSave[key] !== 0) ||
        (key !== 'id' &&
          key !== 'customerCode' &&
          key !== 'className' &&
          configurationBase &&
          configurationBase[0] &&
          configurationToSave[key] === configurationBase[0][key]);

      if (isToDelete) delete configurationToSave[key];
    });

    mutation.mutate(configurationToSave);

    handleOpenDialog(false);
  };

  const onCloseConfirmationDialog = () => {
    handleOpenDialog(false);
  };

  const onChange = (key, value) => {
    const clonedAudit = JSON.parse(JSON.stringify(audit));

    clonedAudit[key] = value;
    handleAudit(clonedAudit);
  };

  const onCancel = () => {
    navigate(-1);
  };

  const onUse = () => {
    handleOpenDialog(true);
  };

  useCrumbs([
    { to: '/ws', label: 'Workspaces' },
    { to: `/ws/${currentWorkspace.id}`, label: `${currentWorkspace.name}` },
    { to: `/ws/${currentWorkspace.id}/apps`, label: `App` },
    {
      to: `/ws/${currentWorkspace.id}/apps/${app && app.id ? app.id : ''}`,
      label: app && app.name ? app.name : '',
    },
    {
      to: `/ws/${currentWorkspace.id}/apps/${
        app && app.id ? app.id : ''
      }/customer/${customerCode || ''}`,
      label: customer
        ? `${customer.CompanyName}`
        : customerCode
        ? customerCode
        : '',
    },
    {
      to: `/ws/${currentWorkspace.id}/apps/${
        app && app.id ? app.id : ''
      }/customer/${customerCode}/history/${classId}`,
      label: t('history', 'Storico'),
    },
    {
      label: t('configuration', 'Ripristina Configurazione'),
    },
  ]);

  const fetchData = async (id: string) => {
    const auditResult = await fetchAuditByConfigurationId(
      boot.appKissflowServicesBaseUrl,
      id,
    );

    if (auditResult && auditResult.data) {
      const filteredAudit = auditResult.data.data.filter(
        (el) => el.id.toString() === configId,
      );
      if (filteredAudit.length > 0) {
        handleAuditFull(filteredAudit[0]);
        if (filteredAudit[0].previousConfiguration) {
          handleAudit(JSON.parse(filteredAudit[0].previousConfiguration));
        }
      }
    }
  };

  useEffect(() => {
    if (
      !set &&
      configurationBase &&
      configurationBase.length > 0 &&
      classes.length > 0
    ) {
      const composedConfiguration =
        classes.length > 0 && configurationBase
          ? { ...configurationBase[0], label: classes[0].label }
          : null;
      handleConfiguration(composedConfiguration);
      handleSet(true);

      if (composedConfiguration) {
        fetchData(String(composedConfiguration.id));
      }
    }
  }, [configurationBase, classes]);

  if (queryInfo.isLoading) {
    return <MessageBox.Loading />;
  }
  if (queryInfo.isError) {
    return <MessageBox.QueryError query={queryInfo} />;
  }

  if (set && !audit) {
    return (
      <MessageBox.Info>
        {t(
          'noPreviousConfiguration',
          'Nessuna configurazione precedente trovata',
        )}
      </MessageBox.Info>
    );
  }

  const titleForDialog = t('alert', 'Attenzione');
  const textForDialog = t(
    'resumeConfigText',
    'Vuoi ripristinare la configurazione selezionata?',
  );

  return (
    <ScreenWrapper>
      <TitleToolbar
        title={t('configurationRecover', 'Ripristina configurazione')}
        subtitle={configuration ? configuration.label : null}
      />
      <div
        style={{
          marginTop: '40px',
          pointerEvents: saveLoading ? 'none' : 'auto',
        }}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0 -16px' }}>
          <div
            style={{
              width: '50%',
              padding: '0 16px',
              marginBottom: '16px',
              opacity: saveLoading ? 0.5 : 1,
            }}
          >
            <Input
              label={t('url', 'URL')}
              placeholder={t('insertText', 'Inserisci testo')}
              fullWidth
              value={audit ? audit.kfUrl : ''}
              onChange={(e) => {
                onChange('kfUrl', e.target.value);
              }}
            />
          </div>
          <div
            style={{
              width: '50%',
              padding: '0 16px',
              marginBottom: '16px',
              opacity: saveLoading ? 0.5 : 1,
            }}
          >
            <Input
              label={t('accountId', 'Account ID')}
              placeholder={t('insertText', 'Inserisci testo')}
              fullWidth
              value={audit ? audit.kfAccountId : ''}
              onChange={(e) => {
                onChange('kfAccountId', e.target.value);
              }}
            />
          </div>
          <div
            style={{
              width: '50%',
              padding: '0 16px',
              marginBottom: '16px',
              opacity: saveLoading ? 0.5 : 1,
            }}
          >
            <Input
              label={t('processId', 'Process ID')}
              placeholder={t('insertText', 'Inserisci testo')}
              fullWidth
              value={audit ? audit.kfProcessId : ''}
              onChange={(e) => {
                onChange('kfProcessId', e.target.value);
              }}
            />
          </div>
          <div
            style={{
              width: '50%',
              padding: '0 16px',
              marginBottom: '16px',
              opacity: saveLoading ? 0.5 : 1,
            }}
          >
            <Input
              label={t('keyId', 'Key ID')}
              placeholder={t('insertText', 'Inserisci testo')}
              fullWidth
              value={audit ? audit.kfKeyId : ''}
              onChange={(e) => {
                onChange('kfKeyId', e.target.value);
              }}
            />
          </div>
          <div
            style={{
              width: '50%',
              padding: '0 16px',
              marginBottom: '16px',
              opacity: saveLoading ? 0.5 : 1,
            }}
          >
            <Input
              label={t('accessSecret', 'Access Secret')}
              placeholder={t('insertText', 'Inserisci testo')}
              fullWidth
              value={audit ? audit.kfAccessSecret : ''}
              onChange={(e) => {
                onChange('kfAccessSecret', e.target.value);
              }}
            />
          </div>

          <div style={{ width: '100%', padding: '0 16px' }}>
            <SaveBar
              onCancelLabel={t('cancel', 'Cancel')}
              onCancel={onCancel}
              onSaveLabel={t('ripristina', 'Ripristina')}
              onSave={onUse}
            />
          </div>

          <ConfirmationDialog
            handleConfirmation={onConfirmationDialog}
            handleClose={onCloseConfirmationDialog}
            text={textForDialog}
            open={openDialog}
            title={titleForDialog}
          ></ConfirmationDialog>
        </div>
      </div>
    </ScreenWrapper>
  );
};

export default KissflowClassAuditDetail;

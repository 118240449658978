import React from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { IconButton, Stack, Typography } from '@mui/material';
import ConfirmButton from '../../../../components/buttons/ConfirmButton';
import Img from '../../../../components/Img';
import { AppModel } from '../../../../generated/axios';
import defaultIcon from '../../../../lib/defaultIcon';
import { useActions } from '../helpers/hooks';
import { ListItemWrapper } from './Styled';
import SettingsIcon from '@mui/icons-material/Settings';
import ModalChangeURL from './ModalChangeURL';

export default ({ data }: { data: AppModel }) => {
  const location = useLocation();
  const { createHandler, deleteHandler } = useActions();
  const { id } = useParams();
  const navigate = useNavigate();

  const { id: appId } = data;
  const isNew = location.pathname.indexOf('/apps/new') > -1;

  const isConfig = data.id === 5;

  const configHandler = (appId: string) => {
    console.log('appId', appId);
    navigate(`${location.pathname}/${appId}`);
  };

  return (
    <ListItemWrapper elevation={3}>
      <Stack direction="row" sx={{ alignItems: 'center' }}>
        <Img alt="alt" src="" altSrc={defaultIcon} className="ico" />
        <Typography variant="h6" sx={{ mx: 3 }}>
          {data.name}
        </Typography>
        <Typography variant="subtitle1">{data.description}</Typography>
      </Stack>
      {data.id ? (
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          <Typography variant="subtitle2">Versione: {data.version}</Typography>
          {isConfig && (
            <IconButton
              onClick={() => {
                appId && configHandler(String(appId));
              }}
            >
              <SettingsIcon color="secondary" fontSize="medium" />
            </IconButton>
          )}
          <ModalChangeURL data={data} />
          {isNew ? (
            // <IconButton
            //   onClick={() => {
            //     createHandler(data);
            //   }}
            // >
            //   <AddCircleOutlineOutlinedIcon color="action" fontSize="medium" />
            // </IconButton>
            <ConfirmButton
              action={() => {
                createHandler(data);
              }}
            >
              <ConfirmButton.AddIcon />
            </ConfirmButton>
          ) : (
            <ConfirmButton
              action={() => {
                appId && deleteHandler(String(appId));
              }}
            >
              <ConfirmButton.DeleteIcon />
            </ConfirmButton>
            // <ConfirmDelete
            //   message="Confermi di voler rimuovere l'app dal workspace?"
            //   onClick={() => {
            //     appId && deleteHandler(String(appId));
            //   }}
            // />
            // <IconButton
            //   onClick={() => {
            //     appId && deleteHandler(String(appId));
            //   }}
            // >
            //   <DeleteOutlineIcon color="action" fontSize="medium" />
            // </IconButton>
          )}
        </Stack>
      ) : null}
    </ListItemWrapper>
  );
};

import { atom } from 'recoil';
import { GRID_VIEW, RQX_START_VIEW_MODE } from '../lib/config';

export const dataViewMode = atom({
  key: 'ViewMode',
  default: localStorage.getItem(RQX_START_VIEW_MODE) || GRID_VIEW,
  effects: [
    ({ onSet }) => {
      return onSet((newValue) =>
        localStorage.setItem(RQX_START_VIEW_MODE, newValue),
      );
    },
  ],
});

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import WorkspaceUserFormPage from '../../../pages/WorkspaceUserFormPage';
import PermissionsUser from '../groups/Permissions/PermissionsUser';
import UserForm from './UserForm';
// import Form from "../../catalog/Form";
// import Item from "../../catalog/Item";
// import AppSelector from "./components/AppSelector";
import WSUsers from './WSUsers';

const UsersRouter = () => {
  return (
    <Routes>
      <Route path="" element={<WSUsers />} />
      {/* <Route path=":id" element={<Item />} /> */}
      {/* <Route path="edit/:id" element={<Form />} /> */}
      {/* <Route path="new" element={<UserForm />} /> */}
      <Route path="new" element={<WorkspaceUserFormPage />} />
      <Route path="edit/:id_user" element={<WorkspaceUserFormPage />} />
      <Route path=":idUser/permissions" element={<PermissionsUser />} />
    </Routes>
  );
};

export default UsersRouter;

import React from 'react';
import { Link } from 'react-router-dom';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ElectricalServicesOutlinedIcon from '@mui/icons-material/ElectricalServicesOutlined';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import HomeIcon from '@mui/icons-material/Home';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import PeopleIcon from '@mui/icons-material/People';
// import SearchIcon from '@mui/icons-material/Search';
import {
    Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Stack, Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { INavMenuItem } from '../../types';
import { useApplicationContext } from '../providers/ApplicationProvider';
import { useNavContext } from '../providers/NavProvider';
import { DrawerHeader } from './layouts/Styled';

const Item = ({ icon, ...info }: INavMenuItem) => (
  <ListItem>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText>
      <Link to={info.to} style={{ textDecoration: 'none' }}>
        <Typography color="secondary">{info.label}</Typography>
      </Link>
    </ListItemText>
  </ListItem>
);

const cloudItems = [
  {
    icon: <HomeIcon color="secondary" />,
    to: '/ws',
    label: 'Workspaces',
  },
  // {
  //   icon: <PeopleIcon color="secondary" />,
  //   to: '/users',
  //   label: 'Utenti START',
  // },
];

const wsItems = [
  {
    icon: <InfoOutlinedIcon color="secondary" />,
    to: '/ws/_id_',
    label: 'Info',
  },
  {
    icon: <GridViewOutlinedIcon color="secondary" />,
    to: '/ws/_id_/apps',
    label: 'App',
  },
  {
    icon: <FactoryOutlinedIcon color="secondary" />,
    to: '/ws/_id_/customers',
    label: 'Clienti',
  },
  {
    icon: <GroupWorkOutlinedIcon color="secondary" />,
    to: '/ws/_id_/groups',
    label: 'Gruppi',
  },
  {
    icon: <AccountCircleOutlinedIcon color="secondary" />,
    to: '/ws/_id_/users',
    label: 'Utenti',
  },
  // {
  //   icon: <ElectricalServicesOutlinedIcon color="secondary" />,
  //   to: '/ws/_id_/api',
  //   label: 'API',
  // },
];

const Nav = ({ width }: { width: number }) => {
  const { open, toggle } = useNavContext();
  const { currentWorkspace } = useApplicationContext();
  return (
    <Drawer
      open={open}
      onClose={toggle}
      variant="persistent"
      anchor="left"
      PaperProps={{
        sx: { backgroundColor: grey[100] },
      }}
      sx={{
        width,
        flexShrink: 0,
        '& .MuiDrawer-paper': { width, boxSizing: 'border-box' },
      }}
    >
      <DrawerHeader>
        {/* <IconButton onClick={toggle}>
          <ChevronLeftIcon />
        </IconButton> */}
      </DrawerHeader>
      <Stack direction="column" spacing={2} sx={{ p: 2 }}>
        <List dense={true}>
          <ListItem>
            <ListItemText sx={{ fontWeight: '700' }}>
              Requiro Cloud
            </ListItemText>
          </ListItem>
          {cloudItems.map((item: INavMenuItem) => (
            <Item key={item.label} {...item} />
          ))}
        </List>
        <Divider />
        {/* <List dense={true}>
          {currentWorkspace ? (
            <ListItem>
              <ListItemText>
                Workspace
                <Typography>
                  {currentWorkspace ? currentWorkspace.name : 'unknown'}
                </Typography>
              </ListItemText>
            </ListItem>
          ) : null}
          {currentWorkspace
            ? wsItems.map((item: INavMenuItem) => {
                const updatedItem = {
                  ...item,
                  to: item.to.replace('_id_', currentWorkspace.id),
                };
                return <Item key={item.label} {...updatedItem} />;
              })
            : null}
          {currentWorkspace ? (
            <ListItem disabled>
              <ListItemIcon>
                <ElectricalServicesOutlinedIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography color="secondary">API</Typography>
              </ListItemText>
            </ListItem>
          ) : null}
        </List> */}
      </Stack>
    </Drawer>
  );
};

export default Nav;

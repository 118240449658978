import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { IHasChildren } from '../../../types';

// export const Item = styled('div')(({ theme }: { theme: any }) => ({
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
// }));

// export const Name = ({ children }: IHasChildren) => (
//   <Typography sx={{ mb: 1 }}>{children}</Typography>
// );

// export const Value = ({ children }: IHasChildren) => (
//   <Typography
//     component="span"
//     sx={{
//       backgroundColor: '#ffffff',
//       p: 1,
//       borderRadius: 1,
//       width: '100%',
//       display: 'inline-block',
//     }}
//   >
//     {children}
//   </Typography>
// );

export const Item = styled('div')(({ theme }: { theme: any }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
}));

export const Name = ({ children }: IHasChildren) => (
  <Typography sx={{ mb: 1 }}>{children}</Typography>
);

export const Value = ({ children }: IHasChildren) => (
  <Typography
    component="span"
    sx={{
      backgroundColor: '#ffffff',
      p: 1,
      borderRadius: 1,
      width: '100%',
      display: 'inline-block',
    }}
  >
    {children}
  </Typography>
);

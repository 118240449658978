import React from 'react';
import { Box, Container, Stack } from '@mui/material';
import { IHasChildren } from '../../../types';
import WSNav from '../../features/ws/WSNav';
import { DRAWER_WIDTH } from '../../lib/config';
import { useNavContext } from '../../providers/NavProvider';
import AppCrumbs from '../AppCrumbs';
import Nav from '../Nav';
import ApplicationBar from './ApplicationBar';
import { DrawerHeader, Main } from './Styled';

export const ScreenWrapper = ({ children }: IHasChildren) => (
  <Container className="ScreenWrapper" sx={{ pt: 2, textAlign: 'left' }}>
    <Stack spacing={2}>{children}</Stack>
  </Container>
);

const AppLayout = ({ children }: IHasChildren) => {
  const { open } = useNavContext();

  return (
    <Stack
      className="AppLayout"
      direction="column"
      sx={{ padding: 0, backgroundColor: '#f5f5f5', minHeight: '100vh' }}
    >
      <ApplicationBar />
      <Box className="AppContent" sx={{ display: 'flex' }}>
        <Nav width={DRAWER_WIDTH} />
        <Main className="Main" open={open}>
          <DrawerHeader className="DrawerHeader" />
          {/* see ApplicationBar for AppCrumbs and WSNav */}
          {/* <Container>
            <Stack spacing={4}>
              <AppCrumbs />
              <WSNav />
            </Stack>
          </Container> */}
          {children}
        </Main>
      </Box>
    </Stack>
  );
};

export default AppLayout;

import React from 'react';
import Box from '@mui/material/Box';
import { t } from 'i18next';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import EditIcon from '@mui/icons-material/Edit';
import RestoreIcon from '@mui/icons-material/Restore';
import DeleteIcon from '@mui/icons-material/Delete';

import { TableCell, TableRow, Menu, MenuItem, Chip, Switch } from 'requiro-ui';

type KissflowSignProps = {
  row: any;
  onMenuClick: Function;
};

const KissflowSign = ({ row, onMenuClick }: KissflowSignProps) => {
  const [menuAnchorEl, handleMenuAnchorEl] = React.useState<boolean>(null);
  const open = Boolean(menuAnchorEl);

  const currentSign = row;

  const onMoreClick = (event) => {
    handleMenuAnchorEl(event.currentTarget);
  };

  const onMenuClose = () => {
    handleMenuAnchorEl(null);
  };

  const renderMenuItemContent = (text, Icon) => {
    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          color: '#1A416B',
        }}
      >
        <Icon sx={{ fontSize: '16px', marginRight: '8px' }} />
        <span>{text}</span>
      </Box>
    );
  };

  if (!currentSign) return null;

  return (
    <TableRow
      hover={true}
      tabIndex={-1}
      key={currentSign.id}
      sx={{
        cursor: 'pointer',
      }}
    >
      <TableCell align="left">{currentSign.kfProcessId}</TableCell>
      <TableCell align="left">{currentSign.kfAccountId}</TableCell>
      <TableCell align="left">
        <Chip
          color="lightBlue"
          icon={<CheckCircleOutlineIcon />}
          label={t('configured', 'Configurato')}
        />
      </TableCell>
      <TableCell align="left">
        <Switch
          checked={currentSign.enabled}
          onChange={() => {
            onMenuClick(
              currentSign,
              currentSign.enabled ? 'disable' : 'enable',
            );
          }}
        />
      </TableCell>
      <TableCell align="right" sx={{ maxWidth: '30px' }}>
        <SettingsOutlinedIcon
          style={{ bottom: '-3px', position: 'relative' }}
          onClick={() => {
            onMenuClick(currentSign, 'configure');
          }}
        />
      </TableCell>
      <TableCell align="right" sx={{ maxWidth: '30px' }}>
        <MoreVertOutlinedIcon
          style={{ bottom: '-3px', position: 'relative' }}
          onClick={onMoreClick}
        />

        <Menu
          id={`${currentSign.id}-menu`}
          anchorEl={menuAnchorEl}
          open={open}
          onClose={onMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          sx={{ padding: '8px' }}
        >
          <MenuItem
            value="configure"
            onClick={() => {
              onMenuClick(currentSign, 'configure');
            }}
          >
            {renderMenuItemContent(t('configure', 'Configura'), TuneIcon)}
          </MenuItem>
          {/* <MenuItem
            onClick={() => {
              onMenuClick(currentClass, 'subscription');
            }}
          >
            {renderMenuItemContent(t('subscription', 'Subscription'), EditIcon)}
          </MenuItem>
          <MenuItem
            onClick={() => {
              onMenuClick(currentClass, 'history');
            }}
          >
            {renderMenuItemContent(t('history', 'Storico'), RestoreIcon)}
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              onMenuClick(currentSign, 'delete');
              handleMenuAnchorEl(null);
            }}
          >
            {renderMenuItemContent(t('delete', 'Elimina'), DeleteIcon)}
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default KissflowSign;

import '../App.css';
import { t } from 'i18next';
import { get, isEmpty, isNil } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Autocomplete, Button, Input, Switch } from 'requiro-ui';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, FormControlLabel, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Customer } from '../../types';
import { getUserExt } from '../api/users';
import { createUser, updateUser } from '../api/workspaceUsers';
import AddCustomersDialog from '../components/dialogs/AddCustomersDialog';
import { ScreenWrapper } from '../components/layouts/AppLayout';
import MessageBox from '../components/MessageBox';
import ScreenToolbar from '../components/ScreenToolbar';
import TitleToolbar from '../components/TitleToolbar';
import { wsActions } from '../lib/config';
import { useCustomersInWorkspace } from '../lib/queries';
import { useCrumbs } from '../lib/useCrumbs';
import { useAppInitiator } from '../providers/AppInitiator';
import { useApplicationContext } from '../providers/ApplicationProvider';
import {
  searchEntityByEmailJSON,
  searchEntityBySpecifiedKey,
} from '../utilities/UtilitiesFunction';

function WorkspaceUserFormPage(props: any) {
  const { currentWorkspace, selectedCustomersForAddingInWorkspace } =
    useApplicationContext();
  const { boot } = useAppInitiator();
  const location = useLocation();
  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();

  // const name = currentWorkspace.name;

  const { workspaceUser } = location.state ? location.state : ({} as any); // Type Casting, then you can get the params passed via router
  const idWorkspace = currentWorkspace.id;
  const realmWorkspace = currentWorkspace.realm;

  const [user, setUser] = React.useState<any>(
    workspaceUser ? workspaceUser : { federated: false, billable: true },
  );

  console.log('user', user);

  const [inputFederated, setInputFederated] = React.useState<boolean>(
    get(user, 'federated', ''),
  );
  const [inputBillable, setInputBillable] = React.useState<boolean>(
    get(user, 'billable', ''),
  );

  const [title, setTitle] = React.useState(
    user && user.id && user.id !== undefined
      ? 'Modifica utente del workspace ' + idWorkspace
      : 'Creazione utente del workspace ' + idWorkspace,
  );
  const [enableSaveButton, setEnableSaveButton] = React.useState(false);
  const [modifyMode, SetModifyMode] = React.useState(
    workspaceUser ? true : false,
  );
  const [enableOtherFields, setEnableOtherFields] = React.useState(modifyMode);
  const [enableSearchButton, setEnableSearchButton] = React.useState(false);
  const [userFound, setUserFound] = React.useState(false);
  const [users, setUsers] = React.useState<any[]>([]);
  const [lastExistentEmailSelected, setLastExistentEmailSelected] =
    React.useState<string>('');

  const navigate = useNavigate();

  const crumbs = [
    { to: '/ws', label: 'Workspaces' },
    { to: `/ws/${currentWorkspace.id}`, label: `${currentWorkspace.name}` },
    { to: `/ws/${currentWorkspace.id}/users`, label: wsActions.users },
  ];

  if (modifyMode) {
    //@ts-ignore
    crumbs.push({ label: user.email });
    //@ts-ignore
    crumbs.push({ label: 'Modifica' });
  } else {
    //@ts-ignore
    crumbs.push({ label: 'Crea' });
  }

  useCrumbs(crumbs);

  // - start TO EXTRACT
  const queryInfo = useCustomersInWorkspace(currentWorkspace.id);

  /*
  if (queryInfo.isLoading) {
    return <MessageBox.Loading />;
  }

  if (queryInfo.isError) {
    return <MessageBox.QueryError query={queryInfo} />;
  }
  */

  const customers = get(queryInfo, 'data.data.data', []) as Customer[];
  customers.forEach((customer: any) => {
    (customer.CompanyGroupCode = customer.principalCustomerCode),
      (customer.CustomerCode = customer.customerCode),
      (customer.CompanyName = customer.companyName),
      (customer.VatNumber = customer.vatNumber);
  });
  //context.setCustomersOfSelectedWorkspace(customers);
  // - end TO EXTRACT

  useEffect(() => {
    setEnableSaveButton(controlEnableSaveButton());
    if (user.email && user.email.length > 4) {
      findUser();
    } else if (user.email && user.email.length <= 4) {
      setUsers([]);
      setUser({ ...user });
    }
  }, [user.email]);

  useEffect(() => {
    setEnableSaveButton(controlEnableSaveButton());
  }, [user]);

  const handleTextfieldsChange = (event: any) => {
    if (event && event.target) {
      let userMap = {} as any;

      // userMap[event.target.id] = event.target.value;

      // edit nested prop
      if (event.target.id === 'requiroHubUser') {
        userMap['attributes'] = { 'requiroHub.user': event.target.value };
      } else {
        userMap[event.target.id] = event.target.value;
      }

      if (event.target.id === 'email') {
        //Ho digitato l'email sempre a mano senza cliccare su eventuali suggerimenti
        if (!modifyMode) {
          userMap['id'] = null;
        }
        userMap['username'] = event.target.value;
        //userMap["firstName"] = null;
        //userMap["lastName"] = null;
        //userMap["requiroHubUser"] = null;
        let i = searchEntityBySpecifiedKey('email', users, userMap);
        if (i > -1) {
          setUser(users[i]);
          setUserFound(true);
          return;
        } else {
          setUserFound(false);
        }
      } else {
        if (event.target.id && event.target.id.startsWith('email-option')) {
          //Ho cliccato su una delle scelte dell'autocompletamento
          setUserFound(true);
        } else {
          // update userMap after all checks!!!
          userMap[event.target.id] = event.target.value;
        }
      }
      setUser({ ...user, ...userMap });
    }
  };

  const controlEnableSaveButton = () => {
    return (
      user !== null && user.email !== null && user.firstName && user.lastName
    );
  };

  const clearFields = () => {
    if (modifyMode) {
      setUser({ email: user.email, id: user.id });
    } else {
      setUser({ email: user.email });
    }
  };

  const findUser = () => {
    console.log('SEARCH');
    setEnableOtherFields(true);
    getUserExt(
      boot.appServicesBaseUrl,
      'realm=' +
        realmWorkspace +
        '&exact=false&briefRepresentation=false&email=' +
        user.email,
    )
      .then((response: any) => {
        // console.log({ response });
        const temp = get(response, 'data.data.users');
        if (temp) {
          // let existentUsersList = response.data.data.users;
          setUsers(temp);
          // * refill form with data
          const { customerCodeBilling, customerCode } = workspaceUser;

          const userInfo = temp[0];

          setUser({
            ...userInfo,
            customerCode: customerCode,
            customerCodeBilling: customerCodeBilling,
          });
        } else {
          setUserFound(false);
          // clearFields();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveUser = () => {
    console.log('SAVE');
    if (modifyMode) {
      //Sono in modifica di un utente esistente
      modify();
    } else {
      create();
    }
  };

  const create = () => {
    //let newUser = { ...user, ...{ username: user.email, state: 1 } };
    let newUser = { ...user, ...{ username: user.email } };
    createUser(boot.appServicesBaseUrl, idWorkspace, newUser)
      .then((response: any) => {
        enqueueSnackbar(t('res.saveOK'), { variant: 'success' });
        navigate(-1);
      })
      .catch((err) => {
        // console.log(err);
        if (err.response.data.status.code < 500)
          enqueueSnackbar(err.response.data.status.error.message, {
            variant: 'error',
          });
      });
  };

  const modify = () => {
    updateUser(boot.appServicesBaseUrl, idWorkspace, user.id, user)
      .then((response: any) => {
        enqueueSnackbar(t('res.saveOK'), { variant: 'success' });
        navigate(-1);
      })
      .catch((err) => {
        // console.log(err);
        if (err.response.data.status.code < 500)
          enqueueSnackbar(err.response.data.status.error.message, {
            variant: 'error',
          });
      });
  };

  const validateEmail: any = (email: string | null) => {
    if (email) {
      let regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(email.toLowerCase());
    }
  };

  const handleChangeOfSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    let userMap = {} as any;
    userMap['state'] = event.target.checked ? true : false;
    setUser({ ...user, ...userMap });
  };

  const actionForSelectedCustomers = () => {
    // if (
    //   selectedCustomersForAddingInWorkspace &&
    //   selectedCustomersForAddingInWorkspace.length > 0
    // ) {
    //   let userDatiAggiuntivi = {} as any;
    //   userDatiAggiuntivi.customerCodeBilling =
    //     selectedCustomersForAddingInWorkspace[0].billingCode;
    //   setUser({ ...user, ...userDatiAggiuntivi });
    // }

    if (
      !isNil(selectedCustomersForAddingInWorkspace) &&
      !isEmpty(selectedCustomersForAddingInWorkspace)
    ) {
      const selectedCustomer = selectedCustomersForAddingInWorkspace[0];
      const moreData = {
        customerCode: selectedCustomer.CustomerCode,
        customerCodeBilling:
          selectedCustomer.BillingCode || selectedCustomer.billingCode,
      };
      setUser({ ...user, ...moreData });
    }
  };

  const getRequiroHubUser = (user) => {
    // NOTE: attenzione alla prop 'requirohub.user' in fase di modifica del form
    return user &&
      user.attributes &&
      user.attributes['requirohub.user'] &&
      user.attributes['requirohub.user'][0]
      ? JSON.parse(user.attributes['requirohub.user'][0])[
          currentWorkspace.appCode
        ]
      : user.requiroHubUser
      ? user.requiroHubUser
      : '';
  };

  return (
    <ScreenWrapper>
      <TitleToolbar
        title={params.id_user ? `Modifica l'utente` : `Crea l'utente`}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {userFound || user.id != null ? (
              <CheckCircleIcon
                sx={{ color: 'green', mr: 1 }}
                style={{ marginBottom: '10px' }}
              />
            ) : (
              <CancelIcon sx={{ color: 'red', mr: 1, my: 0.5 }} />
            )}
            <div style={{ width: '95%' }}>
              <Autocomplete
                freeSolo
                id="email"
                disableClearable
                disabled={modifyMode}
                value={user.email}
                label="Email"
                onInputChange={handleTextfieldsChange}
                onChange={(event: any, newValue: any) => {
                  let userMap = {} as any;
                  userMap.email = newValue;
                  setLastExistentEmailSelected(newValue);
                  //setUser({ ...user, ...userMap });
                  if (users[0]) {
                    let i = searchEntityByEmailJSON(users, newValue);
                    setUser({ ...user, ...users[i] });
                  }
                  setUserFound(true);
                }}
                options={users.map((user: any) => user.email)}
                renderInput={(params: any) => (
                  <Input
                    {...params}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    InputProps={{ ...params.InputProps, type: 'search' }}
                  />
                )}
              />
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            required
            id="firstName"
            name="firstName"
            label="Nome"
            fullWidth
            variant="outlined"
            disabled={!enableOtherFields}
            value={get(user, 'firstName', '')}
            onChange={handleTextfieldsChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            required
            id="lastName"
            name="lastName"
            label="Cognome"
            fullWidth
            variant="outlined"
            disabled={!enableOtherFields}
            value={user && user.lastName ? user.lastName : ''}
            defaultValue={user && user.lastName ? user.lastName : ''}
            onChange={handleTextfieldsChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            //required
            id="requiroHubUser"
            name="requiroHubUser"
            label="Requiro Hub User"
            fullWidth
            variant="outlined"
            // disabled={
            //   /* (!enableOtherFields && !modifyMode) ||
            //   userFound || */
            //   !!getRequiroHubUser(user)
            // }
            //value={user && user.requiroHubUser ? user.requiroHubUser : ''}
            /*defaultValue={
                  user && user.requiroHubUser ? user.requiroHubUser : ''
                }*/
            value={getRequiroHubUser(user)}
            onChange={handleTextfieldsChange}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Input
            //required
            id="customerCode"
            name="customerCode"
            label="Customer Code"
            fullWidth
            variant="outlined"
            disabled={true}
            value={get(user, 'customerCode', '')}
            onChange={handleTextfieldsChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <FormControlLabel
              sx={{ ml: '6px', mr: '6px' }}
              control={
                <Switch
                  size="small"
                  checked={inputFederated}
                  color="secondary"
                  onChange={() => {
                    setUser({ ...user, ...{ federated: !inputFederated } });
                    setInputFederated(!inputFederated);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={
                <span style={{ marginLeft: '16px' }}>
                  {`Utente federato: ${inputFederated ? 'SI' : 'NO'}`}
                </span>
              }
            />
            <FormControlLabel
              sx={{ ml: '6px', mr: '6px' }}
              control={
                <Switch
                  size="small"
                  checked={inputBillable}
                  color="secondary"
                  onChange={() => {
                    setUser({ ...user, ...{ billable: !inputBillable } });
                    setInputBillable(!inputBillable);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={
                <span style={{ marginLeft: '16px' }}>
                  {`Fatturabile: ${inputBillable ? 'SI' : 'NO'}`}
                </span>
              }
            />
          </Box>
        </Grid>
        {/*<Grid item xs={12}>
              <Switch
                checked={user.state ? (user.state === 1 ? true : false) : true}
                id="state"
                onChange={handleChangeOfSwitch}
                color="secondary"
              />
              </Grid> */}
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Stack spacing={2} direction="row">
            <AddCustomersDialog
              actionForSelectedCustomers={actionForSelectedCustomers}
              singleSelection={true}
              preFiltering={false}
              customButtonLabel="Customer"
              customers={customers}
              onlyWorkspaceCustomers={true}
            ></AddCustomersDialog>
            <Button
              disabled={!enableSaveButton}
              onClick={saveUser}
              variant="contained"
              color="primary"
              size="small"
            >
              Salva
            </Button>
          </Stack>
        </Grid>
        {/* <MessageDialog handleClose={handleCloseMessageDialog} text={textForMessageDialog} open={openMessageDialog} title={"Esito operazione"}></MessageDialog> */}
      </Grid>
    </ScreenWrapper>
  );
}

export default WorkspaceUserFormPage;

import { get } from 'lodash';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Item, Name, Value } from '../../components/display/Typo';
import { ScreenWrapper } from '../../components/layouts/AppLayout';
import MessageBox from '../../components/MessageBox';
import { ScreenToolbarToolsComposite } from '../../components/ScreenToolbarTools';
import TitleToolbar from '../../components/TitleToolbar';
import { WorkspaceInfo } from '../../generated/axios';
import { wsActions } from '../../lib/config';
import { useSingleWorkspace } from '../../lib/queries';
import { useCrumbs } from '../../lib/useCrumbs';
import { useApplicationContext } from '../../providers/ApplicationProvider';

const WSItem = () => {
  const queryInfo = useSingleWorkspace();

  const { currentWorkspace } = useApplicationContext();
  const location = useLocation();

  const name = currentWorkspace
    ? currentWorkspace.name
    : get(location, 'state.workspace.name', '---');

  useCrumbs([
    { to: '/ws', label: 'Workspaces' },
    { label: `${name}` },
    { label: wsActions.info },
  ]);

  if (queryInfo.isLoading) {
    return <MessageBox.Loading />;
  }

  if (queryInfo.isError) {
    return <MessageBox.QueryError query={queryInfo} />;
  }

  const data = get(queryInfo, 'data.data.data', {}) as WorkspaceInfo;

  return (
    <ScreenWrapper>
      {/* <AppCrumbs /> */}
      <TitleToolbar title="Dettaglio workspace">
        <ScreenToolbarToolsComposite.EditButton />
      </TitleToolbar>
      {/* <Paper elevation={3} sx={{ p: 2 }}> */}
      <div style={{ margin: '16px -16px', display: 'flex', flexWrap: 'wrap' }}>
        <div
          style={{
            width: '33.333333%',
            padding: '0 16px',
            marginBottom: '16px',
          }}
        >
          <Name>App Code</Name>
          <Value>{data.appCode}</Value>
        </div>
        <div
          style={{
            width: '66.666666%',
            padding: '0 16px',
            marginBottom: '16px',
          }}
        >
          <Name>Denominazione sociale</Name>
          <Value>{data.principalCompanyName}</Value>
        </div>

        <div
          style={{
            width: '50%',
            padding: '0 16px',
            marginBottom: '16px',
          }}
        >
          <Name>Nome</Name>
          <Value>{data.name}</Value>
        </div>
        <div
          style={{
            width: '25%',
            padding: '0 16px',
            marginBottom: '16px',
          }}
        >
          <Name>Realm</Name>
          <Value>{data.realm}</Value>
        </div>
        <div
          style={{
            width: '25%',
            padding: '0 16px',
            marginBottom: '16px',
          }}
        >
          <Name>Workspace id</Name>
          <Value>{data.id}</Value>
        </div>

        <div
          style={{
            width: '50%',
            padding: '0 16px',
            marginBottom: '16px',
          }}
        >
          <Name>Customer Code (Principale) </Name>
          <Value>{data.principalCustomerCode}</Value>
        </div>

        <div
          style={{
            width: '50%',
            padding: '0 16px',
            marginBottom: '16px',
          }}
        ></div>

        <div
          style={{
            width: '33.333333%',
            padding: '0 16px',
            marginBottom: '16px',
          }}
        >
          <Name>App collegate</Name>
          <Value>{data.appCount}</Value>
        </div>
        <div
          style={{
            width: '33.333333%',
            padding: '0 16px',
            marginBottom: '16px',
          }}
        >
          <Name>Ragioni sociali</Name>
          <Value>{get(data, 'customers', []).length}</Value>
        </div>
        <div
          style={{
            width: '33.333333%',
            padding: '0 16px',
            marginBottom: '16px',
          }}
        >
          <Name>Gruppi</Name>
          <Value>{data.groupCount}</Value>
        </div>
        <div
          style={{
            width: '33.333333%',
            padding: '0 16px',
            marginBottom: '16px',
          }}
        >
          <Name>Utenti</Name>
          <Value>{data.usersCount}</Value>
        </div>
        <div
          style={{
            width: '100%',
            padding: '0 16px',
            marginBottom: '16px',
          }}
        >
          <Name>Descrizione</Name>
          <Value>{data.description}</Value>
        </div>
      </div>
    </ScreenWrapper>
  );
};

export default WSItem;

import React from 'react';

const Img = ({
  src,
  alt,
  altSrc,
  className = '',
}: {
  src: string;
  alt: string;
  altSrc: string;
  className?: string;
}) => {
  const onErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    event.currentTarget.src = altSrc;
  };

  return (
    <img className={className} src={src} alt={alt} onError={onErrorHandler} />
  );
};

export default Img;

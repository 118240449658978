import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Form from './Form';
import Job from './Job';
import Jobs from './Jobs';

const JobRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Jobs />} />
      <Route path="new" element={<Form />} />
      <Route path="edit/:id" element={<Form />} />
      <Route path=":id" element={<Job />} />
    </Routes>
  );
};

export default JobRoutes;

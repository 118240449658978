import { t } from 'i18next';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Customer } from '../../../types';
import { fetchCustomers } from '../../api/customerApi';
import { addCustomers, deleteCustomers, getWorkspace } from '../../api/workspaceApi';
import AddCustomersDialog from '../../components/dialogs/AddCustomersDialog';
import MessageDialog from '../../components/dialogs/MessageDialog';
import { ScreenWrapper } from '../../components/layouts/AppLayout';
import CustomersTable from '../../components/tables/CustomersTable';
import TitleToolbar from '../../components/TitleToolbar';
import { wsActions } from '../../lib/config';
import { useCrumbs } from '../../lib/useCrumbs';
import { useAppInitiator } from '../../providers/AppInitiator';
import { useApplicationContext } from '../../providers/ApplicationProvider';

const WSCustomers = () => {
  // - start TO EXTRACT
  //const queryInfo = useSingleWorkspace();
  const {
    currentWorkspace,
    customersOfSelectedWorkspace,
    selectedCustomersForAddingInWorkspace,
    changeWorkspace,
  } = useApplicationContext();
  const context = useApplicationContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [enableSaveButton, setEnableSaveButton] = React.useState(false);
  const [textForMessageDialog, setTextForMessageDialog] = React.useState('');
  const [openMessageDialog, setOpenMessageDialog] = React.useState(false);
  const [customersList, setCustomersList] = React.useState<Customer[]>(
    customersOfSelectedWorkspace,
  );
  const { boot } = useAppInitiator();

  const name = currentWorkspace
    ? currentWorkspace.name
    : get(location, 'state.workspace.name', '');

  useCrumbs([
    { to: '/ws', label: 'Workspaces' },
    { to: `/ws/${currentWorkspace.id}`, label: `${name}` },
    { label: wsActions.customers },
  ]);
  // - end TO EXTRACT

  /*
  if (queryInfo.isLoading) {
    return <MessageBox.Loading />;
  }

  if (queryInfo.isError) {
    return <MessageBox.QueryError query={queryInfo} />;
  }

  const data = get(queryInfo, 'data.data.data', {}) as WorkspaceInfo;
  const customers = get(
    queryInfo,
    'data.data.data.customers',
    [],
  ) as Customer[];
  customers.forEach((customer: any) => {
    (customer.CompanyGroupCode = customer.principalCustomerCode),
      (customer.CustomerCode = customer.customerCode),
      (customer.CompanyName = customer.companyName),
      (customer.VatNumber = customer.vatNumber);
  });
  //context.setCustomersOfSelectedWorkspace(customers);
  */

  useEffect(() => {
    //fetchWorkspaceExtraInfo();
    fetchCustomerGroupCodeOfPrincipalCustomer();
  }, []);

  useEffect(() => {
    setEnableSaveButton(controlEnableSaveButton);
  }, [customersOfSelectedWorkspace]);

  //TODO: Da rifattorizzare
  async function fetchCustomerGroupCodeOfPrincipalCustomer() {
    fetchCustomers(
      boot.appCoreServicesBaseUrl,
      'infoCustomer=' + currentWorkspace.principalCustomerCode,
    )
      .then((response: any) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.data &&
          response.data.data.data[0]
        ) {
          let companyGroupCode = response.data.data.data[0].CompanyGroupCode;
          changeWorkspace({ ...currentWorkspace, ...{ companyGroupCode } });
          fetchWorkspaceExtraInfo(companyGroupCode);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //TODO: Da rifattorizzare
  async function fetchWorkspaceExtraInfo(companyGroupCode: string = '') {
    getWorkspace(
      boot.appServicesBaseUrl,
      currentWorkspace && currentWorkspace.id ? '/' + currentWorkspace.id : '',
    )
      .then((response: any) => {
        console.log(companyGroupCode);
        // console.log(response);
        if (response && response.data && response.data.data && context) {
          let listaCustomers: Customer[] = [];
          response.data.data.customers.forEach((element: any) => {
            let c = {
              //CompanyGroupCode: "",
              //CompanyGroupCode: response.data.data.principalCustomerCode,
              CompanyGroupCode: companyGroupCode,
              CustomerCode: element.customerCode,
              CompanyName: element.companyName,
              VatNumber: element.vatNumber,
              BillingCode: response.data.data.customerCodeBilling,
            };
            listaCustomers.push(c);
          });
          //context.setCustomersOfSelectedWorkspace(response.data.data.customers ? response.data.data.customers : []);
          context.setCustomersOfSelectedWorkspace(listaCustomers);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  const deleteCustomerFromWorkspace = (customer: Customer) => {
    // console.log(customer);
    deleteCustomers(
      boot.appServicesBaseUrl,
      currentWorkspace.id,
      customer.CustomerCode,
    )
      .then((response: any) => {
        // console.log(response);
        //TODO: Usare t
        setTextForMessageDialog(
          t('res.saveOK', 'Eliminazione effettuata con successo.'),
        );
        fetchWorkspaceExtraInfo(currentWorkspace.companyGroupCode);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const controlEnableSaveButton = () => {
    return (
      customersOfSelectedWorkspace && customersOfSelectedWorkspace.length > 0
    );
  };

  const handleCloseMessageDialog = () => {
    setOpenMessageDialog(false);
    navigate('../../ws');
  };

  const saveCustomers = () => {
    let workspaceDatiAggiuntivi = {} as any;
    workspaceDatiAggiuntivi.customers = selectedCustomersForAddingInWorkspace;
    if (workspaceDatiAggiuntivi && workspaceDatiAggiuntivi.customers) {
      workspaceDatiAggiuntivi.customers.forEach((customer: any) => {
        customer.customerCode = customer.CustomerCode;
        customer.companyName = customer.CompanyName;
        customer.vatNumber = customer.VatNumber;
        customer.companyGroupCode = customer.CompanyGroupCode;
        customer.billingCode = customer.BillingCode;
      });
    }
    addCustomersToWorkspace({
      ...currentWorkspace,
      ...workspaceDatiAggiuntivi,
    });
  };

  const addCustomersToWorkspace = (workspace: any) => {
    addCustomers(boot.appServicesBaseUrl, workspace.id, workspace.customers)
      .then((response: any) => {
        console.log(response);
        setTextForMessageDialog(
          t('res.saveOK', 'Salvataggio effettuato con successo.'),
        );
        //setOpenMessageDialog(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <ScreenWrapper>
      <TitleToolbar title="Clienti nel workspace">
        <AddCustomersDialog
          actionForSelectedCustomers={saveCustomers}
          singleSelection={false}
          preFiltering={true}
        />
      </TitleToolbar>
      <CustomersTable
        title="Lista clienti del workspace"
        rows={customersOfSelectedWorkspace}
        visualizeMassiveDelete={false}
        deleteFunction={deleteCustomerFromWorkspace}
        singleSelection={true}
        visualizePrincipalCustomer={true}
        deletableRow={true}
      />

      <MessageDialog
        handleClose={handleCloseMessageDialog}
        text={textForMessageDialog}
        open={openMessageDialog}
        title={'Esito operazione'}
      />
    </ScreenWrapper>
  );
};

export default WSCustomers;

import axios from 'axios';
import { t } from 'i18next';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'requiro-ui';
import { ScreenWrapper } from '../../../../components/layouts/AppLayout';
import MessageBox from '../../../../components/MessageBox';
import TitleToolbar from '../../../../components/TitleToolbar';
import { wsActions } from '../../../../lib/config';
import { usePermissionSingleGroupQuery } from '../../../../lib/queries';
import { useCrumbs } from '../../../../lib/useCrumbs';
import { useAppInitiator } from '../../../../providers/AppInitiator';
import { useApplicationContext } from '../../../../providers/ApplicationProvider';
import { CollapsibleTable } from './CollapsibleTable';
import { rows } from './fakeData';

const PermissionsGroup = ({ edit }: { edit: boolean }) => {
  const { currentWorkspace } = useApplicationContext();
  const { isLoading, data, isError } = usePermissionSingleGroupQuery(edit);
  const name = get(currentWorkspace, 'name', '');
  const { boot } = useAppInitiator();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const { id, idGroup } = params;
  let rowApps;

  useCrumbs([
    { to: '/ws', label: 'Workspaces' },
    { to: `/ws/${currentWorkspace.id}`, label: `${name}` },
    { to: `/ws/${currentWorkspace.id}/groups`, label: wsActions.groups },
    { label: edit ? 'Modifica permessi' : 'Visualizza permessi' },
  ]);

  const handleClickSave = async () => {
    const url = `${boot.appServicesBaseUrl}/workspace/${id}/group/${idGroup}/permission`;
    const body = { apps: rowApps };
    try {
      await axios.put(url, body);
      enqueueSnackbar(t('res.saveOK'), { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(t('errors.generic'), { variant: 'error' });
      return <MessageBox.QueryError query={err} />;
    }
  };

  if (isLoading) {
    return <MessageBox.Loading />;
  }
  if (!isError && data && data.data && data.data.data && data.data.data.apps) {
    rowApps = data.data.data.apps;
    console.log('data after : ' + JSON.stringify(rowApps));
    return (
      <ScreenWrapper>
        <TitleToolbar
          title={edit ? 'Modifica permessi' : 'Visualizza permessi'}
        />
        {rowApps.length > 0 ? (
          <>
            <CollapsibleTable
              rows={rowApps}
              rowsCopy={[...rowApps]}
              edit={edit}
            />{' '}
            {edit && (
              <Button
                style={{ width: 'max-content' }}
                onClick={handleClickSave}
              >
                Salva
              </Button>
            )}
          </>
        ) : (
          <MessageBox.Info>
            Nessun permesso per il gruppo disponibile.
          </MessageBox.Info>
        )}
      </ScreenWrapper>
    );
  }
  return <MessageBox.Info>Qualcosa è andato storto.</MessageBox.Info>;
};
export default PermissionsGroup;

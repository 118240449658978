import { get } from 'lodash';
import React, { useState } from 'react';
import { Container, Stack } from '@mui/material';
import ScreenToolbar from '../../components/ScreenToolbar';
import { ScreenToolbarToolsComposite } from '../../components/ScreenToolbarTools';
import { useCrumbs } from '../../lib/useCrumbs';
import { useApplicationContext } from '../../providers/ApplicationProvider';
import { useCatalogApps } from '../catalog/helpers/queries';

const CatalogIndex = () => {
  useCrumbs([{ label: 'Gestione Permessi' }]);
  const { changeViewMode, currentViewMode } = useApplicationContext();
  const [searchKey, setSearchKey] = useState<any>('');
  const appsInfo = useCatalogApps(); // useAppsQuery();

  if (appsInfo.isError) {
    return <div>error</div>;
  }
  if (appsInfo.isLoading) {
    return <div>loading</div>;
  }

  const apps = get(appsInfo, 'data.data.data', []);

  const filteredData = searchKey
    ? apps.filter(
        ({ name }: { name: string }) => name.indexOf(searchKey) !== -1,
      )
    : apps;

  return (
    <Container style={{ padding: '20px', textAlign: 'left' }}>
      <Stack spacing={2}>
        <ScreenToolbar>
          {/* <ScreenToolbarToolsComposite.SearchBox
            options={filteredData}
            setSearchKey={setSearchKey}
            searchKey={searchKey}
          /> */}
          <ScreenToolbarToolsComposite.SearchBoxManual
            options={filteredData}
            handleSearch={setSearchKey}
            // searchKey={searchKey}
          />
          <ScreenToolbarToolsComposite.Grid action={changeViewMode} />
          <ScreenToolbarToolsComposite.List action={changeViewMode} />
          {/* <ScreenToolbarTools
            setSearchKey={setSearchKey}
            setViewMode={changeViewMode}
            searchKey={searchKey}
            options={filteredData}
          /> */}
        </ScreenToolbar>
        {/* 
        {currentViewMode === GRID_VIEW ? (
          <GridViewWrapper>
            {filteredData.map((item: AppModel) => (
              <GridItem key={item.id} data={item} />
            ))}
          </GridViewWrapper>
        ) : (
          <ListViewWrapper>
            {filteredData.map((item: AppModel) => (
              <ListItem key={item.id} data={item} />
            ))}
          </ListViewWrapper>
        )}
         */}
      </Stack>
    </Container>
  );
};

export default CatalogIndex;

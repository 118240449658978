import { t } from 'i18next';
import { chunk, get, isEmpty, isNil, sortBy } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { GroupIndexProps } from '../../../../types';
import { ScreenWrapper } from '../../../components/layouts/AppLayout';
import MessageBox from '../../../components/MessageBox';
import ScreenToolbar from '../../../components/ScreenToolbar';
import { ScreenToolbarToolsComposite } from '../../../components/ScreenToolbarTools';
import TitleToolbar from '../../../components/TitleToolbar';
import { WorkspaceGroupModel } from '../../../generated/axios';
import { wsActions } from '../../../lib/config';
import { addUserToGroup, deleteUserFromGroup, editWorkspaceGroup } from '../../../lib/mutations';
import { useGroupsOfCloudUserQuery, useWorkspaceGroupsQuery } from '../../../lib/queries';
import { useCrumbs } from '../../../lib/useCrumbs';
import { usePagination } from '../../../lib/usePagination';
import { useApplicationContext } from '../../../providers/ApplicationProvider';
import GroupIndexTableView from './GroupIndex.table.view';

export interface IActionHandlerParams {
  action: string;
  group: WorkspaceGroupModel;
}

export interface IFilters {
  names: string[];
}

const GroupIndex = (props: GroupIndexProps) => {
  const [groups, setGroups] = useState<WorkspaceGroupModel[]>([]);
  const [filters, setFilters] = useState<IFilters>({ names: [] });
  const [searchKey, setSearchKey] = useState<any>('');
  const {
    page,
    onPageChange,
    rowsPerPage,
    onRowsPerPageChange,
    pages,
    setPages,
  } = usePagination();

  // useEffect(() => {
  //   console.log('MOUNTING');
  // }, []);

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof WorkspaceGroupModel>('id');

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { currentWorkspace } = useApplicationContext();

  useCrumbs([
    { to: '/ws', label: 'Workspaces' },
    { to: `/ws/${currentWorkspace.id}`, label: `${currentWorkspace.name}` },
    { label: wsActions.groups },
  ]);

  let query;

  if (props.usedForDialogAddUserOnAGroup) {
    props.workspaceUser
      ? (query = useGroupsOfCloudUserQuery(props.workspaceUser.id, true, {
          onSuccess: (data: any) => {
            const info: WorkspaceGroupModel[] | [] = get(data, 'data.data', []);
            setGroups(info);
            refreshPages(info, orderBy, order);
            // const updatedPages = updatePages(info, searchKey, rowsPerPage);
            // setPages(updatedPages);

            const names = info.reduce(
              //@ts-ignore
              (prev: string[], curr: WorkspaceGroupModel) => {
                return [...prev, curr.name];
              },
              [],
            );
            //@ts-ignore
            setFilters({ ...filters, names });
          },
        }))
      : null;
  } else if (props.usedForDeleteUserFromGroup) {
    props.workspaceUser
      ? (query = useGroupsOfCloudUserQuery(props.workspaceUser.id, false, {
          onSuccess: (data: any) => {
            const info: WorkspaceGroupModel[] | [] = get(data, 'data.data', []);
            setGroups(info);
            refreshPages(info, orderBy, order);
            // const updatedPages = updatePages(info, searchKey, rowsPerPage);
            // setPages(updatedPages);

            const names = info.reduce(
              //@ts-ignore
              (prev: string[], curr: WorkspaceGroupModel) => {
                return [...prev, curr.name];
              },
              [],
            );
            //@ts-ignore
            setFilters({ ...filters, names });
          },
        }))
      : null;
  } else {
    query = useWorkspaceGroupsQuery({
      onSuccess: (data: any) => {
        const info: WorkspaceGroupModel[] | [] = get(data, 'data.data', []);
        setGroups(info);
        refreshPages(info, orderBy, order);
        // const updatedPages = updatePages(info, searchKey, rowsPerPage);
        // setPages(updatedPages);

        const names = info.reduce(
          //@ts-ignore
          (prev: string[], curr: WorkspaceGroupModel) => {
            return [...prev, curr.name];
          },
          [],
        );
        //@ts-ignore
        setFilters({ ...filters, names });
      },
    });
  }

  const onError = (error: any) => {
    enqueueSnackbar(t('errors.generic'), { variant: 'error' });
  };

  const editMutation = editWorkspaceGroup({
    onError,
    onSuccess: (data: WorkspaceGroupModel) => {
      enqueueSnackbar(t('res.saveOK'), { variant: 'success' });
      queryClient.invalidateQueries();
    },
  });

  const execEdit = (group: WorkspaceGroupModel) => {
    editMutation.mutate({
      idWorkspace: currentWorkspace.id,
      group: { ...group, state: group.state === 0 ? 1 : 0 },
    });
  };

  const addUserToGroupMutation = addUserToGroup({
    onError,
    onSuccess: () => {
      enqueueSnackbar(t('res.saveOK'), { variant: 'success' });
      queryClient.invalidateQueries();
    },
  });

  const deleteUserFromGroupMutation = deleteUserFromGroup({
    onError,
    onSuccess: () => {
      enqueueSnackbar(t('res.saveOK'), { variant: 'success' });
      queryClient.invalidateQueries();
    },
  });

  // on pagination event
  useEffect(() => {
    const updatedPages = updatePages(groups, searchKey, rowsPerPage);
    setPages(updatedPages);
  }, [page, rowsPerPage]);

  useEffect(() => {
    const updatedPages = updatePages(groups, searchKey, rowsPerPage);
    setPages(updatedPages);
  }, [searchKey]);

  const actionHandler = ({ action, group }: IActionHandlerParams) => {
    if (action === wsActions.toggleState) {
      execEdit(group);
    } else if (action === wsActions.edit) {
      navigate(`edit/${group.id}`);
    } else if (action === wsActions.permissionShow) {
      navigate(`${group.id}/permissions`);
    } else if (action === wsActions.permissionEdit) {
      navigate(`edit/${group.id}/permissions`);
    } else if (action === wsActions.add) {
      props.workspaceUser
        ? addToGroup(currentWorkspace.id, group.id, props.workspaceUser.id)
        : null;
    } else if (action === wsActions.deleteUserFromGroup) {
      props.workspaceUser
        ? deleteFromGroup(currentWorkspace.id, group.id, props.workspaceUser.id)
        : null;
    } else if (action === wsActions.info) {
      navigate(`${group.id}`);
    }
  };

  /**
   * filter data after refetch and on search key change
   * @param list
   * @returns
   */
  function updatePages(
    list: WorkspaceGroupModel[],
    searchKey: string,
    rowsPerPage: number,
  ) {
    if (isNil(searchKey) || isEmpty(searchKey)) {
      return chunk(list, rowsPerPage);
    }
    const temp = list.filter(
      (item) => item.name && item.name.indexOf(searchKey) > -1,
    );
    return chunk(temp, rowsPerPage);
  }

  const refreshPages = (groups: WorkspaceGroupModel[], _orderBy, _order) => {
    // reorder groups
    const orderedGroups = sortBy(groups, [_orderBy]) as WorkspaceGroupModel[];
    const temp = _order === 'asc' ? orderedGroups : orderedGroups.reverse();

    // refresh pages
    const updatedPages = updatePages(temp, searchKey, rowsPerPage);

    // reset page
    onPageChange({}, 0);
    setPages(updatedPages);
  };

  const sortRequestHandler = (e, key) => {
    const _order = key === orderBy && order === 'asc' ? 'desc' : 'asc';
    const _orderBy = key;
    refreshPages(groups, _orderBy, _order);
    setOrder(_order);
    setOrderBy(_orderBy);
  };

  if (query.isLoading) {
    return <MessageBox.Loading />;
  }
  if (query.isError) {
    return <MessageBox.QueryError query={query} />;
  }

  const addToGroup = (
    id: string,
    idGroup: string | number,
    idKeycloak: string,
  ) => {
    addUserToGroupMutation.mutate({
      id,
      idGroup,
      idKeycloak,
    });
  };

  const deleteFromGroup = (
    id: string,
    idGroup: string | number,
    idKeycloak: string,
  ) => {
    deleteUserFromGroupMutation.mutate({
      id,
      idGroup,
      idKeycloak,
    });
  };

  return (
    <ScreenWrapper>
      <TitleToolbar title="Gruppi nel workspace">
        {pathname.indexOf('groups') > -1 ? (
          <ScreenToolbarToolsComposite.AddButton />
        ) : null}

        {/* <ScreenToolbarToolsComposite.SearchBox
          options={groups}
          setSearchKey={setSearchKey}
          searchKey={searchKey}
        /> */}
        <ScreenToolbarToolsComposite.SearchBoxManual
          options={groups}
          handleSearch={setSearchKey}
          // searchKey={searchKey}
        />
        {/* <ScreenToolbarTools
          options={groups}
          setSearchKey={setSearchKey}
          searchKey={searchKey}
          canCreate={
            props.canCreate != null && props.canCreate != undefined
              ? props.canCreate
              : true
          }
        /> */}
      </TitleToolbar>
      {pages && pages[page] ? (
        <GroupIndexTableView
          list={pages[page]}
          actionHandler={actionHandler}
          sortProps={{
            orderBy,
            order,
            sortRequestHandler,
          }}
          paginationProps={{
            onRowsPerPageChange,
            onPageChange,
            page,
            rowsPerPage,
            count: groups.length,
          }}
          usedForDialogAddUserOnAGroup={props.usedForDialogAddUserOnAGroup}
          usedForDeleteUserFromGroup={props.usedForDeleteUserFromGroup}
        />
      ) : (
        <MessageBox.Info>Nessun gruppo disponibile.</MessageBox.Info>
      )}
    </ScreenWrapper>
  );
};

export default GroupIndex;

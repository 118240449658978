import axios from 'axios';
import Keycloak from 'keycloak-js';
import React, { createContext, useContext, useState } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { IAppInitiator, IAppInitiatorContext } from '../../types';
//import axios from 'axios';
import { setAuthHeader, setReqHeaders } from '../lib/AxiosInterceptors';
import { useBoot } from '../lib/useBoot';
import { useDomainCode } from '../lib/useDomainCode';
import { useRealm } from '../lib/useRealm';

/**
 * Contexts
 */
export const KeycloakContext = createContext<IAppInitiatorContext | null>(null);

/**
 * Provider
 */
const AppInitiator = ({ children }: IAppInitiator) => {
  const [token /*, setToken */] = useState('');
  const domainCode = useDomainCode();
  const boot = useBoot();
  // console.log(boot);
  const realm = useRealm(
    boot && boot.auth && boot.auth.spec ? boot.auth.spec.url : null,
    domainCode,
  );

  if (!boot || !realm) {
    return null;
  }

  const config = Keycloak({
    url: realm.authServer,
    //realm: realm.realm,
    realm: 'master',
    clientId: boot.auth.spec.clientId,
  });

  const keycloakContextValue = {
    realm,
    boot,
    keycloak: { ...config },
    token,
    axiosBaseServicesClient: axios.create({ baseURL: boot.appServicesBaseUrl }),
    axiosCoreServicesClient: axios.create({
      baseURL: boot.appCoreServicesBaseUrl,
    }),
  };

  const onEventHandler = (event: unknown, error: unknown) => {
    if (event === 'onTokenExpired') {
      // console.log('--> onTokenExpired', { event, error });
    }
  };
  const onTokenHandler = (tokens: any) => {
    //tokens: IToken
    // console.log('OnToken');
    if (tokens && tokens.token) {
      // add token to axios querystring
      // axios.interceptors.request.use(addToken(tokens.token), addTokenError);
      setAuthHeader(tokens.token);
      setReqHeaders(tokens.token);
      localStorage.setItem('rqx-sso-token', tokens.token);
      // console.log(tokens.token)
      //setToken(tokens.token);
    }
  };

  return (
    <KeycloakContext.Provider value={keycloakContextValue}>
      <ReactKeycloakProvider
        authClient={config}
        onEvent={onEventHandler}
        onTokens={onTokenHandler}
        LoadingComponent={
          <img src="loader.gif" alt="logo" className="imageCentered" />
        }
      >
        {children}
      </ReactKeycloakProvider>
    </KeycloakContext.Provider>
  );
};

/**
 * Custom Hook
 */
export function useAppInitiator() {
  const ctx = useContext(KeycloakContext);
  if (!ctx) throw new Error('AppInitiator Context cannot be undefined!');
  return ctx;
}

export default AppInitiator;

import { get } from 'lodash';
import { useLocation } from 'react-router-dom';
import { Container, Stack } from '@mui/material';
import ScreenToolbar from '../../components/ScreenToolbar';
import TitleToolbar from '../../components/TitleToolbar';
import { wsActions } from '../../lib/config';
import { useCrumbs } from '../../lib/useCrumbs';
import { useApplicationContext } from '../../providers/ApplicationProvider';

const WSApi = () => {
  // - start TO EXTRACT
  const { currentWorkspace } = useApplicationContext();
  const location = useLocation();

  const name = currentWorkspace
    ? currentWorkspace.name
    : get(location, 'state.workspace.name', '---');

  useCrumbs([
    { to: '/ws', label: 'Workspaces' },
    { label: `${name}` },
    { label: wsActions.api },
  ]);
  // - end TO EXTRACT
  return (
    <div>
      <Container style={{ padding: '20px', textAlign: 'left' }}>
        <Stack spacing={2}>
          <TitleToolbar title="Workspace API" />
          content here
        </Stack>
      </Container>
    </div>
  );
};

export default WSApi;

export interface IHeaderCell {
  id: string;
  label: string;
  sortable: boolean;
  type?: string;
  align?: string;
}

export const tableCells: IHeaderCell[] = [
  {
    id: 'id',
    label: 'Job ID',
    sortable: false,
  },
  {
    id: 'type',
    label: 'Tipo',
    sortable: false,
  },
  {
    id: 'createdBy',
    label: 'Creato da',
    sortable: false,
  },
  {
    id: 'state',
    label: 'Stato',
    sortable: false,
    type: 'tag',
  },
  {
    id: 'createdOn',
    label: 'Creato il',
    sortable: false,
    type: 'datetime',
  },
  {
    id: 'startedOn',
    label: 'Iniziato il',
    sortable: false,
    type: 'datetime',
  },
  {
    id: 'finishOn',
    label: 'Finito il',
    sortable: false,
    type: 'datetime',
  },
  {
    id: 'interrupt',
    label: 'Interrotto',
    sortable: false,
  },
  {
    id: 'action',
    label: 'Azioni',
    sortable: false,
    type: 'action',
    align: 'flex-end',
  },
];

// import logo from './logo.svg';
import './App.css';
import { SnackbarProvider } from 'notistack';
import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { theme } from 'requiro-ui';
import {
  Box,
  CircularProgress,
  CssBaseline,
  ThemeProvider,
} from '@mui/material';
import AppLayout from './components/layouts/AppLayout';
import AppInitiator from './providers/AppInitiator';
import { ApplicationProvider } from './providers/ApplicationProvider';
import NavProvider from './providers/NavProvider';
import { AppRouter } from './routes/routes';

const client = new QueryClient();

const Progress = () => (
  <Box sx={{ width: '100%', textAlign: 'center' }}>
    <CircularProgress />
  </Box>
);

function App() {
  return (
    <BrowserRouter>
      <SnackbarProvider>
        <RecoilRoot>
          <QueryClientProvider client={client}>
            <AppInitiator>
              <Suspense fallback={<Progress />}>
                <NavProvider>
                  <ThemeProvider theme={theme}>
                    <ApplicationProvider>
                      <AppLayout>
                        <AppRouter />
                      </AppLayout>
                    </ApplicationProvider>
                    <CssBaseline />
                  </ThemeProvider>
                </NavProvider>
              </Suspense>
            </AppInitiator>
            {/* <ReactQueryDevtools initialIsOpen={true} /> */}
          </QueryClientProvider>
        </RecoilRoot>
      </SnackbarProvider>
    </BrowserRouter>
  );
}

export default App;

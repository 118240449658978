import { atom, selector } from 'recoil';

export const catalogSearchKey = atom({
  key: 'CatalogSearchKey',
  default: '',
});

export const catalogApps = atom({
  key: 'CatalogApps',
  default: [],
});

export const filteredCatalogApps = selector({
  key: 'FilteredCatalogApps',
  get: ({ get }) => {
    const apps = get(catalogApps);
    const key = get(catalogSearchKey);

    return key
      ? apps.filter(({ name }: { name: string }) => {
          return name.toLowerCase().indexOf(key.toLowerCase()) !== -1;
        })
      : apps;
  },
});

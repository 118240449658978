import { useEffect } from 'react';
import { ICrumb } from '../../types';
import { useApplicationContext } from '../providers/ApplicationProvider';

export function useCrumbs(items?: ICrumb[]) {
  const { changeCrumbs, crumbs } = useApplicationContext();
  useEffect(() => {
    if (JSON.stringify(crumbs) !== JSON.stringify(items)) {
      changeCrumbs(items);
    }
  }, [items]);
}

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AddUser from '../features/users/AddUser';
import All from '../features/users/All';
import Form from '../features/users/Form';
import FormCreate from '../features/users/FormCreate';

const Users = () => {
  return (
    <Routes>
      <Route path="/" element={<All />} />
      <Route path="/new" element={<FormCreate />} />
      <Route path="/add" element={<AddUser />} />
      <Route path="/edit/:id" element={<Form />} />
    </Routes>
  );
};

export default Users;

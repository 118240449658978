import { get } from 'lodash';
import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useWorkspaces } from '../../../lib/queries';
import { workspaceAtom, workspaceIdAtom } from '../../../state/jobs';

export function useSearchWorkspace() {
  const [selectedWorkspace, setWorkspace] = useRecoilState(workspaceAtom);
  const workspaceId = useRecoilValue(workspaceIdAtom);
  const [searchKey, setSearchKey] = useState('');
  const workspacesQuery = useWorkspaces();

  const handleSearch = () => {
    const list = get(workspacesQuery, 'data.workspaces');
    const found = list ? list.find((item) => item.name === searchKey) : null;
    setWorkspace(found);
  };

  return {
    selectedWorkspace,
    workspacesQuery,
    searchKey,
    workspaceId,
    setSearchKey,
    handleSearch,
  };
}

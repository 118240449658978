import React, { ReactElement, ReactNode, useState } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton
} from '@mui/material';
import { IHasChildren } from '../../../types';

interface IConfirmButton {
  action: () => void;
  children: ReactNode;
  title?: string;
  message?: string;
}

interface IProps {
  toggle?: (value: boolean) => void;
}

interface IIconProps extends IProps, IHasChildren {}

/**
 * generic icon button, need children prop
 * @returns
 */
const Icon = ({ children, toggle }: IIconProps) => {
  return <IconButton onClick={() => toggle(true)}>{children}</IconButton>;
};

/**
 * delete icon button
 * @returns
 */
const DeleteIcon = ({ toggle }: IProps) => (
  <Icon toggle={toggle}>
    <DeleteOutlineIcon color="secondary" fontSize="medium" />
  </Icon>
);

/**
 * add icon button
 * @returns
 */
const AddIcon = ({ toggle }: IProps) => (
  <Icon toggle={toggle}>
    <AddCircleOutlineOutlinedIcon color="secondary" fontSize="medium" />
  </Icon>
);

const ConfirmButton = ({
  action,
  children,
  title = 'Richiesta conferma',
  message = 'Confermi la tua richiesta?',
}: IConfirmButton) => {
  const [open, toggle] = useState(false);

  const handleCancel = () => toggle(false);

  const handleOk = () => {
    handleCancel();
    action();
  };

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child as ReactElement<IProps>, { toggle }),
      )}

      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={open}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleCancel}
            variant="contained"
            color="secondary"
          >
            NO
          </Button>
          <Button onClick={handleOk} variant="contained" color="secondary">
            SI
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ConfirmButton.Icon = Icon;
ConfirmButton.DeleteIcon = DeleteIcon;
ConfirmButton.AddIcon = AddIcon;

export default ConfirmButton;

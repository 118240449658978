import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MessageDialogProps } from '../../../types';

export default function MessageDialog(props: MessageDialogProps) {
  const [text, setText] = React.useState(props.text ? props.text : '');

  useEffect(() => {
    setText(props.text);
  }, [props.text]);

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.title ? props.title : ''}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/*<Button onClick={handleClose}>Disagree</Button> */}
          <Button onClick={props.handleClose} autoFocus size="small">
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { useEffect, useState } from 'react';

export const useDomainCode = () => {
  const [domainCode, setDomainCode] = useState<any>('master');

  useEffect(() => {
    async function exec() {
      try {
        const regex = /^[a-z0-9]{6}$/;
        const domain =
          window.location.host.split('.') && window.location.host.split('.')[0];

        regex.test(domain) ? setDomainCode(domain) : setDomainCode('master');
      } catch (error) {
        console.log('useDomainCode failure', error);
      }
    }
    exec();
  }, []);

  return domainCode;
};

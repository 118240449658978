import { get, isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import AddIcon from '@mui/icons-material/Add';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import GridViewIcon from '@mui/icons-material/GridView';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import ViewListIcon from '@mui/icons-material/ViewList';
import { ScreenWrapper } from '../../../../components/layouts/AppLayout';
import MessageBox from '../../../../components/MessageBox';
import ScreenToolbar from '../../../../components/ScreenToolbar';
import { ScreenToolbarToolsComposite } from '../../../../components/ScreenToolbarTools';
import TitleToolbar from '../../../../components/TitleToolbar';
import { wsActions } from '../../../../lib/config';
import { useAppsQuery, useWorkspaceAppsQuery } from '../../../../lib/queries';
import { useCrumbs } from '../../../../lib/useCrumbs';
import { useApplicationContext } from '../../../../providers/ApplicationProvider';
import { dataViewMode } from '../../../../state/viewModeState';
import CatalogIndexView from '../../../catalog/CatalogIndex.view';
import { useCatalogApps } from '../../../catalog/helpers/queries';
import GridItem from './GridItem';
import ListItem from './ListItem';

function filterNotIncluded(queryInfo: any, queryWSAppInfo: any) {
  const apps = get(queryInfo, 'data.data.data', []);
  const addedApps = get(queryWSAppInfo, 'data.data.data', []);
  const addedAppsId = addedApps.map((app: any) => app.id);
  // console.log({ addedAppsId });

  return apps.filter((app: any) => !addedAppsId.includes(app.id));
}

const AppSelector = () => {
  // const { changeViewMode, currentViewMode } = useRecoilState(dataViewMode);
  const [currentViewMode, changeViewMode] = useRecoilState(dataViewMode);
  const [searchKey, setSearchKey] = useState<any>('');
  const queryInfo = useAppsQuery();
  const queryWSAppInfo = useWorkspaceAppsQuery();
  const { currentWorkspace } = useApplicationContext();
  // const location = useLocation();

  const name = currentWorkspace.name;

  useCrumbs([
    { to: '/ws', label: 'Workspaces' },
    { to: `/ws/${currentWorkspace.id}`, label: `${currentWorkspace.name}` },
    { to: `/ws/${currentWorkspace.id}/apps`, label: wsActions.app },
    { label: wsActions.add },
  ]);

  if (queryInfo.isLoading) {
    return <MessageBox.Loading />;
  }
  if (queryInfo.isError) {
    return <MessageBox.QueryError query={queryInfo} />;
  }

  // const apps = get(queryInfo, 'data.data.data', []);
  // const addedApps = get(queryWSAppInfo, 'data.data.data', []);
  // const addedAppsId = addedApps.map((app: any) => app.id);
  // console.log({ addedAppsId });

  // const notAddedApps = apps.filter((app: any) => !addedAppsId.includes(app.id));
  const notAddedApps = filterNotIncluded(queryInfo, queryWSAppInfo);

  const filteredData = searchKey
    ? notAddedApps.filter(
        ({ name }: { name: string }) => name.indexOf(searchKey) !== -1,
      )
    : notAddedApps;

  return (
    <ScreenWrapper>
      <TitleToolbar title="Aggiungi applicazioni">
        <ScreenToolbarToolsComposite>
          {/* <ScreenToolbarToolsComposite.Grid action={changeViewMode} />
          <ScreenToolbarToolsComposite.List action={changeViewMode} /> */}
          <ScreenToolbarToolsComposite.ToggleViewButton
            currentViewMode={currentViewMode}
            action={changeViewMode}
          />
          {/* <ScreenToolbarToolsComposite.SearchBox
            options={filteredData}
            setSearchKey={setSearchKey}
            searchKey={searchKey}
          /> */}
          <ScreenToolbarToolsComposite.SearchBoxManual
            options={filteredData}
            handleSearch={setSearchKey}
            // searchKey={searchKey}
          />
        </ScreenToolbarToolsComposite>
        {/* 
        <ScreenToolbarTools
              setSearchKey={setSearchKey}
              setViewMode={changeViewMode}
              searchKey={searchKey}
              options={filteredData}
              canCreate={false}
            /> */}
      </TitleToolbar>
      {isEmpty(notAddedApps) ? (
        <MessageBox.Info>Non ci sono risultati.</MessageBox.Info>
      ) : (
        <CatalogIndexView
          data={filteredData}
          viewMode={currentViewMode}
          GridItem={GridItem}
          ListItem={ListItem}
          children={undefined}
        />
      )}
    </ScreenWrapper>
  );
};

export default AppSelector;

import { orderBy } from 'lodash';
import React from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
    TableSortLabel
} from 'requiro-ui';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { Stack } from '@mui/material';
import ConfirmButton from '../../../components/buttons/ConfirmButton';
import { DisabledIcon, EnabledIcon } from '../../../components/Styled';
import { WorkspaceGroupModel } from '../../../generated/axios';
import { tableRowsPerPageOptions, wsActions } from '../../../lib/config';
import { dateTimeFormatter } from '../../../lib/utils';
import GridItem from './components/GridItem';
import ListItem from './components/ListItem';
import MenuRow from './components/MenuRow';
import { IActionHandlerParams } from './GroupIndex';
import { headCells, IHeadCell } from './helpers';

interface IProps {
  list: any;
  paginationProps: IPagination;
  sortProps: ISort;
  actionHandler: ({ action, group }: IActionHandlerParams) => void;
  usedForDialogAddUserOnAGroup?: boolean;
  usedForDeleteUserFromGroup?: boolean;
}

interface ISort {
  order: 'asc' | 'desc';
  orderBy: string;
  sortRequestHandler: (e: React.MouseEvent<unknown>, key: string) => void;
}

interface IPagination {
  onPageChange: (e: unknown, newPage: number) => void;
  onRowsPerPageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  page: number;
  rowsPerPage: number;
  count: number;
}

const GroupIndexTableView = ({
  list,
  actionHandler,
  paginationProps,
  sortProps,
  usedForDialogAddUserOnAGroup,
  usedForDeleteUserFromGroup,
}: IProps) => {
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow color="azure">
              {headCells.map((item: IHeadCell) => (
                <TableCell key={item.id} sx={{ fontWeight: '700' }}>
                  {item.sortable ? (
                    <TableSortLabel
                      active={sortProps.orderBy === item.id}
                      direction={
                        sortProps.orderBy === item.id ? sortProps.order : 'asc'
                      }
                      onClick={(e: React.MouseEvent<unknown>) => {
                        sortProps.sortRequestHandler(e, item.id);
                      }}
                    >
                      {item.label}
                    </TableSortLabel>
                  ) : (
                    <span>{item.label}</span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row: WorkspaceGroupModel) => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{dateTimeFormatter(row.createdOn)}</TableCell>
                <TableCell>{dateTimeFormatter(row.modifiedOn)}</TableCell>
                <TableCell wid>
                  {row.state === 1 ? <EnabledIcon /> : <DisabledIcon />}
                </TableCell>
                <TableCell>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ justifyContent: 'flex-end' }}
                  >
                    {usedForDialogAddUserOnAGroup ? (
                      <ConfirmButton
                        action={() =>
                          actionHandler({
                            action: wsActions.add,
                            group: row,
                          })
                        }
                      >
                        <ConfirmButton.AddIcon />
                      </ConfirmButton>
                    ) : // <IconButton
                    //   size="large"
                    //   onClick={() =>
                    //     actionHandler({
                    //       action: wsActions.add,
                    //       group: row,
                    //     })
                    //   }
                    // >
                    //   <AddCircleIcon />
                    // </IconButton>
                    usedForDeleteUserFromGroup ? (
                      // <IconButton
                      //   size="large"
                      //   onClick={() =>
                      //     actionHandler({
                      //       action: wsActions.deleteUserFromGroup,
                      //       group: row,
                      //     })
                      //   }
                      // >
                      //   <DeleteIcon />
                      // </IconButton>
                      <ConfirmButton
                        action={() =>
                          actionHandler({
                            action: wsActions.deleteUserFromGroup,
                            group: row,
                          })
                        }
                      >
                        <ConfirmButton.DeleteIcon />
                      </ConfirmButton>
                    ) : (
                      <>
                        <MenuRow actionHandler={actionHandler} data={row} />
                        {/* <IconButton
                          onClick={() =>
                            actionHandler({
                              action: wsActions.info,
                              group: row,
                            })
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            actionHandler({
                              action: wsActions.edit,
                              group: row,
                            })
                          }
                        >
                          <ModeEditOutlineOutlinedIcon />
                        </IconButton>
                        <Switch
                          checked={row.state === 1 ? true : false}
                          onChange={(_e: any) =>
                            actionHandler({
                              action: wsActions.toggleState,
                              group: row,
                            })
                          }
                        /> */}
                      </>
                    )}
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={tableRowsPerPageOptions}
        component="div"
        {...paginationProps}
      />
    </>
  );
};

export default GroupIndexTableView;

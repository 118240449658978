import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ConfirmationDialogProps } from '../../../types';

import { Button } from 'requiro-ui';

export default function MessageDialog(props: ConfirmationDialogProps) {
  const [text, setText] = React.useState(props.text ? props.text : '');

  useEffect(() => {
    setText(props.text);
  }, [props.text]);

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.title ? props.title : ''}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={props.handleClose} autoFocus>
            {props.closeLabel || 'Annulla'}
          </Button>
          <Button color="primary" onClick={props.handleConfirmation} autoFocus>
            {props.confirmationLabel || 'Conferma'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ScreenWrapper } from '../../components/layouts/AppLayout';
import MessageBox from '../../components/MessageBox';
import ScreenToolbar from '../../components/ScreenToolbar';
import { ScreenToolbarToolsComposite } from '../../components/ScreenToolbarTools';
import TitleToolbar from '../../components/TitleToolbar';
import { AppModel } from '../../generated/axios';
import { GRID_VIEW } from '../../lib/config';
import { useCrumbs } from '../../lib/useCrumbs';
import { useApplicationContext } from '../../providers/ApplicationProvider';
import { breadcrumbState } from '../../state/breadcrumbState';
import { dataViewMode } from '../../state/viewModeState';
import { GridViewWrapper, ListViewWrapper } from './CatalogStyles';
import GridItem from './components/GridItem';
import ListItem from './components/ListItem';
import { useCatalogApps } from './helpers/queries';
import { catalogSearchKey, filteredCatalogApps } from './helpers/recoil';

const CatalogIndex = () => {
  // const setCrumbs = useSetRecoilState(breadcrumbState);
  useCrumbs([{ label: 'Catalogo' }]);

  const appsInfo = useCatalogApps();
  // const { changeViewMode, currentViewMode } = useApplicationContext();
  const [currentViewMode, changeViewMode] = useRecoilState(dataViewMode);
  const [searchKey, setSearchKey] = useRecoilState<string>(catalogSearchKey);
  const filteredData = useRecoilValue(filteredCatalogApps);

  // if (appsInfo.isError) {
  //   return <div>error</div>;
  // }
  // if (appsInfo.isLoading) {
  //   return <div>loading</div>;
  // }

  // const apps = get(appsInfo, 'data.data.data', []);

  // const filteredData = searchKey
  //   ? apps.filter(({ name }: { name: string }) => {
  //       return name.toLowerCase().indexOf(searchKey.toLowerCase()) !== -1;
  //     })
  //   : apps;

  return (
    <ScreenWrapper>
      {appsInfo.isLoading ? (
        <MessageBox.Loading />
      ) : appsInfo.isError ? (
        <MessageBox.QueryError query={appsInfo} />
      ) : (
        <>
          <TitleToolbar title="Catalogo app">
            <ScreenToolbarToolsComposite>
              <ScreenToolbarToolsComposite.AddButton />
              <ScreenToolbarToolsComposite.ToggleViewButton
                currentViewMode={currentViewMode}
                action={changeViewMode}
              />
              {/* <ScreenToolbarToolsComposite.SearchBoxManual
                options={filteredData}
                handleSearch={setSearchKey}
                // searchKey={searchKey}
              /> */}
              <ScreenToolbarToolsComposite.SearchBoxManual
                options={filteredData}
                handleSearch={setSearchKey}
                // searchKey={searchKey}
              />
            </ScreenToolbarToolsComposite>
            {/* <ScreenToolbarTools
                setSearchKey={setSearchKey}
                setViewMode={changeViewMode}
                searchKey={searchKey}
                options={filteredData}
                /> */}
          </TitleToolbar>
          {currentViewMode === GRID_VIEW ? (
            <GridViewWrapper>
              {filteredData.map((item: AppModel) => (
                <GridItem key={item.id} data={item} />
              ))}
            </GridViewWrapper>
          ) : (
            <ListViewWrapper>
              {filteredData.map((item: AppModel) => (
                <ListItem key={item.id} data={item} />
              ))}
            </ListViewWrapper>
          )}
        </>
      )}
    </ScreenWrapper>
  );
};

export default CatalogIndex;

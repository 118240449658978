import { get } from 'lodash';
import React from 'react';
import { Workspace as WorkspaceInfo } from '../../../types';
import MessageBox from '../../components/MessageBox';
import { wsActions } from '../../lib/config';
import { useSingleWorkspace } from '../../lib/queries';
import { useCrumbs } from '../../lib/useCrumbs';
import Workspace from '../../pages/Workspace';
import { useApplicationContext } from '../../providers/ApplicationProvider';

const WSEdit = () => {
  const queryInfo = useSingleWorkspace();
  const { currentWorkspace } = useApplicationContext();

  useCrumbs([
    { to: '/ws', label: 'Workspaces' },
    { to: `/ws/${currentWorkspace.id}`, label: `${currentWorkspace.name}` },
    { label: wsActions.edit },
  ]);

  if (queryInfo.isLoading) {
    return <MessageBox.Loading />;
  }

  if (queryInfo.isError) {
    return <MessageBox.QueryError query={queryInfo} />;
  }

  const data = get(queryInfo, 'data.data.data', {}) as WorkspaceInfo;

  return <Workspace workspace={data}></Workspace>;
};

export default WSEdit;

import axios from 'axios';

export async function getWorkspaces(baseUrl, queryString: string = '') {
  return await axios.get(
    baseUrl +
      '/workspace' +
      (queryString && queryString !== '' ? '?' + queryString : ''),
  );
}

export async function getWorkspace(baseUrl, id: string = '') {
  return await axios.get(baseUrl + '/workspace' + id);
  //return await axios.get("/mockJson/workspaces.json");
}

export async function createWorkspace(baseUrl, workspace: any) {
  return await axios.post(baseUrl + '/workspace', workspace);
}

export async function updateWorkspace(baseUrl, workspace: any) {
  return await axios.put(baseUrl + '/workspace/' + workspace.id, workspace);
}

export async function addCustomers(
  baseUrl,
  idWorkspace: number | string,
  customers: any[],
) {
  return await axios.post(
    baseUrl + '/workspace/' + idWorkspace + '/customer',
    customers,
  );
}

export async function deleteCustomers(
  baseUrl,
  idWorkspace: number | string,
  customerCode: number | string,
) {
  return await axios.delete(
    baseUrl + '/workspace/' + idWorkspace + '/customer/' + customerCode,
  );
}
